import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cart/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import pricePackageData from "../pricePackageData";
import axios from "axios";

const Designing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.cart);
  const [updatedPriceData, setUpdatedPriceData] = useState(pricePackageData);
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const userCountry = response.data.country_code;
        updatePricing(userCountry);
        // updatePricing('US');
      } catch (error) {
        console.error('Error fetching user country:', error);
        updatePricing('IN'); // Default to Indian pricing if API fails
      }
    };

    const formatPrice = (price) => {
      // Remove commas and convert to number
      const numericPrice = parseFloat(price.replace(/,/g, ''));
      return numericPrice;
    };

    const formatWithCommas = (price) => {
      // Convert the price to string and add commas as per Indian number system
      return price.toLocaleString('en-IN');
    };

    const updatePricing = (country) => {
      const conversionRates = {
        IN: { rate: 1, symbol: '₹' }, // Indian Rupee
        US: { rate: 0.032, symbol: '$' }, // US Dollar
        EU: { rate: 0.031, symbol: '€' }, // Euro
        // Add more countries with their rates and symbols as needed
      };

      const selectedCountry = conversionRates[country] || conversionRates['IN'];
      setCurrencySymbol(selectedCountry.symbol);

      const updatedData = {};

      // Iterate over each package type and update pricing
      Object.keys(pricePackageData).forEach((packageType) => {
        updatedData[packageType] = pricePackageData[packageType].map((item) => {
          const numericPrice = formatPrice(item.price);
          const updatedPrice = (numericPrice * selectedCountry.rate).toFixed(0);

          const formattedPrice = isNaN(updatedPrice) || numericPrice === 0
            ? "Contact Sales"
            : formatWithCommas(parseFloat(updatedPrice));

          return {
            ...item,
            price: formattedPrice,
          };
        });
      });

      setUpdatedPriceData(updatedData);
    };

    fetchUserCountry();
  }, [pricePackageData]);

  const handleAddToCart = (site) => {
    dispatch(addToCart(site));
  };

  const handleAddtoBuy = (site) => {
    dispatch(addToCart(site));
    navigate("/checkout");
  };

  return (
    <div className="bg-[#FFECEC] w-full py-12" id="branding">
      <div className="max-w-[90%] mx-auto">
        <div>
          <div className="text-start mt-[5%] border-b-2 border-black pb-6">
            <p className="lg:tracking-[30px] lg:text-7xl text-3xl tracking-wide text-[#4B4B4B]">
              DESIGNING{" "}
              <span className="tracking-wide lg:text-3xl text-lg lg:-ml-[28px] text-black">
                PACKAGE
              </span>{" "}
            </p>
          </div>
        </div>
        <div className="lg:pt-[1%] grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-y-6 pt-6 md:gap-x-12 lg:gap-x-6 my-12">
          {updatedPriceData.designPackage.map((data, index) => {
            return (
              <div
                key={index}
                className="lg:w-[21vw] w-full bg-[#3F65ED] pt-4 rounded-[10px] h-fit"
              >
                <div className="flex flex-col  text-start bg-[#E4E4E4] space-y-2  py-[5%] rounded-b-[10px]">
                  <span className="text-[#4D74FF] lg:text-[1.6vw] text-2xl font-bold px-[5%]">
                    {data.name}{" "}
                    <span className="text-sm text-black font-normal">
                      {data.des}
                    </span>
                  </span>

                  <div className="lg:py-[15%] py-[10%] text-3xl text-[#1F4BE4] text-center bg-gradient-to-r to-[#DAFFFF] from-[#BCDFFF]">
                    <span>
                    
                      <span className="font-semibold ">
                        {data.price === "Contact Sales" ? data.price :  `  ${currencySymbol}${data.price}/-`}
                      </span>
                    </span>
                  </div>
                  <div className="px-[10%] font-bold">
                    <p>Solutions</p>
                    <div className="flex flex-col text-sm font-normal">
                      {data.solution.map((solution, i) => (
                        <li className="text-[#4FF255]" key={i}>
                          <span className="text-black">{solution}</span>
                        </li>
                      ))}
                    </div>
                  </div>
                  {
                    data.price === 'Contact Sales'?       <Link to={'/contacts'} className="flex justify-center mt-12">
                    <button className="w-56 py-2 bg-[#018F84] flex justify-center rounded-lg font-semibold  text-white">
                      Contact Sales
                    </button>
                  </Link>:
                  <div className="px-[10%] flex justify-between pt-[10%]">
                    <button
                      className="text-xs text-[#018F84] border border-[#018F84] w-[80px] h-[30px] rounded bg-white"
                      onClick={() => handleAddToCart(data)}
                    >
                      Add to cart
                    </button>
                    <button
                      onClick={() => handleAddtoBuy(data)}
                      className="text-xs text-[#ffffff]  bg-[#018F84] w-[80px] h-[30px] rounded"
                    >
                      Buy
                    </button>
                  </div>
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Designing;
