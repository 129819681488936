import React from "react";
import { motion } from "framer-motion";
import CustomerDetails from "../components/checkout/CustomerDetails";
import Stepper from "../components/checkout/Stepper";

const Checkout = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col  p-4"
    >
      {/* <CustomerDetails /> */}
      <Stepper />
    </motion.div>
  );
};

export default Checkout;
