const Images = {
    design: require('./Home/design.png'),
    tech: require('./Home/tech.png'),
    market: require('./Home/maketing.png'),
    hotel: require('./Home/homeindustry.png'),
    pet: require('./Home/pet.png'),
    realestate: require('./Home/reales.png'),
    book: require('./Home/book.png'),
    trader: require('./Home/traders.png'),
    gym: require('./Home/gym.png'),
    kitchen: require('./Home/kitchen.png'),
    event: require('./Home/event.png'),
    travel: require('./Home/travel.png'),
    landscape: require('./Home/landscaping.png'),
    temp: require('./Home/temp.png'),
    star: require('./Home/star.png'),
    invcomma: require('./Home/invcomma.png'),
    footerlogo: require('./logo.svg'),
    send: require('./footer/send.png'),
    whatsapp: require('./footer/whatsapp.png'),
    linkedin: require('./footer/linkedin.png'),
    phone: require('./footer/Phone set up.png'),
    arrowL: require('./arrow-left.svg'),
    arrowR: require('./arrow-right.svg'),
    bulb: require('./startProject/bulb.png'),
    workmain: require('./Work/workmain.svg'),
    invcomma2: require('./Work/invcomma2.png'),
    call: require('./Contact/phone.gif'),
    email: require('./Contact/email.gif'),
    chat: require('./Contact/chat.gif'),
    google: require('./footer/google.png'),
    tel: require('./Contact/callicon.png'),
    arrow: require('./services/arrow.png'),
    whatsapp1: require('./footer/social.png'),
    Exclamation:require('./Exclamation.png'),
    cart:require('./cart.png'),
    navCart:require('./pricing/cart.png'),
    navUser:require('./pricing/user.png'),
    profile:require('./pricing/profile.png')

}

export default Images;