const process = [
    {
        id: 1,
        title: 'Discuss',
        pic: require('./process/image 350.png'),
        content: 'A online meet is set,where customer needs are understood. Pain points and their goals are noted',
    },
    {
        id: 2,
        title: 'Design',
        pic: require('./process/image 3522.png'),
        content: 'The design requirements are listened, understood and analyzed. A document is maintained.',
    },
    {
        id: 3,
        title: 'Built',
        pic: require('./process/image 351.png'),
        content: 'After designs are built,our developers make it to the level of pixel precision,as envisioned.',
    },
    {
        id: 4,
        title: 'Secure',
        pic: require('./process/image 352.png'),
        content: 'After the products built.we make sure, everything is secure.',
    },
    {
        id: 1,
        title: 'Test',
        pic: require('./process/image 353.png'),
        content: 'Before launching, tests are done in various phases.',
    },
    {
        id: 1,
        title: 'Deliver',
        pic: require('./process/image 354.png'),
        content: 'In this stage, product is delivered to the client.',
    },
]




export default {process}