import WheelComponent from "react-wheel-of-prizes";

export default function Wheel() {
  const segments = [
    "RS. 500 OFF",
    "RS. 1000 OFF",
    "RS. 1500 OFF",
    "RS. 2000 OFF",
    "RS. 2500 OFF",
    "RS. 3000 OFF",
    "RS. 3500 OFF",
    "RS. 4000 OFF",
    "RS. 4500 OFF",
    "RS. 4750 OFF",
    "RS. 5000 OFF",
    "Better luck next time",
  ];

  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#FF5733",
    "#FFC300",
    "#C70039",
    "#900C3F",
    "#581845",
    "#DAF7A6",
    "#FF5733",
  ];

  const onFinished = (winner) => {
    console.log("Congratulations! You won: ", winner);
    alert(`Congratulations! You won: ${winner}`);
  };

  return (
    <div className="App">
      <div style={{ margin: "auto", width: "fit-content" }}>
        <WheelComponent
          segments={segments}
          segColors={segColors}
          onFinished={onFinished}
          primaryColor="black"
          contrastColor="white"
          buttonText="Lucky | Spin"
          isOnlyOnce={true}
          size={250}
          upDuration={500}
          downDuration={600}
          fontFamily="Arial"
        />
      </div>
    </div>
  );
}
