import React from "react";
import { FaTimes } from "react-icons/fa";

const FAQModal = ({ toggleFaqModal }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center  text-start justify-center bg-black bg-opacity-60 z-50">
      <div className="bg-gray-200 p-4 rounded-lg shadow-lg md:w-[90vw] w-full max-h-[95vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6 border-b border-gray-500 pb-4">
          <h2 className="text-2xl font-semibold">Refund Policy</h2>
          <p
            className="cursor-pointer text-xl p-2 rounded-md bg-red-500 text-white hover:bg-red-600"
            onClick={toggleFaqModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="text-sm text-gray-700 space-y-6">
          <p>
            <span className="font-semibold">
              Refund Policy for Sicu-aura Innoviz
            </span>
            <br />
            Sicu-aura Innoviz is committed to providing high-quality software
            development services. This refund policy outlines the conditions
            under which a client may be eligible for a refund.
          </p>
          <p>
            <span className="font-semibold">
              1. Circumstances for a Full Refund:
            </span>
            <br />
            <ul className="list-none pl-6">
              <li>
                <span className="font-semibold">Project Not Started:</span> A
                full refund will be issued if the project has not been started
                and no work has begun on the development process. This applies
                to situations where the contract is signed but development
                awaits your official go-ahead.
              </li>
              <li>
                <span className="font-semibold">Mutual Agreement:</span> In the
                event that both Sicu-aura Innoviz and the client agree to
                terminate the project before development commences, a full
                refund will be issued.
              </li>
            </ul>
          </p>
          <p>
            <span className="font-semibold">
              2. Circumstances for a Partial Refund:
            </span>
            <br />
            <ul className="list-none pl-6">
              <li>
                <span className="font-semibold">
                  Project Cancellation After Start:
                </span>{" "}
                If the client chooses to cancel the project after development
                has begun, a partial refund may be issued. The amount refunded
                will be determined by the percentage of work completed at the
                time of cancellation. A detailed breakdown of completed work
                stages will be provided to justify the partial refund amount.
              </li>
            </ul>
          </p>
          <p>
            <span className="font-semibold">3. Non-Refundable Situations:</span>
            <br />
            <ul className="list-none pl-6">
              <li>
                <span className="font-semibold">Project Completion:</span> No
                refunds will be granted for completed projects.
              </li>
              <li>
                <span className="font-semibold">Incomplete Information:</span>{" "}
                If the client provided incomplete or inaccurate information
                during the initial project scoping phase, leading to revisions
                outside the original agreement, no refunds will be available for
                rework required due to these discrepancies.
              </li>
              <li>
                <span className="font-semibold">Unrealistic Expectations:</span>{" "}
                Sicu-aura Innoviz will outline project timelines and
                functionalities clearly during the initial stages. If a client
                cancels the project due to unrealistic expectations not outlined
                in the project scope, no refunds will be provided.
              </li>
            </ul>
          </p>
          <p>
            <span className="font-semibold">
              4. Refund Processing Timeframe:
            </span>
            <br />
            <ul className="list-none pl-6">
              <li>
                <span className="font-semibold">Approved Refunds:</span> Once a
                refund is approved, Sicu-aura Innoviz will processing the refund with in 5 working days to the same payment mode.
              </li>
              <li>
                <span className="font-semibold">Refund Arrival Time:</span> The
                actual time it takes for the refunded amount to appear in your
                account depends on your chosen payment method and bank
                processing times. Sicu-aura Innoviz is not responsible for any
                delays outside of our control.
              </li>
            </ul>
          </p>
          <p>
            <span className="font-semibold">5. Dispute Resolution:</span>
            <br />
            In the event of a dispute regarding a refund, Sicu-aura Innoviz will
            work collaboratively with the client to reach a fair resolution.
          </p>
          <p>
            <span className="font-semibold">6. Contact:</span>
            <br />
            For any questions regarding this Refund Policy, please contact
            Sicu-aura Innoviz at{" "}
            <a href="mailto:innoviz@sicu-aura.com" className="text-blue-500">
              innoviz@sicu-aura.com
            </a>
            .
          </p>

        </div>
      </div>
    </div>
  );
};

export default FAQModal;
