const reviewdata = [
    {
        id: 1,
        name: "Pritam Sanyal",
        image: require('../testimonial/male4.jpg'),
        text: "We approached Sicu-Aura Innoviz with a complex problem, and they provided an innovative solution that exceeded our expectations. We are extremely satisfied with the results and look forward to future collaborations.",
        rating: 5,
        color: "#04dbd4",
    },
    {
        id: 2,
        name: "Anirban Das",
        image: require('../testimonial/male2.jpg'),
        text: "Sicu-Aura Innoviz has been an invaluable partner for our business. Their software solutions have streamlined our efficiency. The customer support team is always responsive and goes above and beyond to resolve any issues.",
        rating: 5,
        color: "#04dbd4",
    },
    {
        id: 3,
        name: "Kakali Ghosh",
        image: require('../testimonial/female2.jpg'),
        text: "The software developed by Sicu-Aura Innoviz seamlessly integrated with our existing systems, providing a smooth transition and enhanced performance. Their commitment to quality and customer satisfaction is commendable.",
        rating: 5,
        color: "#04dbd4",
    },
    {
        id: 4,
        name: "Anirban Biswas",
        image: require('../testimonial/male3.jpg'),
        text:"Working with Sicu-Aura Innoviz has been a game-changer. Their professional approach and reliable solutions have helped us stay ahead of the competition. The team's ability to understand our needs and deliver on time.",
        rating: 5,
        color: "#04dbd4",
    },
    {
        id: 5,
        name: "Rimi Das",
        image: require('../testimonial/female3.jpg'),
        text: "Sicu-Aura Innoviz is at the forefront of software innovation. Their use of cutting-edge technology and industry best practices sets them apart from the competition. I have witnessed their commitment to excellence and client success firsthand.",
        rating: 5,
        color: "#04dbd4",
    },


]

export default reviewdata;