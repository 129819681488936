const bg = require("./servicesbg.svg");

const logo1 = [
  require("./Logo_1/swift.svg"),
  require("./Logo_1/microsoft-dotnet.svg"),
  require("./Logo_1/nodejs.svg"),
  require("./Logo_1/vuejs.svg"),
  require("./Logo_1/react.svg"),
  require("./Logo_1/JavaScript-logo.png"),
  require("./Logo_1/jquery_vertical_logo_icon_169489.png"),
  require("./Logo_1/Python-logo-notext.png"),
];
const logo2 = [
  require("./Logo_2/image 335.png"),
  require("./Logo_2/image 336.png"),
  require("./Logo_2/image 337.png"),
  require("./Logo_2/image 338.png"),
  require("./Logo_2/image 339.png")
]
const logo3 = [
  require("./Logo_3/image 340.png"),
  require("./Logo_3/2.png"),
  require("./Logo_3/image 341.png"),
  require("./Logo_3/image 342.png"),
  require("./Logo_3/image 343.png")
]
const circles = [
  require("./circles/1.png"),
  require("./circles/2.png"),
  require("./circles/3.png"),
  require("./circles/4.png"),
  require("./circles/5.png"),
]
const cards = [
  require("./cards/1.png"),
  require("./cards/2.png"),
  require("./cards/3.png"),
  require("./cards/4.png"),
  require("./cards/5.png"),
]
const circle = require('./circles/Mask group.png')
const arrow = require('./arrow.png')
const str1 = require('./1.png')
const str2 = require('./2.png')
export default { logo1, logo2, logo3, bg, circles, circle, arrow, cards, str1, str2 };
