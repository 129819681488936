import React, { useEffect, useState } from "react";
import dash from "../../assets/pricing/dash.png";
import RequestModal from "../modal/RequestModal";
import { Link } from "react-router-dom";
import axios from "axios";

const Offers = () => {
  const [requestModal, setRequstModal] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('₹');
  const [conversionRate, setConversionRate] = useState(1); // Default to 1 for INR

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const userCountry = response.data.country_code;
        updatePricing(userCountry);
      } catch (error) {
        console.error('Error fetching user country:', error);
        updatePricing('IN'); // Default to Indian pricing if API fails
      }
    };

    const updatePricing = (country) => {
      const conversionRates = {
        IN: { rate: 1, symbol: '₹' }, // Indian Rupee
        US: { rate: 0.032, symbol: '$' }, // US Dollar
        EU: { rate: 0.031, symbol: '€' }, // Euro
        // Add more countries with their rates and symbols as needed
      };

      const selectedCountry = conversionRates[country] || conversionRates['IN'];
      setCurrencySymbol(selectedCountry.symbol);
      setConversionRate(selectedCountry.rate);
    };

    fetchUserCountry();
  }, []);

  const toggleRequestModal = () => {
    setRequstModal(!requestModal);
  };

  const formatPrice = (price) => {
    return `${currencySymbol} ${(price * conversionRate).toFixed(2)}`;
  };

  return (
    <div className="bg-[#E0DFFF] w-full">
      <div className="max-w-[90%] mx-auto">
        <div>
          <div className="text-[5vw] text-start mt-[5%] border-b-2 border-black">
            <p className="lg:tracking-[15px] lg:text-7xl text-3xl tracking-wide text-[#4B4B4B]">
              LIMITED EDITION{" "}
              <span className="tracking-wide text-base lg:text-3xl lg:-ml-[28px]">
                PACKAGE
              </span>{" "}
            </p>
          </div>
        </div>
        <div>
          <p className="text-sm my-4 lg:max-w-[80%] max-w-[90%] mx-auto mb-16">
            *if you have a great vision or you are on a mission or you want to
            scale your business then we have got your back if your business is
            for a good reason you will have our support for sure. Submit your
            business details and get our support it does not matter how much
            money you make, all that matters is the reason behind you business*
          </p>
        </div>
        <div className="w-full mb-20 flex lg:flex-nowrap flex-wrap gap-x-10">
          <div className="lg:w-1/2 w-full flex flex-wrap border border-gray-500 rounded-3xl">
            <div className="lg:w-[40%] w-full bg-[#FF6D56] py-24 pb-10 rounded-t-3xl lg:rounded-l-3xl lg:rounded-r-none">
              <div className="relative text-[#1F4BE4]">
                <p className="text-xl font-medium">
                  {formatPrice(42999)} /-
                </p>
                <img
                  src={dash}
                  className="absolute left-1/2 h-6 -translate-x-1/2 top-1/2 transform -translate-y-1/2 w-[50%]"
                />
              </div>
              <div className="text-3xl font-semibold text-[#1F4BE4]">
                <p className="mt-4">
                  {formatPrice(29499)} /-
                </p>
              </div>
              <div className="font-semibold font-antonio tracking-wide text-white text-[14px] mb-2 mt-4">
                <p>SAVE 31% OFF</p>
              </div>
              <div>
                <button className="bg-[#FFC034] px-6 py-2 text-sm font-medium">
                  Worth {formatPrice(12999)} benefits
                </button>
              </div>
            </div>
            <div className="lg:w-[60%] w-full bg-[#F3FEFF] px-8 flex-col justify-center lg:rounded-r-3xl rounded-b-3xl lg:rounded-l-none pb-6 lg:pb-0">
              <div className="text-[#1F4BE4] text-5xl mt-6 text-start lg:-ml-20 font-semibold">
                <p>Vision</p>
              </div>
              <div className="text-start mt-4">
                <p className="font-semibold">
                  Multiple page customized basic website
                </p>
                <p className="text-lg font-normal">
                  (Worth {formatPrice(32499)} /-)
                </p>
              </div>
              <div className="text-start mt-3">
                <p className="font-semibold">
                  Facebook special marketing campaign
                </p>
                <p className="text-lg font-normal">
                  (Worth {formatPrice(9499)} /-)
                </p>
              </div>
              <div
                onClick={toggleRequestModal}
                className="flex lg:justify-end mt-4"
              >
                <button className="px-4 py-2 bg-[#018F84] rounded-lg font-semibold text-white">
                  Request Approval
                </button>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex-wrap flex rounded-3xl border border-gray-500 mt-12 lg:mt-0">
            <div className="lg:w-[40%] w-full bg-[#FF6D56] py-24 pb-10 lg:rounded-l-3xl rounded-t-3xl lg:rounded-r-none">
              <div className="relative text-[#1F4BE4]">
                <p className="text-xl font-medium">
                  {formatPrice(72499)} /-
                </p>
                <img
                  src={dash}
                  className="absolute left-1/2 h-6 -translate-x-1/2 top-1/2 transform -translate-y-1/2 w-[50%]"
                />
              </div>
              <div className="text-3xl font-semibold text-[#1F4BE4]">
                <p className="mt-4">
                  {formatPrice(49700)} /-
                </p>
              </div>
              <div className="font-semibold font-antonio tracking-wide text-white text-[14px] mb-2 mt-4">
                <p>SAVE 32% OFF</p>
              </div>
              <div>
                <button className="bg-[#FFC034] px-6 py-2 text-sm font-medium">
                  Worth {formatPrice(12999)} benefits
                </button>
              </div>
            </div>
            <div className="lg:w-[60%] w-full bg-[#F3FEFF] px-10 flex-col justify-center lg:rounded-r-3xl rounded-b-3xl lg:rounded-l-none pb-6">
              <div className="text-[#1F4BE4] text-5xl mt-6 text-start lg:-ml-20 font-semibold">
                <p>Mission</p>
              </div>
              <div className="text-start mt-4">
                <p className="font-semibold">
                  Lead Generation website
                </p>
                <p className="text-lg font-normal">
                  (Worth {formatPrice(56999)} /-)
                </p>
              </div>
              <div className="text-start mt-3">
                <p className="font-semibold">
                  Awareness special marketing campaign
                </p>
                <p className="text-lg font-normal">
                  (Worth {formatPrice(15999)} /-)
                </p>
              </div>
              <div
                onClick={toggleRequestModal}
                className="flex lg:justify-end mt-4"
              >
                <button className="px-4 py-2 bg-[#018F84] rounded-lg font-semibold text-white">
                  Request Approval
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mb-32 mt-12 flex flex-wrap justify-end gap-x-10">
          <div className="lg:w-1/2 w-full flex flex-wrap rounded-3xl border border-gray-500">
            <div className="lg:w-[40%] w-full bg-[#FF6D56] lg:rounded-l-3xl rounded-t-3xl lg:rounded-r-none py-24 pb-10">
              <div className="relative text-[#1F4BE4]">
                <p className="text-xl font-medium">
                  {formatPrice(32499)} /-
                </p>
                <img
                  src={dash}
                  className="absolute left-1/2 h-6 -translate-x-1/2 top-1/2 transform -translate-y-1/2 w-[50%]"
                />
              </div>
              <div className="text-3xl font-semibold text-[#1F4BE4]">
                <p className="mt-4">
                  {formatPrice(22499)} /-
                </p>
              </div>
              <div className="font-semibold font-antonio tracking-wide text-white text-[14px] mb-2 mt-4">
                <p>SAVE 31% OFF</p>
              </div>
              <div>
                <button className="bg-[#FFC034] px-6 py-2 text-sm font-medium">
                  Worth {formatPrice(12999)} benefits
                </button>
              </div>
            </div>
            <div className="lg:w-[60%] w-full bg-[#F3FEFF] px-8 flex-col justify-center lg:rounded-r-3xl rounded-b-3xl lg:rounded-l-none pb-6">
              <div className="text-[#1F4BE4] text-5xl mt-6 text-start lg:-ml-20 font-semibold">
                <p>Scale</p>
              </div>
              <div className="text-start mt-4">
                <p className="font-semibold">
                  Complete professional website
                </p>
                <p className="text-lg font-normal">
                  (Worth {formatPrice(29499)} /-)
                </p>
              </div>
              <div className="text-start mt-3">
                <p className="font-semibold">
                  Google special marketing campaign
                </p>
                <p className="text-lg font-normal">
                  (Worth {formatPrice(7999)} /-)
                </p>
              </div>
              <div
                onClick={toggleRequestModal}
                className="flex lg:justify-end mt-4"
              >
                <button className="px-4 py-2 bg-[#018F84] rounded-lg font-semibold text-white">
                  Request Approval
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {requestModal && <RequestModal close={toggleRequestModal} />}
    </div>
  );
};

export default Offers;
