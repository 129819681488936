import React, { useEffect, useState } from "react";
import Images from "../assets";
import { portfoliodata, extradata } from "../assets/Work/data/portfoliodata";
import rocket from "../assets/about/rocket.svg";
import servicesImg from "../assets/services/index";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import PreLoader from "../components/PreLoader";

const Work = () => {
  const [displayCount, setDisplayCount] = useState(6);
  const [portfolioWorkData, setPortfolioWorkData] = useState(portfoliodata);
  const [extraWorkData, setExtraWorkData] = useState(extradata);
  const [viewMore, setViewMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [screenLoading, setScreenLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    if (!initialLoadComplete) {
      setTimeout(() => {
        setScreenLoading(false);
        setInitialLoadComplete(true);
      }, 7500);
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Work",
    });
    setTimeout(() => {
      setScreenLoading(false);
    }, 7500);
  }, []);

  const [visible, setVisible] = useState(false);
  const totalPosts = visible
    ? portfoliodata.length + extradata.length
    : portfoliodata.length;
  const totalPostsInital = portfoliodata.length + extradata.length;

  useEffect(() => {
    setExtraWorkData(viewMore ? extradata : extradata.slice(0, 6));
  }, [viewMore]);

  useEffect(() => {
    const filteredData =
      selectedCategory === "All"
        ? extradata.slice(0, 6)
        : extradata.filter((item) => item.category.includes(selectedCategory));

    if (selectedCategory !== "All") {
      setPortfolioWorkData([]);
    } else {
      setPortfolioWorkData(portfoliodata);
    }

    setExtraWorkData(filteredData);
  }, [selectedCategory]);

  useEffect(() => {
    if (searchQuery) {
      const filteredData = extradata.filter((item) =>
        item.heading.toLowerCase().includes(searchQuery)
      );
      setExtraWorkData(filteredData);
      setPortfolioWorkData([]);
    } else {
      setPortfolioWorkData(portfoliodata);
      setExtraWorkData(extradata.slice(0, 6));
    }
  }, [searchQuery]);

  return (
    <>
      {screenLoading ? (
        <div className="z-[100] absolute w-screen">
          <PreLoader />
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0, top: 0 }}
          animate={{ opacity: 1, top: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
          className=" flex flex-col items-center"
        >
          <div className="flex relative items-end w-full  justify-center sm:pt-[15%] pt-[30%] bg-gradient-to-t to-[#001F89] from-[#94ACFF]   ">
            <div className=" flex justify-center relative z-[1] ">
              <div className="flex justify-center mt-[5%] w-[60%]  ">
                <svg
                  width="1303"
                  height="592"
                  className=" w-[100%] h-[100%]"
                  viewBox="0 0 1303 592"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <motion.g
                    initial={{ opacity: 1, y: 200 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 1.5 },
                    }}
                    filter="url(#filter0_d_243_29)"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M153.034 78.7593V587.124H756.51V353.293H622.774V263.582H456.226V183.838H319.997V78.7593H153.034ZM237.762 398.564C237.762 357.275 271.233 323.804 312.521 323.804C353.81 323.804 387.281 357.275 387.281 398.564V587.124H237.762V398.564ZM562.551 379.459C530.437 379.459 504.404 405.492 504.404 437.605V587.124H620.697V437.605C620.697 405.492 594.664 379.459 562.551 379.459Z"
                      fill="url(#paint0_linear_243_29)"
                    />
                  </motion.g>
                  <motion.g
                    initial={{ opacity: 1, y: 200 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 0.5 },
                    }}
                    filter="url(#filter1_d_243_29)"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M876.541 4C761.85 4 668.875 96.9749 668.875 211.665V587.125L720.791 587.125V212.761C720.791 126.743 790.523 57.0115 876.541 57.0115C962.558 57.0115 1032.29 126.743 1032.29 212.761V587.125L1084.21 587.125V211.665C1084.21 96.975 991.231 4 876.541 4Z"
                      fill="url(#paint1_linear_243_29)"
                    />
                  </motion.g>
                  <motion.g
                    initial={{ opacity: 1, y: 200 }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 2, delay: 0.2 },
                    }}
                    filter="url(#filter2_d_243_29)"
                  >
                    <path
                      d="M620.697 479.554V587.54H1157.3V171.793H1054.3V241.154H972.067V328.373H874.88V402.717H756.51V479.554H620.697Z"
                      fill="url(#paint2_linear_243_29)"
                    />
                  </motion.g>
                  <motion.circle
                    initial={{ opacity: 1, y: -200 }}
                    whileInView={{
                      opacity: 1,
                      y: [-500, 0, -100, 0, -25, 0],
                      transition: { duration: 0.5, delay: 0.2 },
                    }}
                    cx="1234.11"
                    cy="520.114"
                    r="68.1143"
                    fill="url(#paint3_linear_243_29)"
                  />
                  <motion.g
                    initial={{ opacity: 1, x: -50 }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: { duration: 1.5 },
                    }}
                    filter="url(#filter3_d_243_29)"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M83.8969 386.104L93.0341 395.242V587.125H0V395.242L9.13728 386.104L83.8969 386.104Z"
                      fill="url(#paint4_linear_243_29)"
                    />
                  </motion.g>
                  <defs>
                    <filter
                      id="filter0_d_243_29"
                      x="153.034"
                      y="74.7593"
                      width="667.476"
                      height="516.365"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="60" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_243_29"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_243_29"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter1_d_243_29"
                      x="668.875"
                      y="0"
                      width="469.331"
                      height="591.125"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="50" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_243_29"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_243_29"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter2_d_243_29"
                      x="620.697"
                      y="167.793"
                      width="600.607"
                      height="423.747"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="60" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_243_29"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_243_29"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter3_d_243_29"
                      x="0"
                      y="382.104"
                      width="157.034"
                      height="209.02"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="60" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_243_29"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_243_29"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_243_29"
                      x1="463.702"
                      y1="164.733"
                      x2="463.702"
                      y2="587.124"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#E9BF6B" />
                      <stop offset="1" stop-color="#836B3C" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_243_29"
                      x1="876.541"
                      y1="83.517"
                      x2="876.541"
                      y2="467.319"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FF8673" />
                      <stop offset="1" stop-color="#8C4A40" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_243_29"
                      x1="889.416"
                      y1="242.815"
                      x2="889.416"
                      y2="567.189"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#74D0E8" />
                      <stop offset="1" stop-color="#365D67" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_243_29"
                      x1="1269.73"
                      y1="452.289"
                      x2="1234.11"
                      y2="588.229"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#E7F97D" />
                      <stop offset="1" stop-color="#0F1C0F" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear_243_29"
                      x1="46.5171"
                      y1="386.104"
                      x2="46.5171"
                      y2="581.725"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#C781FF" />
                      <stop offset="1" stop-color="#613881" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className=" absolute font-bold sm:text-[5vw] text-[8vw] text-white bottom-[86%] left-[20%]">
                Portfolio
              </div>
            </div>
          </div>
          <div className=" flex flex-col w-full justify-center items-center ">
            <div className="services-gradient_1 w-full   ">
              <div className="text-white flex items-center justify-center ">
                <img
                  className="sm:w-[6%] w-[10%]"
                  src={servicesImg.str1}
                  alt="string"
                ></img>
                <p className="text-white font-Inter sm:text-3xl text-xs font-semibold">
                  The trust shown by the businesses
                  <br />
                  towards our team,work proecess.
                </p>
                <img
                  className="sm:w-[6%]  w-[10%]"
                  src={servicesImg.str2}
                  alt="string"
                ></img>
              </div>
            </div>

            <div className=" flex flex-col w-full bg-[#DAF9F9]  items-center">
              <div className=" max-w-[80%] w-[80%] my-4 mx-[5%] portfoliobg flex sm:flex-row flex-col-reverse sm:justify-around  items-center rounded-lg  py-4">
                <motion.ul
                  initial={{ scale: 0.8, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.2, delay: 0.2 }}
                  className="flex sm:flex-row flex-col justify-around font-Raleway sm:text-[1.3vw] text-[3vw] sm:w-[70%] py-2 sm:py-0 font-bold"
                >
                  {[
                    "All",
                    "UI/UX mobile",
                    "UI/UX Website",
                    "Development",
                    "Digital marketing",
                  ].map((category, index) => (
                    <li
                      key={index}
                      className={`flex items-center justify-center cursor-pointer hover:bg-[#F2F2F2] transition-all duration-200 ease-in-out px-3 py-1 rounded-xl ${
                        selectedCategory === category
                          ? "bg-[#F2F2F2] px-3 py-1 rounded-xl"
                          : ""
                      }`}
                      onClick={() => setSelectedCategory(category)}
                    >
                      {category}
                    </li>
                  ))}
                </motion.ul>
                <div className="bg-[#F2F2F2] flex items-center  sm:w-[20%] w-[30%] sm:h-[5vh] h-[4vh] rounded-xl ">
                  {" "}
                  <input
                    className=" bg-transparent sm:text-[1vw] text-[3vw] text-center sm:w-[100%] w-[80%] outline-none"
                    placeholder="Search"
                    onChange={(e) => setSearchQuery(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className="flex flex-col w-[80%]">
                <div className="flex flex-row justify-between font-Inter sm:text-[1.3vw] text-[3vw] mx-6">
                  <div className="font-bold ">Latest Work</div>
                  <div className="font-Inter">
                    <span className="font-bold">{totalPosts}</span>/
                    {totalPostsInital} Posts
                  </div>
                </div>
                <div className="mt-4 flex flex-col justify-center">
                  <div
                    className="grid gap-[5%]"
                    style={{ gridTemplateAreas: `'Header Main' 'Menu Main'` }}
                  >
                    {portfolioWorkData.map((item, index) => (
                      <motion.div
                        initial={{ scale: 0.8, opacity: 0 }}
                        whileInView={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.2, delay: 0.2 }}
                        className="flex flex-col"
                        style={{ gridArea: `${item.gridarea}` }}
                        key={item.id}
                      >
                        <div
                          className="overflow-hidden relative sm:rounded-[10px] rounded-md flex main-container border border-[#0D6F7A] hover:shadow-2xl shadow-[#080808] transition-all ease-in-out duration-500 mx-[5%] cursor-pointer"
                          onClick={() => window.open(item.link)}
                        >
                          <img
                            src={item.imageUrl}
                            className={`sm:rounded-[10px] rounded-sm w-full object-cover `}
                            alt=""
                          />
                          <div className="absolute bg-black bg-opacity-35 w-full overlay  flex flex-col items-center justify-center px-[2%] font-Inter text-white  sm:text-sm text-[10px]">
                            {item.description}
                          </div>
                        </div>
                        <div className="text-start mx-[6%] mt-[2%] sm:text-[1vw] font-Inter font-semibold text-[2.5vw]">
                          {item?.hashtags.map((hashtag, index) => (
                            <span key={index}>
                              {hashtag}
                              {index !== item.hashtags.length - 1 ? " " : ""}
                              &nbsp;
                            </span>
                          ))}
                        </div>
                      </motion.div>
                    ))}
                  </div>
                  {portfolioWorkData.length ? (
                    <div className="my-[5%]"></div>
                  ) : (
                    <div></div>
                  )}
                  <div className="sm:grid-cols-3 grid-cols-1 grid sm:gap-[5%] gap-5 font-Inter md:mb-[50%] lg:mb-[25%] mb-[25%]">
                    {extraWorkData &&
                      extraWorkData.map((item) => (
                        <motion.div
                          initial={{ scale: 0.8, opacity: 0 }}
                          whileInView={{ scale: 1, opacity: 1 }}
                          transition={{ duration: 0.2, delay: 0.2 }}
                          className="flex flex-col"
                          key={item.id}
                        >
                          <div
                            className="overflow-hidden flex sm:rounded-[10px] rounded-md main-container mx-[5%] border border-[#0D6F7A] hover:shadow-2xl shadow-[#080808] transition-all ease-in-out duration-500 cursor-pointer"
                            onClick={() => window.open(item.link)}
                          >
                            <img
                              src={item.imageUrl}
                              className="sm:rounded-[10px] rounded-sm w-full sm:h-[200px]  h-[150px] object-cover"
                              alt=""
                            />
                          </div>
                          <div className="text-start mx-[6%] mt-4 sm:text-[1vw] font-Inter font-semibold text-[2.5vw]">
                            {item?.hashtags.map((hashtag, index) => (
                              <span key={index}>
                                {hashtag}
                                {index !== item.hashtags.length - 1 ? " " : ""}
                                &nbsp;
                              </span>
                            ))}
                          </div>
                          <div className="text-start mx-[6%] my-[2%] font-semibold sm:text-[1.6vw] text-[3.5vw]">
                            {item.heading}
                          </div>
                          <div className="text-start mx-[6%] sm:text-[1.2vw] text-[3vw] ">
                            {item.description}
                          </div>
                        </motion.div>
                      ))}
                  </div>
                  <div>
                    {extraWorkData && extraWorkData.length === 0 ? (
                      <div className="-mt-[18%] sm:-mt-[12%] text-center font-bold text-xl md:text-3xl">
                        <p>Data not found</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    {!viewMore && extraWorkData.length > 0 && (
                      <div className="mb-6 -mt-[10%]">
                        <button
                          className="sm:rounded-[10px] rounded-[5px] px-[4%] py-[1%] bg-[#353535] font-Inter text-white sm:text-[1.3vw] text-[3vw] font-normal"
                          onClick={() => setViewMore(true)}
                        >
                          View More
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex flex-col w-full bg-[#4779CE] ">
              <div className="mx-subsection text-white text-start sm:py-[5%] py-[10%]  ">
                <div className="sm:text-[4.5vw] text-[8vw] font-Inter ">
                  Industries we serve
                </div>
                <div className="font-Raleway sm:text-[1.5vw] text-[3vw]">
                  The capacity to empower the industries with our services
                </div>
              </div>
            </div>
            <div className=" flex flex-col  bg-white z-10 w-full items-center  ">
              <div className=" mt-section flex flex-col items-center justify-center max-w-[90%] w-[90%] mb-section">
                <div className=" mt-[4%] grid sm:grid-cols-5 grid-cols-3 place-content-center place-items-center mx-[3%]  sm:gap-[4.3rem] gap-[15px] font-Inter font-medium sm:text-[1.5vw] text-[2.8vw] text-[#004078] text-center ">
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%]  mx-[2%] ">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.hotel} alt="" />
                    </div>
                    <div className=" mt-4">Hotel Industry</div>
                  </div>
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.pet} alt="" />
                    </div>
                    <div className=" mt-4">Pet Shop</div>
                  </div>
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.realestate} alt="" />
                    </div>
                    <div className=" mt-4">Real Estate</div>
                  </div>
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.book} alt="" />
                    </div>
                    <div className=" mt-4">Book Authors</div>
                  </div>
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.trader} alt="" />
                    </div>
                    <div className=" mt-4">Traders</div>
                  </div>
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.gym} alt="" />
                    </div>
                    <div className=" mt-4">Gym’s</div>
                  </div>
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%] ">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.kitchen} alt="" />
                    </div>
                    <div className=" mt-4">Cloud Kitchens</div>
                  </div>
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.event} alt="" />
                    </div>
                    <div className=" mt-4">Event Planners</div>
                  </div>
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.travel} alt="" />
                    </div>
                    <div className=" mt-4">Travel Agency</div>
                  </div>
                  <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                    <div className="border-2 border-[#004078] rounded-[10px] p-4">
                      <img src={Images.landscape} alt="" />
                    </div>
                    <div className=" mt-4">Landscape</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full py-[10%] bg-gradient-to-t from-[#08297cdc] flex justify-center to-[#759CE8] ">
              <div className=" bg-[#F3FEFF] sm:mx-[15vw] mx-[8vw] sm:w-[70%] w-[80%]  py-[2%] sm:rounded-3xl rounded-lg">
                <div className="mx-subsection text-start flex justify-between items-center relative">
                  <div>
                    <div className="  sm:text-[4vw] text-[6vw] font-Inter">
                      Let’s start on the Idea,
                      <br /> you thinking about !
                    </div>
                    <div className="font-Raleway   sm:text-[1.5vw] text-[3vw] py-[2%]">
                      Any stage your business <br />
                      may be in,we will scale
                      <br /> it for you.
                    </div>
                    <Link to={"/startProject"}>
                      <div className="bg-gradient-to-r from-[#00C5B5] to-[#005F57] sm:text-[1.5vw] text-[3vw] flex items-center justify-center w-[45%] sm:py-[5%] py-[3%] sm:rounded-2xl rounded-md text-center text-white  mt-[5%]">
                        Get in touch
                        <img
                          className="w-[15%] mt-[1%] ml-1"
                          src={servicesImg.arrow}
                          alt="arrow"
                        ></img>
                      </div>
                    </Link>
                  </div>
                  <div className=" w-[40vw] absolute -right-[20%] ">
                    <img
                      src={rocket}
                      className="mix-blend-multiply"
                      alt="rocket"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Work;
