import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MetaPixelEvent = ({ eventName }) => {
    useEffect(() => {
        (function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
    
        window.fbq("init", "983549883299111");
        window.fbq("track", "PageView");
    
        if (eventName) {
          window.fbq("track", eventName);
        }
      }, [eventName]);
//   return (
//     <Helmet>
//       <noscript>{<img height="1" width="1" style={{display: 'none'}}
//              src="https://www.facebook.com/tr?id=983549883299111&ev=PageView&noscript=1"/>}</noscript>
//     </Helmet>
//   );
return null;
};

export default MetaPixelEvent;
