import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import axios from "axios";

const User = () => {
  const customerDetails = useSelector((state) => state.cart.customerDetails);
  const user = useSelector((state) => state.auth.user);

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://pavan-sicu-aura-default-rtdb.firebaseio.com/orders.json"
      );
      const allOrders = response.data;
      const userOrders = [];

      for (const key in allOrders) {
        if (allOrders[key].order.user.email === user.email) {
          userOrders.push(allOrders[key]);
        }
      }

      setOrders(userOrders);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  console.log(orders);

  useEffect(() => {
    if (user) {
      fetchOrders();
    }
  }, [user]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader"></div>
      </div>
    );
  }

  if (!loading && orders.length < 0) {
    return (
      <div className="flex justify-center items-center text-center h-screen">
        <p>No orders found for this user.</p>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="flex justify-center items-center h-screen ">
        Login Please
      </div>
    );
  }

  return (
    <section className="py-24 relative px-[5%] space-y-10 min-h-screen">
      <div className="w-full">
        <div className="flex justify-center w-fit mx-auto mt-10 items-center space-x-4 p-4 bg-white shadow-xl rounded-lg">
          <img
            src={user?.photoURL}
            className="h-16 w-16 rounded-full object-cover border-2 border-gray-300"
            alt="profile"
          />
          <div>
            <p className="text-lg font-semibold">{user?.displayName}</p>
            <p className="text-gray-600">{user?.email}</p>
          </div>
        </div>
      </div>
      <div className="main-box border border-gray-200 rounded-xl pt-6">
        <div className="flex flex-col lg:flex-row lg:items-center justify-between px-6 border-b border-gray-200">
          <div className="data w-full">
            <div className="grid grid-cols-1 md:gap-x-6 lg:gap-x-12 md:grid-cols-2 lg:grid-cols-3 w-full">
              {orders &&
                orders.map((order, index) => (
                  <div
                    key={index}
                    className="border border-gray-200 rounded-lg mt-12 shadow-lg p-6 flex flex-col"
                  >
                    <div className="flex items-center justify-center  space-x-4  mb-4">
                      {order.order.user && (
                        <>
                          <div>
                            <div className="mt-2 text-center text-gray-700 space-y-2">
                              <p>
                                <span className="font-semibold">
                                  Designation:
                                </span>{" "}
                                {order.order.customerDetails.designation}
                              </p>
                              <p>
                                <span className="font-semibold">Address:</span>{" "}
                                {order.order.customerDetails.address}
                              </p>
                              <p>
                                <span className="font-semibold">Mobile:</span>{" "}
                                {order.order.customerDetails.mobile}
                              </p>
                              <p>
                                <span className="font-semibold">
                                  Organization:
                                </span>{" "}
                                {order.order.customerDetails.organization}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex-1 space-y-4">
                      {order.order.cart &&
                        order.order.cart.map((cartItem, index) => (
                          <div
                            key={index}
                            className="border-t border-gray-200 pt-4"
                          >
                            <p className="text-lg font-semibold text-gray-800">
                              {cartItem.name}
                            </p>
                            <p className="text-gray-600">{cartItem.des}</p>
                            <p className="text-gray-800 font-bold">
                              ₹{cartItem.price}
                            </p>
                            <ul className="mt-2 space-y-1">
                              {cartItem.solution.map((solution, i) => (
                                <li className="text-[#4FF255]" key={i}>
                                  <span className="text-black">{solution}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      <p className="text-xl font-bold text-gray-900 mt-4">
                        Total Price: ₹{order.order.firebaseData}
                      </p>
                    </div>
                    <button
                      onClick={() => toast("Coming soon...")}
                      className="mt-6 rounded-full py-3 px-7 font-semibold text-sm leading-7 text-white bg-[#0D6F7A] shadow-sm transition-all duration-500 hover:bg-[#0d6f7aae] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0D6F7A]"
                    >
                      Track Your Order
                    </button>
                  </div>
                ))}
            </div>
            {!orders ||
              (orders.length === 0 && (
                <div className="mx-auto py-24 text-4xl text-center w-full">
                  <p>No order found currently</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default User;
