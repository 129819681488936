import React from 'react';
import Images from '../assets';


function Connect  ()  {
    return (
        <div class="fixed left-[10px]  bottom-[10px] px-[5px] z-[100]" >
        <div style={{display:"flex",flexDirection:'column'}}>
            <a href="tel:6289179476" className='flex justify-center items-center' target='__blank'><img src={Images.tel}  alt="" className=" bg-[#00C5B5] my-[50%]  rounded-full p-[10%] hover:scale-105 transition-all duration-300 ease-in-out sm:w-[3.5vw] w-[8vw]"  /></a>
            <a href='https://wa.me/916289179476' className='flex justify-center items-center' target='__blank'><img src={Images.whatsapp1} alt="" className=" bg-[#00C5B5]  rounded-full p-[10%] hover:scale-105 transition-all duration-300 ease-in-out sm:w-[3.5vw] w-[8vw]" /></a>
            
            
            </div>
      </div>
    );
}

export default Connect;
