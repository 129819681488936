import { motion } from "framer-motion";
import { useState } from "react";
import React from "react";

const Transition = ({ children }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  const transition = {
    duration: 0.5,
    ease: "easeInOut",
  };

  const handleTransitionStart = () => {
    setIsTransitioning(true);
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };
  const transitionVariants = {
    initial: { y: '100%' },
    animate: { y: 0 },
    exit: { y: '-100%' },
  };

  return (
    <>
      <motion.div
        className=" w-full h-[100vh] bg-white z-0 "
        initial={{ height: "100vh" }}
        animate={{ height: "0vh" }}
        exit={{ height: ["0vh", "100vh"] }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
        onAnimationStart={handleTransitionStart}
        onAnimationEnd={handleTransitionEnd}
      />
      <motion.div
        className=" w-full h-[100vh] bg-blue-900 z-0"
        initial={{ height: "100vh" }}
        animate={{ height: "0vh" }}
        exit={{ height: ["0vh", "100vh"] }}
        transition={{ duration: 0.8, ease: "easeInOut", delay: 0.2 }}
        onAnimationStart={handleTransitionStart}
        onAnimationEnd={handleTransitionEnd}
      />
      <motion.div
        className=" w-full h-[100vh] bg-[#0D6F7A] z-0"
        initial={{ height: "100vh" }}
        animate={{ height: "0vh" }}
        exit={{ height: ["0vh", "100vh"] }}
        transition={{ duration: 0.8, ease: "easeInOut", delay: 0.4 }}
        onAnimationStart={handleTransitionStart}
        onAnimationEnd={handleTransitionEnd}
      />
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"

        transition={{ type: 'tween', duration: 0.5 }}
        onAnimationStart={handleTransitionStart}
        onAnimationEnd={handleTransitionEnd}
        className=" bg-black "
      >
        {children}
      </motion.div>
    </>
  );
};

export default Transition;
