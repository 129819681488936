import React, { useEffect, useState } from "react";
import arrowl from "../assets/arrow-left.svg";
import arrowr from "../assets/arrow-right.svg";
import Carousel from "react-elastic-carousel";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../components/firebase";
import ReactGA from "react-ga4";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import ViewBlogs from "../components/ViewBlogs";
const Blogs = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [blogs, setBlogs] = useState([]);
  const [searchBlogs, setSearchBlogs] = useState([]);
  const [isPop, setPop] = useState({ pop: false, id: null })
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Blogs",
    });
    fetchJobs();
  }, []);
  const fetchJobs = async () => {
    const querySnapshot = await getDocs(collection(db, "Innoviz-blogs"));
    const InnovizBlogs = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        const blogsData = doc.data();
        blogsData.id = doc.id;
        InnovizBlogs.push(blogsData);
      } else {
        console.log("No such document!");
      }
    });
    setBlogs(InnovizBlogs);
    setSearchBlogs(InnovizBlogs);
    setLoading(false);
  };
  console.log(blogs)
  const postsPerPage = 6;
  const pageCount = Math.ceil(blogs.length / postsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const MyArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === "PREV" ? (
        <img
          src={arrowl}
          className={
            isEdge
              ? "opacity-[50%] cursor-not-allowed sm:w-[14%] w-[35%] hover:scale-110 transition-all ease-in-out duration-600"
              : "sm:w-[14%] w-[35%] hover:scale-110 transition-all ease-in-out duration-600"
          }
          alt="arrowl"
        ></img>
      ) : (
        <img
          src={arrowr}
          className={
            isEdge
              ? "opacity-[50%] cursor-not-allowed sm:w-[13%] w-[30%] hover:scale-110 transition-all ease-in-out duration-600 relative -right-[60%]"
              : "sm:w-[13%] w-[30%] hover:scale-110 transition-all ease-in-out duration-600 relative -right-[60%]"
          }
          alt="arrowr"
        ></img>
      );
    return (
      <div>
        <button
          onClick={onClick}
          disabled={isEdge}
          className=" absolute sm:h-[40vh] h-[27vh] w-[20%] bg-gradient-to-r to-[#000000] from-[#e8e8e800] right-0 "
        >
          {pointer}
        </button>
        <button
          onClick={onClick}
          disabled={isEdge}
          className="absolute sm:h-[40vh] h-[27vh] w-[20%] ms-0 ps-[3%]  bg-gradient-to-l to-[#000000] from-[#0a0a0a00]  z-10"
        >
          {pointer}
        </button>
      </div>
    );
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 425, itemsToShow: 2 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 3 },
  ];

  
  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "Innoviz-blogs"));
      const allBlogs = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          const blogsData = doc.data();
          blogsData.id = doc.id; // Add the document ID to the blogsData
          allBlogs.push(blogsData);
        } else {
          console.log("No such document!");
        }
      });
      setSearchBlogs(allBlogs);
      setLoading(false);
    };


    const handleSearchBlogs = async () => {
      if (searchQuery === "") {
        await fetchBlogs();
      } else {
        const filteredBlogs = searchBlogs.filter(
          (blog) =>
            blog.content.toLowerCase().includes(searchQuery) ||
            blog.title.toLowerCase().includes(searchQuery)
        );
        setSearchBlogs(filteredBlogs);
      }
    };

    handleSearchBlogs();
  }, [searchQuery]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };
  return (
    <motion.div
      initial={{ opacity: 0, top: 0 }}
      animate={{ opacity: 1, top: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      className="overflow-hidden font-Inter"
    >
      {
        isPop.pop ? (
         <ViewBlogs blogs={blogs} isPop={isPop} setPop={setPop} />

        ) : null
      }
      <div className="bg-gradient-to-t to-[#001F89] from-[#94ACFF]  sm:pt-[11%] pt-[20%] flex justify-center items-end  ">
        <div className="flex justify-center mt-[5%] w-[70%]">
          <AnimatePresence>
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1341 521"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.g
                initial={{ opacity: 1, y: 200 }}
                whileInView={{
                  opacity: 1,
                  y: [400, -10, 0],
                  transition: { duration: 1.5 },
                }}
                filter="url(#filter0_d_255_149)"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1199 516C1199 448.763 1185.47 382.185 1159.19 320.066C1132.91 257.947 1094.38 201.505 1045.82 153.961C997.252 106.418 939.597 68.7041 876.143 42.9737C812.69 17.2433 744.681 4 676 4C607.319 4 539.31 17.2433 475.857 42.9737C412.403 68.7041 354.748 106.418 306.183 153.961C257.618 201.505 219.094 257.947 192.811 320.066C166.528 382.185 153 448.763 153 516L415.251 516V424.45V161.114H511.451V424.45H597.07V516H676H1199Z"
                  fill="url(#paint0_linear_255_149)"
                />
              </motion.g>
              <motion.g
                initial={{ opacity: 1, x: -400 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1.5, delay: 0.5 },
                }}
                filter="url(#filter1_d_255_149)"
              >
                <path
                  d="M1.04308e-06 79L196.5 79C250.9 79 295 123.1 295 177.5V177.5C295 231.9 250.9 276 196.5 276L-7.56806e-06 276"
                  stroke="url(#paint1_linear_255_149)"
                  stroke-width="60"
                />
              </motion.g>
              <motion.g
                initial={{ opacity: 1, x: -400 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1.5, delay: 0.5 },
                }}
                filter="url(#filter2_d_255_149)"
              >
                <path
                  d="M1.04308e-06 290L196.5 290C250.9 290 295 334.1 295 388.5V388.5C295 442.9 250.9 487 196.5 487L-7.56806e-06 487"
                  stroke="url(#paint2_linear_255_149)"
                  stroke-width="60"
                />
              </motion.g>
              <motion.path
                initial={{ opacity: 0, scale: 1.5 }}
                whileInView={{
                  opacity: 1,
                  scale: 1,
                  transition: { duration: 1.5, delay: 0.5 },
                }}
                d="M928 349.5C928 419.478 871.475 476 802 476C732.525 476 676 419.478 676 349.5C676 279.522 732.525 223 802 223C871.475 223 928 279.522 928 349.5Z"
                stroke="url(#paint3_linear_255_149)"
                stroke-width="80"
              />
              <motion.g
                initial={{ opacity: 1, x: 400 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1.5, delay: 0.5 },
                }}
                filter="url(#filter3_d_255_149)"
              >
                <path
                  d="M1240 216.186V181.5C1240 125.443 1194.56 80 1138.5 80V80C1082.44 80 1037 125.443 1037 181.5V362C1037 431.036 1092.96 487 1162 487H1175.72"
                  stroke="url(#paint4_linear_255_149)"
                  stroke-width="60"
                />
              </motion.g>
              <motion.g
                initial={{ opacity: 1, x: 150 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 2.2 },
                }}
                filter="url(#filter4_d_255_149)"
              >
                <motion.rect
                  x="1170"
                  y="345"
                  width="117"
                  height="172"
                  fill="url(#paint5_linear_255_149)"
                />
              </motion.g>
              <defs>
                <filter
                  id="filter0_d_255_149"
                  x="153"
                  y="0"
                  width="1100"
                  height="520"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="50" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_255_149"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_255_149"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_d_255_149"
                  x="0"
                  y="45"
                  width="379"
                  height="265"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="50" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_255_149"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_255_149"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter2_d_255_149"
                  x="0"
                  y="256"
                  width="379"
                  height="265"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="50" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_255_149"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_255_149"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter3_d_255_149"
                  x="1007"
                  y="46"
                  width="317"
                  height="475"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="50" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_255_149"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_255_149"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter4_d_255_149"
                  x="1170"
                  y="341"
                  width="171"
                  height="180"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="50" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_255_149"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_255_149"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_255_149"
                  x1="619.637"
                  y1="14.1791"
                  x2="619.637"
                  y2="517"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FFE281" />
                  <stop offset="1" stop-color="#897222" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_255_149"
                  x1="308.918"
                  y1="177.5"
                  x2="-1.23612e-05"
                  y2="177.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#84FFE9" />
                  <stop offset="1" stop-color="#2D5267" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_255_149"
                  x1="308.918"
                  y1="388.5"
                  x2="-1.23612e-05"
                  y2="388.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#84FFE9" />
                  <stop offset="1" stop-color="#2D5267" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_255_149"
                  x1="802"
                  y1="183"
                  x2="802"
                  y2="516"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#817EFF" />
                  <stop offset="1" stop-color="#4C2F70" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_255_149"
                  x1="1138.5"
                  y1="50.3483"
                  x2="1138.5"
                  y2="487"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF8C81" />
                  <stop offset="1" stop-color="#9F1B0E" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_255_149"
                  x1="1228.5"
                  y1="345"
                  x2="1228.5"
                  y2="517"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E9FF71" />
                  <stop offset="1" stop-color="#565D2F" />
                </linearGradient>
              </defs>
            </svg>
          </AnimatePresence>
        </div>
      </div>
      <div className="sm:text-3xl text-sm font-Inter font-semibold sm:py-[5%] py-[10%] bg-white">
        Articles curated by the team
      </div>
      <div className=" bg-[#C1DFFF] relative pb-[4%]">
        <h1 className="  text-start px-[10%] font-semibold sm:text-2xl text-sm  py-[2%]">
          Latest
        </h1>
        {loading && (
          <div className="flex gap-5 items-center py-[1.5%]">
            <div
              role="status"
              class="space-y-7 animate-pulse  md:items-center  w-[33%]"
            >
              <div class="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-[100%] dark:bg-gray-700">
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
              <div class="w-full">
                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
            <div
              role="status"
              class="space-y-7 animate-pulse   rtl:space-x-reverse w-[33%]  md:items-center"
            >
              <div class="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-[100%] dark:bg-gray-700">
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
              <div class="w-full">
                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
            <div
              role="status"
              class="space-y-7 animate-pulse   rtl:space-x-reverse w-[33%]  md:items-center"
            >
              <div class="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-[100%] dark:bg-gray-700">
                <svg
                  class="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
              <div class="w-full">
                <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {!loading && (
          <Carousel
            pagination={false}
            breakPoints={breakPoints}
            enableAutoPlay
            autoPlaySpeed={3000}
            renderArrow={MyArrow}
          >
            {blogs.map((data, index) => {
              return (
                <div className="flex justify-center font-Inter flex-col rounded">
                  {data.imageUrl.includes(".mp4") ? (
                    <video
                      controls
                      id="myVideo"
                      className="sm:h-[40vh] h-[27vh] object-cover md:px-2 rounded"
                    >
                      <source src={data.imageUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={data.imageUrl}
                      loading="lazy"
                      className="sm:h-[40vh] h-[27vh] object-cover md:mx-2 rounded bg-black"
                      alt="posts"
                    ></img>
                  )}
                  <div className="text-start md:px-2 pt-3">
                    <div className="font-semibold sm:text-sm text-[10px]">
                      #{data.tagline ? data.tagline.split("#")[1] : ""}
                    </div>
                    <div className="font-semibold sm:text-xl text-sm">
                      {" "}
                      {data.title.substring(0, data.title.length / 1)}...
                    </div>
                    <div className="sm:text-sm text-[10px]">
                      {" "}
                      {data.content
                        ? data.content
                          .replace(/<[^>]+>|&nbsp;/g, "", ":&nbsp;")
                          .substring(0, data.content.length / 15)
                        : ""}
                      ...
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        )}
      </div>
      <div className="bg-[#DBE1EE] flex sm:flex-row flex-col justify-between py-[5%] px-[10%]">
        <div className="flex flex-col sm:text-[1.2vw] text-[3vw] sm:pt-[10%] ">
          <div className="grid grid-cols-2 place-items-center sm:flex sm:flex-col sm: justify-between sm:mt-[60%] my-[10%] sm:h-[40vh] ">
            <button className="border-[#262626] border rounded-full sm:p-[4%] p-[2%] px-[2%] mb-[10%] sm:mb-0 sm:w-[10vw] w-[25vw] hover:bg-[#262626] hover:text-white hover:scale-110 transition-all ease-in-out duration-700">
              All
            </button>
            <button className="border-[#262626] border rounded-full sm:p-[4%] p-[2%] px-[2%] mb-[10%] sm:mb-0 sm:w-[10vw] w-[25vw] hover:bg-[#262626] hover:text-white hover:scale-110 transition-all ease-in-out duration-700">
              Technology
            </button>
            <button className="border-[#262626] border rounded-full sm:p-[4%] p-[2%] px-[2%] sm:w-[10vw] w-[25vw] hover:bg-[#262626] hover:text-white hover:scale-110 transition-all ease-in-out duration-700">
              Design
            </button>
            <button className="border-[#262626] border rounded-full sm:p-[4%] p-[2%] px-[2%] sm:w-[10vw] w-[25vw] hover:bg-[#262626] hover:text-white hover:scale-110 transition-all ease-in-out duration-700">
              Marketing
            </button>
          </div>
          {/* <div className='sm:mt-[20%]'>
            <div>
              {currentPage * postsPerPage + 1}-{Math.min((currentPage + 1) * postsPerPage, blogs.length)} <span className='sm:text-[2vw] text-[4vw]'>/</span> {blogs.length} <br />Articles
            </div>
          </div> */}
        </div>
        <div>
          <div>
            <div className="flex sm:justify-between justify-around pt-[5%] m-1 sm:pt-0">
              <div className="sm:text-[2vw] text-[4vw]">Other Posts</div>
              <div className="bg-[#F2F2F2] flex items-center sm:w-[30%] w-[50%] sm:h-[5vh] h-[4vh] rounded-md px-4">
                {" "}
                <input
                  onChange={handleSearch}
                  className=" bg-transparent sm:text-[1vw] text-[3vw] text-center h-[30px] w-[100%] outline-none"
                  placeholder="Search blog"
                ></input>
              </div>
            </div>
            <div className="bg-white rounded-xl">
              <div className="grid sm:grid-cols-3 sm:w-[60vw]">
                {loading
                  ? Array.from({ length: postsPerPage }).map((_, index) => (
                    <div
                      key={index}
                      role="status"
                      className="p-[10%] animate-pulse rtl:space-x-reverse w-[100%] md:items-center"
                    >
                      <div className="flex items-center justify-center w-full h-[150px]  bg-gray-300 rounded sm:w-[100%] dark:bg-gray-700">
                        <svg
                          className="w-10 h-10 text-gray-200 dark:text-gray-600"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                      </div>
                      <div className="w-full mt-[10%]">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ))
                  : searchBlogs
                    .slice(
                      currentPage * postsPerPage,
                      (currentPage + 1) * postsPerPage
                    )
                    .map((data, index) => (
                      <div className="" key={index}>
                        <div className="w-[100%] p-[10%] md:h-[380px] relative">
                          {data.imageUrl.includes(".mp4") ? (
                            <video
                              controls
                              id="myVideo"
                              className="w-[100%] h-[150px] object-cover rounded-md"
                            >
                              <source src={data.imageUrl} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              src={data.imageUrl}
                              loading="lazy"
                              className="w-[100%] h-[150px] rounded-md object-cover bg-gray-500"
                              alt="blog"
                            />
                          )}
                          <div className="text-start font-Inter py-[5%]">
                            <div className="sm:text-[1vw] text-[3vw]">
                              #
                              {data.tagline ? data.tagline.split("#")[1] : ""}
                            </div>
                            <div className="font-semibold sm:text-[1.5vw] text-[4vw]">
                              {" "}
                              {data.title.substring(0, data.title.length / 3)}
                              ...
                            </div>
                            <div className="sm:text-[1vw] text-[3vw]">
                              {data.content
                                ? data.content
                                  .replace(/<[^>]+>|&nbsp;/g, "", ":&nbsp;")
                                  .substring(0, data.content.length / 45)
                                : ""}
                              ...
                            </div>
                          </div>
                        </div>
                        <button onClick={() => { setPop({ pop: true, id: data.id }); }} className="bg-[#959595] sm:text-[1vw] text-[3vw] rounded-full px-[6%] py-[3%] hover:bg-[#262626] hover:text-white">
                          Learn more
                        </button>
                      </div>
                    ))}
              </div>
              {searchBlogs.length === 0 && <div className="text-center md:text-3xl text-xl font-bold mt-14"><p>Data not found</p></div>}
              <div className="flex justify-center sm:text-[1.2vw] text-[3vw] py-[3%]">
                <button
                  className={`pagination__button ${currentPage === 0 ? "disabled" : ""
                    }`}
                  onClick={() => handlePageClick({ selected: currentPage - 1 })}
                  disabled={currentPage === 0}
                >
                  Previous
                </button>
                <span className="bg-[#959595] sm:w-[11vw] w-[25vw] flex items-center justify-around rounded">
                  Page
                  <span className="bg-[#F2F2F2] sm:w-[5vw] w-[10vw] rounded">
                    {currentPage + 1} <span className="">/</span> {pageCount}
                  </span>
                </span>
                <button
                  className={`pagination__button ${currentPage === pageCount - 1 ? "disabled" : ""
                    }`}
                  onClick={() => handlePageClick({ selected: currentPage + 1 })}
                  disabled={currentPage === pageCount - 1}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </motion.div>
  );
};

export default Blogs;
