import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import { api } from "../mailerApi";

const RequestModal = ({ toggleRequestModal }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    address: "",
    businessName: "",
    businessType: "",
    startingDate: "",
    unique: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      header: "Sicu-Aura Innoviz",
      subject:
        "we have received new contact request from sicu-aura Innoviz website",
      html: `
            <p>I hope this email finds you well. I am writing to provide you with the details of a user who has submitted a request on our website:</p>
            <p>Name: ${formData.name}</p>
            <p>Phone: ${formData.phone}</p>
            <p>Address: ${formData.address}</p>
            <p>businessName: ${formData.businessName}</p>
            <p>businessType: ${formData.businessType}</p>
            <p>startingDate: ${formData.startingDate}</p>
            <p>unique: ${formData.unique}</p>`,
      to: "logeshthirumurugan@gmail.com,loganop007@gmail.com",
    };

    try {
      const response = await axios.post(api, data);
      setFormData({
        name: "",
        company: "",
        email: "",
        number: "",
        selectedDate: new Date(),
        file: null,
        text: "",
        selectedRequirement: "",
      });
      toast.success("Mail Sent.");
      toggleRequestModal()
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email.");
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70 z-50">
      <div className="bg-gray-200 p-6 rounded-lg shadow-lg md:w-[50vw] w-full max-h-[95vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6 border-b border-gray-500 pb-4">
          <h2 className="text-2xl font-semibold">Request Policy</h2>
          <p
            className="cursor-pointer text-xl p-2 rounded-md bg-red-500 text-white hover:bg-red-600"
            onClick={toggleRequestModal}
          >
            <FaTimes />
          </p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm text-start font-medium text-gray-700">
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </label>
          </div>
          <div>
            <label className="block text-sm text-start font-medium text-gray-700">
              Phone:
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </label>
          </div>
          <div>
            <label className="block text-sm text-start font-medium text-gray-700">
              Address:
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </label>
          </div>
          <div>
            <label className="block text-sm text-start font-medium text-gray-700">
              Business Name:
              <input
                type="text"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </label>
          </div>
          <div>
            <label className="block text-sm text-start font-medium text-gray-700">
              Type of Business:
              <input
                type="text"
                name="businessType"
                value={formData.businessType}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </label>
          </div>
          <div>
            <label className="block text-sm text-start font-medium text-gray-700">
              Starting Date:
              <input
                type="date"
                name="startingDate"
                value={formData.startingDate}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </label>
          </div>
          <div>
            <label className="block text-sm text-start font-medium text-gray-700">
              What makes your business unique from others:
              <textarea
                name="unique"
                value={formData.unique}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                rows="4"
                required
              ></textarea>
            </label>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={toggleRequestModal}
              className="mr-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#018F84] text-white rounded-md "
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestModal;
