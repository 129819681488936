import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Lottie from "lottie-react";

const LottieAnimation = ({ animationData }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: false });
    const lottieRef = useRef(null);

    useEffect(() => {
        if (inView) {
            controls.start("visible");
            lottieRef.current.play();
        } else {
            lottieRef.current.stop();
        }
    }, [controls, inView]);

    const sectionVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 1 }
        }
    };

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={sectionVariants}
        >
            <Lottie
                lottieRef={lottieRef}
                animationData={animationData}
                loop={false}
                autoplay={false}
            />
        </motion.div>
    );
};

export default LottieAnimation;
