import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import logo from "../assets/innovizLogo.svg";
import { useNavigate } from "react-router-dom";

const Chatbot = () => {
  const navigate = useNavigate();

  const handleRedirect = (page) => {
    navigate(page);
  };

  const steps = [

    {
      id: "0",
      message: "Hi there! Welcome to Sicu-aura Innoviz. What's your name?",
      trigger: "name",
    },
    {
      id: "name",
      user: true,
      trigger: "askForPhone",
    },
    {
      id: "askForPhone",
      message: ({ previousValue }) => `Great to meet you, ${previousValue}! Can you share your mobile number with us?`,
      trigger: "phone",
    },
    {
      id: "phone",
      user: true,
      trigger: "informationReceived",
    },
    {
      id: "informationReceived",
      message: "Awesome! Thanks for sharing your details. How can we assist you today?",
      trigger: "mainOptions",
    },

    
    {
      id: "mainOptions",
      options: [
        {
          value: 1,
          label: "Want to know about our projects?",
          trigger: "projects",
        },
        {
          value: 2,
          label: "Explore our website portfolio",
          trigger: "portfolio",
        },
        { value: 3, label: "Looking to start a project?", trigger: "new" },
      ],
    },

    // Project section
    {
      id: "projects",
      options: [
        { value: 1, label: "Website projects", trigger: "web-projects" },
        { value: 2, label: "Application projects", trigger: "app-projects" },
        { value: 3, label: "AI projects", trigger: "ai-projects" },
      ],
    },
    {
      id: "web-projects",
      message: "Website projects - Let's get your online presence started!",
      trigger: "askForContact",
    },
    {
      id: "app-projects",
      message:
        "Application projects - Turn your ideas into stunning mobile apps!",
      trigger: "askForContact",
    },
    {
      id: "ai-projects",
      message: "AI projects - Dive into the world of artificial intelligence!",
      trigger: "askForContact",
    },

    // Portfolio section
    {
      id: "portfolio",
      options: [
        { value: 1, label: "Our Services", trigger: "redirectServices" },
        { value: 2, label: "Our Work", trigger: "redirectWork" },
        { value: 3, label: "Our Blog", trigger: "redirectBlog" },
        { value: 4, label: "About Us", trigger: "redirectAbout" },
        { value: 5, label: "Contact Us", trigger: "redirectContact" },
      ],
    },
    {
      id: "redirectServices",
      message: "Redirecting you to our services page...",
      trigger: () => {
        handleRedirect("/services");
        return "mainOptions";
      },
    },
    {
      id: "redirectWork",
      message: "Redirecting you to our work page...",
      trigger: () => {
        handleRedirect("/work");
        return "mainOptions";
      },
    },
    {
      id: "redirectBlog",
      message: "Redirecting you to our blog page...",
      trigger: () => {
        handleRedirect("/blog");
        return "mainOptions";
      },
    },
    {
      id: "redirectAbout",
      message: "Redirecting you to our about page...",
      trigger: () => {
        handleRedirect("/about");
        return "mainOptions";
      },
    },
    {
      id: "redirectContact",
      message: "Redirecting you to our contact page...",
      trigger: () => {
        handleRedirect("/contact");
        return "mainOptions";
      },
    },

    // New project section
    {
      id: "new",
      message:
        "Awesome! We offer various project development services. What type of project are you interested in?",
      trigger: "new-projects-lists",
    },
    {
      id: "new-projects-lists",
      options: [
        {
          value: 1,
          label: "Custom Software Development",
          trigger: "customSoftware",
        },
        { value: 2, label: "Mobile App Development", trigger: "mobileApp" },
        { value: 3, label: "Web App Development", trigger: "webApp" },
        { value: 4, label: "E-commerce Development", trigger: "eCommerce" },
      ],
    },
    {
      id: "customSoftware",
      message:
        "Custom Software Development - Tailored solutions for your unique needs.",
      trigger: "connect",
    },
    {
      id: "mobileApp",
      message:
        "Mobile App Development - Transforming your app ideas into reality!",
      trigger: "connect",
    },
    {
      id: "webApp",
      message:
        "Web App Development - Crafting engaging web experiences for your business.",
      trigger: "connect",
    },
    {
      id: "eCommerce",
      message:
        "E-commerce Development - Building robust online stores that drive sales.",
      trigger: "connect",
    },
    {
      id: "connect",
      message:
        `If you have any other doubts related to projects please contact us at (+91 6289179476).`,
      trigger: "askForContact",
    },

    {
      id: "askForContact",
      message: "Do you have any other questions?",
      trigger: "yesno",
    },
    {
      id: "yesno",
      options: [
        { value: "yes", label: "Yes", trigger: "mainOptions" },
        { value: "no", label: "No", trigger: "end" },
      ],
    },

    {
      id: "end",
      message: "Thank you for chatting with us. Have a great day!",
      trigger: "restart",
    },
    {
      id: "restart",
      options: [{ value: "yes", label: "Restart", trigger: "0" }],
    },
  ];

  const theme = {
    background: "#0D6F7A",
    headerBgColor: "#00C5B5",
    headerFontSize: "20px",
    botBubbleColor: "#0C3151",
    headerFontColor: "white",
    botFontColor: "white",
    userBubbleColor: "#00C5B5",
    userFontColor: "white",
  };

  const config = {
    botAvatar: logo,
    floating: true,
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ChatBot headerTitle="Sicu-aura" steps={steps} {...config} />
      </ThemeProvider>
    </div>
  );
};

export default Chatbot;
