// src/SignIn.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { auth } from "./firebase";
import {
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithPhoneNumber,
  signInWithCredential,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import toast from "react-hot-toast";
import { api } from "./mailerApi";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Images from "../assets";
import { useLocation } from "react-router-dom";
import { setUser } from "../redux/auth/authSlice";

const SignIn = ({ setPopUp, count }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setName] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");

  const isValidPhoneNumber = (number) => {
    return number.replace(/\D/g, "").length > 10;
  };

  const handleSendCode = async (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phoneNumber)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "normal",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        },
      }
    );

    try {
      const appVerifier = window.recaptchaVerifier;
      const phoneNumberWithCountryCode = "+" + phoneNumber;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumberWithCountryCode,
        appVerifier
      );
      setVerificationId(confirmationResult.verificationId);
      toast.success("Code sent!");
    } catch (error) {
      toast.error("Failed to send code");
      console.error(error);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    try {
      const userCredential = await signInWithCredential(auth, credential);
      const user = userCredential.user;
      sessionStorage.setItem("user", JSON.stringify(user));
      dispatch(setUser(user)); // Dispatch the user to Redux
      toast.success("Phone number authenticated!");
      setPopUp((prevState) => ({ ...prevState, pop: false }));
      send();
    } catch (error) {
      console.error(error);
      toast.error("Failed to verify code");
    }
  };

  const send = async () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const data = {
      header: "Sicu-Aura Innoviz",
      subject: "New User Logged in",
      html: `
            <p>New User ${userName ||
              user.displayName} has signed up using these credentials:</p>
            <p>${phoneNumber}</p>
            <p>${user.displayName}</p>
            <p>${user.email}</p>
            <p>${new Date()}</p>
            `,
      to: "logeshthirumurugan@gmail.com",
    };

    try {
      await axios.post(api, data);
    } catch (error) {
      console.error("Internal error", error);
    }
  };

  const handleGoogleSignIn = async () => {
    if (!isValidPhoneNumber(phoneNumber)) {
      toast.error("Please enter a valid phone number");
      return;
    }
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      sessionStorage.setItem("user", JSON.stringify(user));
      dispatch(setUser(user)); // Dispatch the user to Redux
      toast.success("Google sign-in successful!");
      setPopUp((prevState) => ({ ...prevState, pop: false }));
      send();
    } catch (error) {
      console.error(error);
      toast.error("Failed to sign in with Google");
    }
  };

  return (
    <div className="absolute z-50 bg-[#C7ECE8] p-[2%] font-Raleway rounded-lg border-black border-[1px] mb-[5%] text-black font-normal sm:text-sm text-xs">
      <div className="pb-5 w-[100%] flex justify-between text-[#005F57] font-semibold">
        <h2>Sign In with Phone Number</h2>
        {count < 3 &&
        pathname !== "/work" &&
        pathname !== "/checkout" &&
        pathname !== "/user" ? (
          <span
            className="cursor-pointer hover:scale-105 transition-all ease-in-out duration-200"
            onClick={() => {
              setPopUp((prevState) => ({ ...prevState, pop: false }));
            }}
          >
            Close
          </span>
        ) : (
          <div className="relative exclamation-hover">
            <img
              className="w-[20px] cursor-pointer  "
              src={Images.Exclamation}
              alt="!"
            ></img>
            <div className="text-gray-500 bg-gray-300 text-[10px] w-[100px] absolute -top-10 rounded -right-24 exclamation-text transition-all ease-in-out duration-300">
              You have to SiginIn to access the page
            </div>
          </div>
        )}
      </div>
      <form onSubmit={handleSendCode} className="space-y-2">
        <input
          className="w-[100%] h-[35px] rounded-[5px] px-2 border border-gray-300"
          type="text"
          placeholder="Name"
          required
          value={userName}
          onChange={(e) => setName(e.target.value)}
        />
        <PhoneInput
          country={"in"}
          value={phoneNumber}
          inputProps={{
            required: true,
          }}
          onChange={(phone) => setPhoneNumber(phone)}
        />
        <button className="w-[100%] px-12 rounded-[5px] text-white py-[6px] bg-gradient-to-t from-[#00C5B5] to-[#005F57]">
          Send Code
        </button>
      </form>
      <form onSubmit={handleVerifyCode} className="w-[100%] space-y-2 pt-2">
        <input
          type="text"
          placeholder="Verification Code"
          required
          className="w-[100%] h-[35px] rounded-[5px] px-2 border border-gray-300"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <button className="w-[100%] rounded-[5px] text-white py-[6px] bg-gradient-to-t from-[#00C5B5] to-[#005F57]">
          Verify Code
        </button>
        <span>or</span>
      </form>

      <PhoneInput
        country={"in"}
        value={phoneNumber}
        inputProps={{
          required: true,
        }}
        onChange={(phone) => setPhoneNumber(phone)}
      />
      <button
        className="w-[100%] mt-2 flex justify-center gap-2 items-center rounded-[5px] text-white py-[6px] bg-gradient-to-t from-[#00C5B5] to-[#005F57]"
        onClick={handleGoogleSignIn}
      >
        Sign in with
        <span>
          <img className="w-[50%]" src={Images.google} alt="Google" />
        </span>
      </button>

      <div id="recaptcha-container"></div>
    </div>
  );
};

export default SignIn;
