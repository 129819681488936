import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cart/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import poster2 from "../../assets/pricing/poster2.png";
import pricePackageData from "../pricePackageData";
import axios from "axios";
const Marketing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.cart);

  const [updatedPriceData, setUpdatedPriceData] = useState(pricePackageData);
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const userCountry = response.data.country_code;
        updatePricing(userCountry);
        // updatePricing('US');
      } catch (error) {
        console.error('Error fetching user country:', error);
        updatePricing('IN'); // Default to Indian pricing if API fails
      }
    };

    const formatPrice = (price) => {
      // Remove commas and convert to number
      const numericPrice = parseFloat(price.replace(/,/g, ''));
      return numericPrice;
    };

    const formatWithCommas = (price) => {
      // Convert the price to string and add commas as per Indian number system
      return price.toLocaleString('en-IN');
    };

    const updatePricing = (country) => {
      const conversionRates = {
        IN: { rate: 1, symbol: '₹' }, // Indian Rupee
        US: { rate: 0.032, symbol: '$' }, // US Dollar
        EU: { rate: 0.031, symbol: '€' }, // Euro
        // Add more countries with their rates and symbols as needed
      };

      const selectedCountry = conversionRates[country] || conversionRates['IN'];
      setCurrencySymbol(selectedCountry.symbol);

      const updatedData = {};

      // Iterate over each package type and update pricing
      Object.keys(pricePackageData).forEach((packageType) => {
        updatedData[packageType] = pricePackageData[packageType].map((item) => {
          const numericPrice = formatPrice(item.price);
          const updatedPrice = (numericPrice * selectedCountry.rate).toFixed(0);
          const formattedPrice = formatWithCommas(parseFloat(updatedPrice));

          return {
            ...item,
            price: formattedPrice,
          };
        });
      });

      setUpdatedPriceData(updatedData);
    };

    fetchUserCountry();
  }, [pricePackageData]);

  const handleAddToCart = (site) => {
    dispatch(addToCart(site));
  };

  const handleAddtoBuy = (site) => {
    dispatch(addToCart(site));
    navigate("/checkout");
  };

  return (
    <div className="bg-[#FFF4CD] w-full" id="digital">
      <div className="max-w-[90%] mx-auto">
        <div>
          <div className="text-start mt-[5%] border-b-2 border-black ">
            <p className="lg:tracking-[30px] lg:text-7xl text-3xl tracking-wide text-[#4B4B4B]">
              MARKETING{" "}
              <span className="tracking-wide lg:text-3xl text-lg lg:-ml-[28px] text-black">
                PACKAGE
              </span>{" "}
            </p>
          </div>
        </div>
        <div>
          <div className="pt-4 mb-32 flex flex-col-reverse lg:flex-row justify-between items-center w-full py-16">
            {" "}
            <div className="w-full lg:w-[50vw] h-auto md:h-[35vh] lg:mb-32 mt-12">
              <div className="border border-[#0000008e] flex flex-col md:flex-row text-start rounded-[35px]">
                <img
                  src={poster2}
                  className="w-full md:w-[35%] h-[50vh] lg:h-auto rounded-t-[35px] rounded-r-[35px] rounded-l-[35px] md:rounded-r-none md:rounded-t-none"
                  alt="poster"
                />
                <div className="mx-auto px-6 md:px-4 lg:px-[8%] rounded-r-[35px] rounded-[35px] md:rounded-none md:rounded-r-[35px] flex pb-8 justify-center flex-col bg-[#F3FEFF] lg:space-y-10 md:space-y-6 w-full">
                  <div className="text-center lg:text-start mb-2 mt-4 space-y-2">
                    <p className="text-[#4C74FF] xl:text-4xl lg:text-2xl md:text-xl text-lg font-bold">
                      Market your business with the services
                    </p>
                    <p className="lg:text-base font-semibold text-sm">
                      Find the right services for your business.
                    </p>
                  </div>
                  <div className="lg:text-sm text-xs text-center lg:text-start my-2 lg:my-0">
                    <div className="flex lg:flex-row flex-col flex-wrap gap-2">
                      <li className="text-[#4FF255]">
                        <span className="text-black">
                          Lead generation campaign
                        </span>
                      </li>
                      <li className="text-[#4FF255]">
                        <span className="text-black">Google Ads</span>
                      </li>
                    </div>
                    <div className="flex lg:flex-row flex-col flex-wrap gap-2 mt-2">
                      <li className="text-[#4FF255]">
                        <span className="text-black">YouTube Special</span>
                      </li>
                      <li className="text-[#4FF255]">
                        <span className="text-black">Branding Special</span>
                      </li>
                    </div>
                    <div className="flex gap-2 mt-2 justify-center lg:justify-start">
                      <li className="text-[#4FF255]">
                        <span className="text-black">Traffic Special</span>
                      </li>
                    </div>
                  </div>
                  <Link to={'/contacts'} className="flex justify-center lg:justify-end mt-4">
                    <button className="lg:w-[140px] lg:h-[50px] w-[100px] h-[40px] text-xs lg:text-base bg-[#32B6AB] rounded-md text-white">
                      Contact Sales
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-auto mt-12">
              {updatedPriceData.marketingTrialPackage.map((data, index) => (
                <div
                  key={index}
                  className="w-full md:w-1/2 mx-auto lg:w-[25vw] xl:w-[21vw] bg-[#3F65ED] pt-4 mt-4 rounded-[10px]"
                >
                  <div className="flex flex-col text-start bg-[#E4E4E4] space-y-2 py-[5%] rounded-b-[10px]">
                    <span className="text-[#4D74FF] text-xl font-bold px-[10%]">
                      {data.name}{" "}
                      <span className="text-sm text-black font-normal">
                        {data.des}
                      </span>
                    </span>

                    <div className="lg:py-[15%] py-[10%] text-3xl text-[#1F4BE4] text-center bg-gradient-to-r to-[#DAFFFF] from-[#BCDFFF]">
                      <span>
                        {currencySymbol}
                        <span className="font-semibold ">{data.price}/-</span>
                      </span>
                    </div>

                    <div className="px-[10%] font-bold">
                      <p>Solutions</p>
                      <div className="flex flex-col text-sm font-normal">
                        {data.solution.map((solution, i) => (
                          <li className="text-[#4FF255]" key={i}>
                            <span className="text-black">{solution}</span>
                          </li>
                        ))}
                      </div>
                    </div>
                    <div className="px-[10%] flex justify-between pt-[10%]">
                      <button
                        className="text-xs text-[#018F84] border border-[#018F84] w-[80px] h-[30px] rounded bg-white"
                        onClick={() => handleAddToCart(data)}
                      >
                        Add to cart
                      </button>
                      <button
                        onClick={() => handleAddtoBuy(data)}
                        className="text-xs text-[#ffffff] bg-[#018F84] w-[80px] h-[30px] rounded"
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div />
          <div className="lg:pt-[1%] grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-y-6 pt-6 md:gap-x-12 lg:gap-x-6 ">
            {updatedPriceData.marketingBasicPackage.map((data, index) => {
              return (
                <div
                  key={index}
                  className="lg:w-[21vw] w-full bg-[#3F65ED] pt-4 rounded-[10px] h-fit"
                >
                  <div className="flex flex-col  text-start bg-[#E4E4E4] space-y-2  py-[5%] rounded-b-[10px]">
                    <span className="text-[#4D74FF] lg:text-[1.6vw] text-2xl font-bold px-[5%]">
                      {data.name}{" "}
                      <span className="text-sm text-black font-normal">
                        {data.des}
                      </span>
                    </span>

                    <div className="lg:py-[15%] py-[10%] text-3xl text-[#1F4BE4] text-center bg-gradient-to-r to-[#DAFFFF] from-[#BCDFFF]">
                      <span>
                        {currencySymbol}
                        <span className="font-semibold ">{data.price}/-</span>
                      </span>
                    </div>
                    <div className="px-[10%] font-bold">
                      <p>Solutions</p>
                      <div className="flex flex-col text-sm font-normal">
                        {data.solution.map((solution, i) => (
                          <li className="text-[#4FF255]" key={i}>
                            <span className="text-black">{solution}</span>
                          </li>
                        ))}
                      </div>
                    </div>
                    <div className="px-[10%] flex justify-between pt-[10%]">
                      <button
                        className="text-xs text-[#018F84] border border-[#018F84] w-[80px] h-[30px] rounded bg-white"
                        onClick={() => handleAddToCart(data)}
                      >
                        Add to cart
                      </button>
                      <button
                        onClick={() => handleAddtoBuy(data)}
                        className="text-xs text-[#ffffff]  bg-[#018F84] w-[80px] h-[30px] rounded"
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="border-t-2 border-black mt-14 mb-10" />
          <div className="lg:pt-[1%] grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-y-6 pt-6 md:gap-x-12 lg:gap-x-6 ">
            {updatedPriceData &&
              updatedPriceData.marketingPremiumPackage.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="lg:w-[21vw] w-full bg-[#3F65ED] pt-4 rounded-[10px] h-fit"
                  >
                    <div className="flex flex-col  text-start bg-[#E4E4E4] space-y-2  py-[5%] rounded-b-[10px]">
                      <span className="text-[#4D74FF] lg:text-[1.6vw] text-2xl font-bold px-[5%]">
                        {data.name}{" "}
                        <span className="text-sm text-black font-normal">
                          {data.des}
                        </span>
                      </span>

                      <div className="lg:py-[15%] py-[10%] text-3xl text-[#1F4BE4] text-center bg-gradient-to-r to-[#DAFFFF] from-[#BCDFFF]">
                        <span>
                          {currencySymbol}
                          <span className="font-semibold ">{data.price}/-</span>
                        </span>
                      </div>
                      <div className="px-[10%] font-bold">
                        <p>Solutions</p>
                        <div className="flex flex-col text-sm font-normal">
                          {data.solution.map((solution, i) => (
                            <li className="text-[#4FF255]" key={i}>
                              <span className="text-black">{solution}</span>
                            </li>
                          ))}
                        </div>
                      </div>
                      <div className="px-[10%] flex justify-between pt-[10%]">
                        <button
                          className="text-xs text-[#018F84] border border-[#018F84] w-[80px] h-[30px] rounded bg-white"
                          onClick={() => handleAddToCart(data)}
                        >
                          Add to cart
                        </button>
                        <button
                          onClick={() => handleAddtoBuy(data)}
                          className="text-xs text-[#ffffff]  bg-[#018F84] w-[80px] h-[30px] rounded"
                        >
                          Buy
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="pt-4 lg:pt-[1%] mb-16 lg:mb-32 mt-8 lg:mt-12 flex flex-col lg:flex-row justify-between  items-center relative w-full">
            <div className="w-full mt-8 lg:mt-0 flex flex-col lg:flex-row flex-wrap justify-center lg:justify-start items-center">
              {updatedPriceData.professionalMarketingPackage.map(
                (data, index) => (
                  <div
                    key={index}
                    className="w-full md:w-1/2 mx-auto lg:mx-0 lg:w-[25vw] xl:w-[21vw] bg-[#3F65ED] pt-4 mt-4 rounded-[10px]"
                  >
                    <div className="flex flex-col text-start bg-[#E4E4E4] space-y-2 py-4 lg:py-[5%] rounded-b-[10px]">
                      <span className="text-[#4D74FF] text-lg lg:text-xl font-bold px-4 lg:px-[10%]">
                        {data.name}{" "}
                        <span className="text-sm lg:text-base text-black font-normal">
                          {data.des}
                        </span>
                      </span>
                      <div className="py-4 lg:py-[10%] text-2xl lg:text-3xl text-[#1F4BE4] text-center bg-gradient-to-r to-[#DAFFFF] from-[#BCDFFF]">
                        <span>
                          {currencySymbol}
                          <span className="font-semibold">{data.price}/-</span>
                        </span>
                      </div>
                      <div className="px-4 lg:px-[10%] font-bold">
                        <p>Solutions</p>
                        <div className="flex flex-col text-sm lg:text-base font-normal">
                          {data.solution.map((solution, i) => (
                            <li className="text-[#4FF255]" key={i}>
                              <span className="text-black">{solution}</span>
                            </li>
                          ))}
                        </div>
                      </div>
                      <div className="px-4 lg:px-[10%] flex justify-between pt-4 lg:pt-[10%]">
                        <button
                          className="text-xs lg:text-sm text-[#018F84] border border-[#018F84] w-[70px] lg:w-[80px] h-[30px] rounded bg-white"
                          onClick={() => handleAddToCart(data)}
                        >
                          Add to cart
                        </button>
                        <button
                          onClick={() => handleAddtoBuy(data)}
                          className="text-xs lg:text-sm text-[#ffffff] bg-[#018F84] w-[70px] lg:w-[80px] h-[30px] rounded"
                        >
                          Buy
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="w-full  h-auto mt-8 lg:mt-0">
              <div className="border border-[#0000008e] flex flex-col md:flex-row text-start rounded-[35px]">
                <img
                  src={poster2}
                  className="w-full md:w-[35%] h-[50vh] lg:h-auto rounded-t-[35px] rounded-r-[35px] rounded-l-[35px] md:rounded-r-none md:rounded-t-none"
                  alt="poster"
                />
                <div className="mx-auto px-6 md:px-4 lg:px-[8%] rounded-r-[35px] rounded-[35px] md:rounded-none md:rounded-r-[35px] flex pb-8 justify-center flex-col bg-[#F3FEFF] lg:space-y-10 md:space-y-6 w-full">
                  <div className="text-center lg:text-start mb-2 mt-4 space-y-2">
                    <p className="text-[#4C74FF] text-xl xl:text-4xl lg:text-2xl font-bold">
                      Market your business with the services
                    </p>
                    <p className="text-sm lg:text-base font-semibold">
                      Find the right services for your business.
                    </p>
                  </div>
                  <Link to={'/contacts'} className="flex justify-center lg:justify-end mb-6 mt-4 pb-8 lg:pb-0">
                    <button  className="w-[100px] lg:w-[140px] h-[40px] lg:h-[50px] text-xs lg:text-base bg-[#32B6AB] rounded-md text-white">
                      Contact Sales
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div />
        </div>
      </div>
    </div>
  );
};

export default Marketing;
