const careerimg = {
  bg: require("./careerbg.svg"),
  careerimg1: require("./1.png"),
  careerimg2: require("./2.png"),
};
const careerdata = [
  {
    jobtitle: "Full stack developer",
    jobbrief1:
      `We are looking for a Full Stack Developer to produce scalable software solutions. 
      You’ll be part of a cross-functional team that’s responsible for the full software development life cycle, 
      from conception to deployment.
      
      As a Full Stack Developer, you should be comfortable around both front-end and back-end coding languages, d
      evelopment frameworks and third-party libraries.
       You should also be a team player with a knack for visual design and utility.
      `,
      jobbrief2:
      `As a Full Stack Developer, you should be comfortable around both front-end and back-end coding languages, d
      evelopment frameworks and third-party libraries.
       You should also be a team player with a knack for visual design and utility.
      `,
      experience : "0-1 years"
  },
  {
    jobtitle: "Full stack developer",
    jobbrief1:
      `We are looking for a Full Stack Developer to produce scalable software solutions. 
      You’ll be part of a cross-functional team that’s responsible for the full software development life cycle, 
      from conception to deployment.
      
      As a Full Stack Developer, you should be comfortable around both front-end and back-end coding languages, d
      evelopment frameworks and third-party libraries.
       You should also be a team player with a knack for visual design and utility.
      `,
      jobbrief2:
      `As a Full Stack Developer, you should be comfortable around both front-end and back-end coding languages, d
      evelopment frameworks and third-party libraries.
       You should also be a team player with a knack for visual design and utility.
      `,
      experience : "0-1 years"
  },
  {
    jobtitle: "Full stack developer",
    jobbrief1:
      `We are looking for a Full Stack Developer to produce scalable software solutions. 
      You’ll be part of a cross-functional team that’s responsible for the full software development life cycle, 
      from conception to deployment.
      
      As a Full Stack Developer, you should be comfortable around both front-end and back-end coding languages, d
      evelopment frameworks and third-party libraries.
       You should also be a team player with a knack for visual design and utility.
      `,
      jobbrief2:
      `As a Full Stack Developer, you should be comfortable around both front-end and back-end coding languages, d
      evelopment frameworks and third-party libraries.
       You should also be a team player with a knack for visual design and utility.
      `,
      experience : "0-1 years"
  },
  
  
];
export default {careerimg,careerdata};
