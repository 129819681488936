import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  FaCheckCircle,
  FaEnvelope,
  FaSmile,
  FaArrowRight,
} from "react-icons/fa";
import { motion } from "framer-motion";

const Success = () => {
  const navigate = useNavigate();
  const [redirectTime, setRedirectTime] = useState(9);

  useEffect(() => {
    const interval = setInterval(() => {
      setRedirectTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          navigate("/user");
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-t from-[#00C5B5] to-[#005F57]">
      <motion.div
        className="bg-[#0C3151] text-white p-10 rounded-lg shadow-lg mt-6 text-center"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <FaCheckCircle className="w-20 h-20 text-green-400 mx-auto" />
        <h1 className="text-3xl font-semibold mt-4">Order Confirmed! 🎉</h1>
        <p className="text-gray-300 mt-2">
          Thank you for your purchase{" "}
          <FaSmile className="inline text-yellow-400" />. Your order is being
          processed.
        </p>

        <motion.div
          className="mt-6 text-lg font-medium text-gray-400"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 1 }}
        >
          <p>
            <FaEnvelope className="inline text-blue-400 mr-2" />
            You will receive a confirmation email shortly.
          </p>
          <p className="mt-2">
            Redirecting to your{" "}
            <Link className="text-[#00C5B5] font-semibold underline" to="/user">
              Dashboard
            </Link>{" "}
            in {redirectTime} seconds.
          </p>
        </motion.div>

        <motion.div
          className="mt-8 flex justify-center"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <Link
            to="/user"
            className="bg-[#00C5B5] text-[#0C3151] px-6 py-2 rounded-lg hover:bg-[#005F57] hover:text-white flex items-center"
          >
            Go to Dashboard Now <FaArrowRight className="ml-2" />
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Success;
