import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import servicesImg from "../assets/services/index";
import pricePackageData from "../components/pricePackageData";
import poster from "../assets/pricing/poster1.png";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../redux/cart/cartSlice";
import Images from "../assets";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Marketing from "../components/pricing/Marketing";
import Designing from "../components/pricing/Designing";
import Offers from "../components/pricing/Offers";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../components/firebase.js";
import SignIn from "../components/SignIn.js";
import axios from "axios";

const Pricing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.cart);
  const [user, setUser] = useState(null);
  const { pathname } = useLocation();
  const [updatedPriceData, setUpdatedPriceData] = useState(pricePackageData);
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const userCountry = response.data.country_code;
        updatePricing(userCountry);
        // updatePricing('US');
      } catch (error) {
        console.error('Error fetching user country:', error);
        updatePricing('IN'); // Default to Indian pricing if API fails
      }
    };

    const formatPrice = (price) => {
      // Remove commas and convert to number
      const numericPrice = parseFloat(price.replace(/,/g, ''));
      return numericPrice;
    };

    const formatWithCommas = (price) => {
      // Convert the price to string and add commas as per Indian number system
      return price.toLocaleString('en-IN');
    };

    const updatePricing = (country) => {
      const conversionRates = {
        IN: { rate: 1, symbol: '₹' }, // Indian Rupee
        US: { rate: 0.032, symbol: '$' }, // US Dollar
        EU: { rate: 0.031, symbol: '€' }, // Euro
        // Add more countries with their rates and symbols as needed
      };

      const selectedCountry = conversionRates[country] || conversionRates['IN'];
      setCurrencySymbol(selectedCountry.symbol);

      const updatedData = {};

      // Iterate over each package type and update pricing
      Object.keys(pricePackageData).forEach((packageType) => {
        updatedData[packageType] = pricePackageData[packageType].map((item) => {
          const numericPrice = formatPrice(item.price);
          const updatedPrice = (numericPrice * selectedCountry.rate).toFixed(0);
          const formattedPrice = formatWithCommas(parseFloat(updatedPrice));

          return {
            ...item,
            price: formattedPrice,
          };
        });
      });

      setUpdatedPriceData(updatedData);
    };

    fetchUserCountry();
  }, [pricePackageData]);


  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  //   const totalQuantity = cart.reduce((acc, item) => acc + item.quantity, 0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);




  const handleAddToCart = (site) => {
    dispatch(addToCart(site));
  };

  const scrollToId = (id) => {
    const element = document.querySelector(`#${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleAddtoBuy = (site) => {
    
    dispatch(addToCart(site));
    navigate("/checkout");
  };

  return (
    <motion.div
      initial={{ opacity: 0, top: 0 }}
      animate={{ opacity: 1, top: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      className=" flex flex-col items-center"
    >
      <div className="flex relative items-end w-full  justify-center sm:pt-[15%] pt-[30%] bg-gradient-to-t to-[#001F89] from-[#94ACFF]   ">
        <div className=" flex justify-center relative z-[1] ">
          <div className="flex justify-center mt-[5%] w-[60%]  ">
            <svg
              width="1303"
              height="592"
              className=" w-[100%] h-[100%]"
              viewBox="0 0 1303 592"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.g
                initial={{ opacity: 1, y: 200 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 1.5 },
                }}
                filter="url(#filter0_d_243_29)"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M153.034 78.7593V587.124H756.51V353.293H622.774V263.582H456.226V183.838H319.997V78.7593H153.034ZM237.762 398.564C237.762 357.275 271.233 323.804 312.521 323.804C353.81 323.804 387.281 357.275 387.281 398.564V587.124H237.762V398.564ZM562.551 379.459C530.437 379.459 504.404 405.492 504.404 437.605V587.124H620.697V437.605C620.697 405.492 594.664 379.459 562.551 379.459Z"
                  fill="url(#paint0_linear_243_29)"
                />
              </motion.g>
              <motion.g
                initial={{ opacity: 1, y: 200 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.5 },
                }}
                filter="url(#filter1_d_243_29)"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M876.541 4C761.85 4 668.875 96.9749 668.875 211.665V587.125L720.791 587.125V212.761C720.791 126.743 790.523 57.0115 876.541 57.0115C962.558 57.0115 1032.29 126.743 1032.29 212.761V587.125L1084.21 587.125V211.665C1084.21 96.975 991.231 4 876.541 4Z"
                  fill="url(#paint1_linear_243_29)"
                />
              </motion.g>
              <motion.g
                initial={{ opacity: 1, y: 200 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { duration: 2, delay: 0.2 },
                }}
                filter="url(#filter2_d_243_29)"
              >
                <path
                  d="M620.697 479.554V587.54H1157.3V171.793H1054.3V241.154H972.067V328.373H874.88V402.717H756.51V479.554H620.697Z"
                  fill="url(#paint2_linear_243_29)"
                />
              </motion.g>
              <motion.circle
                initial={{ opacity: 1, y: -200 }}
                whileInView={{
                  opacity: 1,
                  y: [-500, 0, -100, 0, -25, 0],
                  transition: { duration: 0.5, delay: 0.2 },
                }}
                cx="1234.11"
                cy="520.114"
                r="68.1143"
                fill="url(#paint3_linear_243_29)"
              />
              <motion.g
                initial={{ opacity: 1, x: -50 }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: { duration: 1.5 },
                }}
                filter="url(#filter3_d_243_29)"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M83.8969 386.104L93.0341 395.242V587.125H0V395.242L9.13728 386.104L83.8969 386.104Z"
                  fill="url(#paint4_linear_243_29)"
                />
              </motion.g>
              <defs>
                <filter
                  id="filter0_d_243_29"
                  x="153.034"
                  y="74.7593"
                  width="667.476"
                  height="516.365"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="60" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_243_29"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_243_29"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_d_243_29"
                  x="668.875"
                  y="0"
                  width="469.331"
                  height="591.125"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="50" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_243_29"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_243_29"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter2_d_243_29"
                  x="620.697"
                  y="167.793"
                  width="600.607"
                  height="423.747"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="60" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_243_29"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_243_29"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter3_d_243_29"
                  x="0"
                  y="382.104"
                  width="157.034"
                  height="209.02"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="60" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_243_29"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_243_29"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_243_29"
                  x1="463.702"
                  y1="164.733"
                  x2="463.702"
                  y2="587.124"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E9BF6B" />
                  <stop offset="1" stop-color="#836B3C" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_243_29"
                  x1="876.541"
                  y1="83.517"
                  x2="876.541"
                  y2="467.319"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF8673" />
                  <stop offset="1" stop-color="#8C4A40" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_243_29"
                  x1="889.416"
                  y1="242.815"
                  x2="889.416"
                  y2="567.189"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#74D0E8" />
                  <stop offset="1" stop-color="#365D67" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_243_29"
                  x1="1269.73"
                  y1="452.289"
                  x2="1234.11"
                  y2="588.229"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#E7F97D" />
                  <stop offset="1" stop-color="#0F1C0F" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_243_29"
                  x1="46.5171"
                  y1="386.104"
                  x2="46.5171"
                  y2="581.725"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#C781FF" />
                  <stop offset="1" stop-color="#613881" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className=" absolute font-bold sm:text-[5vw] text-[8vw] text-white bottom-[86%] left-[20%]">
            Pricing
          </div>
        </div>
      </div>
      <div className=" flex flex-col w-full justify-center items-center ">
        <div className="services-gradient_1 w-full   ">
          <div className="text-white flex items-center justify-center ">
            <img
              className="sm:w-[6%] w-[10%]"
              src={servicesImg.str1}
              alt="string"
            ></img>
            <div>
              <p className="text-white font-Inter sm:text-3xl text-xs font-semibold">
                All the Services you need in one place
              </p>
              <p>From software development ,design and digital marketing</p>
            </div>
            <img
              className="sm:w-[6%]  w-[10%]"
              src={servicesImg.str2}
              alt="string"
            ></img>
          </div>
        </div>

        <div className=" flex flex-col w-full bg-[#DAF9F9]  items-center">
          <div className=" max-w-[80%] w-[80%] my-4 mx-[5%] portfoliobg flex sm:flex-row flex-col-reverse sm:justify-around  items-center rounded-lg  py-4">
            <motion.ul
              initial={{ scale: 0.8, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.2, delay: 0.2 }}
              className="flex sm:flex-row flex-col justify-around font-Raleway sm:text-[1.3vw] text-[3vw] sm:w-[70%] py-2 sm:py-0 font-bold"
            >
              {[
                {
                  id: "software",
                  type: "Software Development",
                },
                {
                  id: "digital",
                  type: "Digital Marketing",
                },
                {
                  id: "branding",
                  type: "Branding and Design",
                },
                {
                  id: "combos",
                  type: "Limited edition combos",
                },
              ].map((category, index) => (
                <li
                  key={index}
                  onClick={() => scrollToId(category.id)}
                  className={`flex items-center justify-center cursor-pointer hover:bg-[#F2F2F2] transition-all duration-200 ease-in-out px-3 py-1 rounded-xl `}
                >
                  {category.type}
                </li>
              ))}
            </motion.ul>
          </div>
        </div>
      </div>

      <div className="w-[100%] px-[5%] bg-[#DAF9F9] ">
        <div className="pt-[1%] flex flex-col lg:flex-row justify-between items-center relative w-full">
          <div className="w-full lg:w-1/2">
            <div className="flex w-full mt-[10%] md:mt-0">
              <span className="text-2xl border-b border-black font-bold">
                Trial Package{" "}
                <span className="text-xs font-normal">(5 Business days)</span>
              </span>
            </div>
            {updatedPriceData.trialPackage && updatedPriceData.trialPackage.map((data, index) => (
              <div
                key={index}
                className="w-full md:w-1/2 mx-auto lg:mx-0 lg:w-[25vw] xl:w-[21vw] bg-[#3F65ED] pt-4 mt-4 rounded-[10px]"
              >
                <div className="flex flex-col text-start bg-[#E4E4E4] space-y-2 py-[5%] rounded-b-[10px]">
                  <span className="text-[#4D74FF] text-xl font-bold px-[10%]">
                    {data.name}{" "}
                    <span className="text-sm text-black font-normal">
                      {data.des}
                    </span>
                  </span>

                  <div className="lg:py-[15%] py-[10%] text-3xl text-[#1F4BE4] text-center bg-gradient-to-r to-[#DAFFFF] from-[#BCDFFF]">
                  <span>
                {currencySymbol}<span className="font-semibold">{data.price}/-</span>
              </span>
                  </div>
                  <div className="px-[10%]">
                    <p className="font-bold">Things covered</p>
                    <div className="flex flex-col text-sm">
                      {data.things.map((thing, i) => (
                        <li className="text-[#4FF255]" key={i}>
                          <span className="text-black">{thing}</span>
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="px-[10%] font-bold">
                    <p>Solutions</p>
                    <div className="flex flex-col text-sm font-normal">
                      {data.solution.map((solution, i) => (
                        <li className="text-[#4FF255]" key={i}>
                          <span className="text-black">{solution}</span>
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="px-[10%] flex justify-between pt-[10%]">
                    <button
                      className="text-xs text-[#018F84] border border-[#018F84] w-[80px] h-[30px] rounded bg-white"
                      onClick={() => handleAddToCart(data)}
                    >
                      Add to cart
                    </button>
                    <button
                      onClick={() => handleAddtoBuy(data)}
                      className="text-xs text-[#ffffff] bg-[#018F84] w-[80px] h-[30px] rounded"
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full lg:w-[50vw] h-auto md:h-[35vh] mt-12 lg:mt-0">
            <div className="border border-[#0000008e] flex text-start rounded-[35px] flex-col md:flex-row">
              <img
                src={poster}
                className="w-full md:w-[35%] h-[50vh] lg:h-auto mix-blend-difference rounded-t-[35px] rounded-r-[35px] rounded-l-[35px] md:rounded-r-none md:rounded-t-none"
                alt="poster"
              />
              <div className="mx-auto px-6 md:px-4 lg:px-[8%] rounded-r-[35px] rounded-[35px] md:rounded-none md:rounded-r-[35px] flex pb-8 justify-center flex-col bg-[#F3FEFF] lg:space-y-10 md:space-y-6 w-full">
                <div className="text-center lg:text-start mb-2 mt-4 space-y-2">
                  <p className="text-[#4C74FF] xl:text-4xl lg:text-2xl md:text-xl text-lg font-bold">
                    Need to discuss with <br /> the consultant.
                  </p>
                  <p className="lg:text-base font-semibold text-sm">
                    Find the right services for your business.
                  </p>
                </div>
                <div className="lg:text-sm text-xs text-center lg:text-start my-2 lg:my-0">
                  <div className="flex lg:flex-row flex-col gap-2">
                    <li className="text-[#4FF255]">
                      <span className="text-black">Having query</span>
                    </li>
                    <li className="text-[#4FF255]">
                      <span className="text-black">Custom software</span>
                    </li>
                  </div>
                  <div className="flex gap-2 flex-col flex-wrap mt-2  lg:flex-row">
                    <li className="text-[#4FF255]">
                      <span className="text-black">App development</span>
                    </li>
                    <li className="text-[#4FF255]">
                      <span className="text-black">Custom functions site</span>
                    </li>
                  </div>
                </div>
                <Link to={'/contacts'} className="flex justify-center lg:justify-end mt-4">
                  <button className="lg:w-[140px] lg:h-[50px] w-[100px] h-[35px] text-xs lg:text-base bg-[#32B6AB] rounded-md text-white">
                    Contact Sales
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="lg:text-[5vw] text-[7vw] font-bold lg:font-normal mt-[12%] text-center lg:text-start"
          id="software"
        >
          SOFTWARE DEVELOPMENT
        </div>
        <div className="flex w-[100%] border-b-2 border-black ">
          <span className="lg:text-2xl text-xl mt-6 lg:font-bold font-semibold">
            Basic package{" "}
            <span className="text-xs font-normal">{`(Single and Multiple pages)`}</span>
          </span>
        </div>
        <div className="lg:pt-[1%] grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-y-6 pt-6 md:gap-x-12 lg:gap-x-6 ">
          {updatedPriceData.basicPackage && updatedPriceData.basicPackage.map((data, index) => {
            return (
              <div
                key={index}
                className="lg:w-[21vw] w-full bg-[#3F65ED] pt-4 rounded-[10px]"
              >
                <div className="flex flex-col  text-start bg-[#E4E4E4] space-y-2  py-[5%] rounded-b-[10px]">
                  <span className="text-[#4D74FF] lg:text-[1.6vw] text-2xl font-bold px-[5%]">
                    {data.name}{" "}
                    <span className="font-normal text-sm">{`(${data.type})`}</span>{" "}
                    <span className="text-sm text-black font-normal">
                      {data.des}
                    </span>
                  </span>

                  <div className="lg:py-[15%] py-[10%] text-3xl text-[#1F4BE4] text-center bg-gradient-to-r to-[#DAFFFF] from-[#BCDFFF]">
                    <span>
                      {currencySymbol}<span className="font-semibold ">{data.price}/-</span>
                    </span>
                  </div>
                  <div className="px-[10%] font-bold">
                    <p>Solutions</p>
                    <div className="flex flex-col text-sm font-normal">
                      {data.solution.map((solution, i) => (
                        <li className="text-[#4FF255]" key={i}>
                          <span className="text-black">{solution}</span>
                        </li>
                      ))}
                    </div>
                  </div>
                  <div className="px-[10%] flex justify-between pt-[10%]">
                    <button
                      className="text-xs text-[#018F84] border border-[#018F84] w-[80px] h-[30px] rounded bg-white"
                      onClick={() => handleAddToCart(data)}
                    >
                      Add to cart
                    </button>
                    <button
                      onClick={() => handleAddtoBuy(data)}
                      className="text-xs text-[#ffffff]  bg-[#018F84] w-[80px] h-[30px] rounded"
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex w-[100%] border-b-2 mt-8 border-black ">
          <span className="lg:text-2xl text-xl lg:font-bold font-semibold">
            Animated package{" "}
            <span className="text-xs font-normal">{`(Single and Multiple pages)`}</span>
          </span>
        </div>
        <div className="lg:pt-[1%] grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-y-6 pt-6 md:gap-x-12 lg:gap-x-6 ">
          {updatedPriceData.animatedPackage&&updatedPriceData.animatedPackage.map((data, index) => {
            return (
              <div key={index} className="lg:w-[21vw] w-full  pt-4 ">
                <div className="flex flex-col justify-between  text-start bg-[#E4E4E4] border-t-[17px] border-[#4D74FF] rounded-[10px] space-y-2  py-[5%] rounded-b-[10px]">
                  <span className="text-[#4D74FF] lg:text-[1.6vw] text-2xl font-bold px-[5%]">
                    {data.name}{" "}
                    <span className="text-sm text-black font-normal">
                      {data.des}
                    </span>
                  </span>

                  <div className="py-[15%] text-3xl text-[#1F4BE4] text-center bg-gradient-to-r to-[#DAFFFF] from-[#BCDFFF]">
                    <span>
                      {currencySymbol}<span className="font-semibold ">{data.price}/-</span>
                    </span>
                  </div>
                  <div className="">
                    <div className="px-[10%] font-bold ">
                      <p>Solutions</p>
                      <div className="flex flex-col text-sm font-normal">
                        {data.solution.map((solution, i) => (
                          <li className="text-[#4FF255]" key={i}>
                            <span className="text-black">{solution}</span>
                          </li>
                        ))}
                      </div>
                    </div>
                    <div className="px-[10%] flex justify-between pt-[10%]">
                      <button
                        className="text-xs text-[#018F84] border border-[#018F84] w-[80px] h-[30px] rounded bg-white"
                        onClick={() => handleAddToCart(data)}
                      >
                        Add to cart
                      </button>
                      <button
                        onClick={() => handleAddtoBuy(data)}
                        className="text-xs text-[#ffffff]  bg-[#018F84] w-[80px] h-[30px] rounded"
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex w-[100%] border-b-2 mt-8 border-black ">
          <span className="lg:text-2xl text-xl lg:font-bold font-semibold">
            Premium package{" "}
          </span>
        </div>
        <div className="lg:pt-[1%] grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-y-6 pt-6 md:gap-x-12 lg:gap-x-6 pb-20">
          {updatedPriceData.premiumPackage&&updatedPriceData.premiumPackage.map((data, index) => {
            return (
              <div key={index} className="lg:w-[21vw] w-full  pt-4 ">
                <div className="flex flex-col justify-between  text-start border-t-[17px] border-[#4D74FF] rounded-[10px]  bg-[#E4E4E4] space-y-2  py-[5%] rounded-b-[10px]">
                  <span className="text-[#4D74FF] lg:text-[1.6vw] text-2xl font-bold px-[5%]">
                    {data.name}{" "}
                    <span className="text-sm text-black font-normal">
                      {data.des}
                    </span>
                  </span>

                  <div className="py-[15%] text-3xl text-[#1F4BE4] text-center bg-gradient-to-r to-[#DAFFFF] from-[#BCDFFF]">
                    <span>
                      {currencySymbol}<span className="font-semibold ">{data.price}/-</span>
                    </span>
                  </div>
                  <div className="">
                    <div className="px-[10%] font-bold">
                      <p>Solutions</p>
                      <div className="flex flex-col text-sm font-normal">
                        {data.solution.map((solution, i) => (
                          <li className="text-[#4FF255]" key={i}>
                            <span className="text-black">{solution}</span>
                          </li>
                        ))}
                      </div>
                    </div>
                    <div className="px-[10%] flex justify-between pt-[10%]">
                      <button
                        className="text-xs text-[#018F84] border border-[#018F84] w-[80px] h-[30px] rounded bg-white"
                        onClick={() => handleAddToCart(data)}
                      >
                        Add to cart
                      </button>
                      <button
                        onClick={() => handleAddtoBuy(data)}
                        className="text-xs text-[#ffffff]  bg-[#018F84] w-[80px] h-[30px] rounded"
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Marketing />
      <Designing />
      <Offers />
      <Link to={"/checkout"} className="relative bg-black">
        <div className=" fixed right-3 z-40 bottom-[15%] flex justify-center items-center shadow-xl  bg-[#32B6AB] lg:w-[56px] lg:h-[56px] w-[40px] h-[40px] rounded-full">
          <span className=" absolute right-0 top-0 bg-red-600 rounded-full z-50 lg:w-[25px] w-[16px] hover:scale-125 cursor-pointer transition-all ease-in-out duration-300 lg:h-[25px] h-[16px] flex items-center justify-center text-xs text-white">
            {cart.length}
          </span>
          <img
            src={Images.cart}
            className="w-[50%] mx-auto lg:mt-[15px] mt-[10px] hover:scale-125 cursor-pointer transition-all ease-in-out duration-300"
            alt=""
          />

          {/* <div className=" font-Montserrat mt-2 text-lg font-semibold">My cart</div> */}
        </div>
      </Link>
    </motion.div>
  );
};

export default Pricing;
