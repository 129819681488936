import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFinalPrice, setCouponCode } from "../../redux/cart/cartSlice";
import toast from "react-hot-toast";
import WheelComponent from "react-wheel-of-prizes";
import { useLocation } from "react-router-dom";
import { collection, doc, getDoc } from "firebase/firestore"; 
import { db } from "../firebase";

const OrderSummary = ({ nextStep }) => {

  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  const segments = [
    "2% OFF",
    "13% OFF",
    "4% OFF",
    "12% OFF",
    "5% OFF",
    "8% OFF",
    "10% OFF",
  ];

  const segColors = [
    "#3498DB",
    "#E74C3C",
    "#F1C40F",
    "#8E44AD",
    "#2ECC71",
    "#F39C12",
    "#1ABC9C",
  ];

  const dispatch = useDispatch();
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [couponCode, setCouponCodeInput] = useState("");

  const cart = useSelector((state) => state.cart.cart);
  const finalPrice = useSelector((state) => state.cart.finalPrice);

  const calculateTotalPrice = () => {
    return cart.reduce(
      (acc, item) => acc + parseFloat(item.price.replace(/,/g, "")),
      0
    );
  };

  const [totalPrice, setTotalPrice] = useState(calculateTotalPrice());
  const [gst, setGst] = useState(0);

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
    setGst((totalPrice * 18) / 100); // Calculate 18% GST on the total price
  }, [cart, totalPrice]);

  const handleCouponInput = async () => {
    try {
      const couponDocRef = doc(collection(db, "coupons"), "currentCoupon");
      const couponDoc = await getDoc(couponDocRef);

      if (couponDoc.exists()) {
        const { genCode, manCode } = couponDoc.data();
        const isValidCoupon =
          couponCode === genCode || couponCode === manCode;

        if (isValidCoupon) {
          const percentage = parseInt(couponCode.slice(-2));
          applyDiscount(percentage);
        } else {
          toast.error("Invalid coupon code.");
        }
      } else {
        toast.error("Coupon code not found.");
      }
    } catch (error) {
      console.error("Error fetching coupon code: ", error);
      toast.error("Failed to validate the coupon code.");
    }
  };

  const applyDiscount = (percentage) => {
    setDiscountPercentage(percentage);
    const discountAmount = (totalPrice * percentage) / 100;
    const newTotalPrice = Math.max(0, totalPrice - discountAmount);

    dispatch(setFinalPrice(newTotalPrice));
    dispatch(setCouponCode(true));
    setIsCouponApplied(true);
    toast.success(`Congratulations! ${percentage}% discount applied.`);
  };

  const onFinished = (winner) => {
    toast.success("Congratulations! You won: " + winner);
    const percentage = parseInt(winner.match(/\d+/)[0]);
    applyDiscount(percentage);
  };

  return (
    <div className="text-start">
      <div className="flex flex-col-reverse lg:flex-row gap-5 items-start max-w-7xl mx-auto">
        <div className="border w-full lg:w-[65%] mt-10 lg:mt-0">
          <div className="bg-[#3399A5] p-[5%] text-xl text-white">
            ORDER SUMMARY
          </div>
          <div className="bg-[#E4F3FF] flex flex-col py-[3%] lg:px-14 px-2 max-h-[70vh] lg:max-h-[40vh] space-y-10 overflow-y-auto">
            {cart &&
              cart.map((item, index) => (
                <div key={index}>
                  <div>
                    <div className="flex justify-between items-center lg:text-xl text-base font-semibold">
                      <p>{item.name} Pack</p>
                      <p>₹{item.price}.00</p>
                    </div>
                    <p className="flex mt-2 flex-wrap text-[#676767]">
                      {item.solution.map((s, idx) => (
                        <p key={idx} className="mr-2 text-sm">
                          *{s}
                        </p>
                      ))}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          <div className="bg-[#E4F3FF] px-14 pb-4">
            <p className="text-xl font-semibold">Apply Coupon Code</p>
            <div className="bg-[#F6F6F6] text-base py-2 mt-4 font-semibold flex justify-start px-[2%] gap-[5%] border border-black rounded-lg">
              <span>
                <div className="text-[#989898]">
                  {discountPercentage}% off on selected items from software and marketing
                </div>
              </span>
              {isCouponApplied ? (
                <span>{discountPercentage}% off applied</span>
              ) : (
                <input
                  className="bg-transparent outline-none"
                  placeholder="Enter coupon code or Spin"
                  value={couponCode}
                  onChange={(e) => setCouponCodeInput(e.target.value)}
                  onBlur={handleCouponInput}
                />
              )}
            </div>
          </div>
          {isCouponApplied ? (
            ""
          ) : (
            <div className="md:flex hidden flex-col sm:px-14 px-2 mt-20">
              <div className="text-2xl font-bold">
                <p>Try your luck here</p>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "-80px",
                  marginBottom: "-300px",
                  fontSize: "14px",
                  paddingBottom: "-100px",
                }}
              >
                <WheelComponent
                  segments={segments}
                  segColors={segColors}
                  onFinished={onFinished}
                  buttonText="Spin"
                  isOnlyOnce={true}
                  size={180}
                  upDuration={400}
                  downDuration={500}
                  fontFamily="Arial"
                />
              </div>
            </div>
          )}
          <div className="flex justify-center lg:justify-end lg:mr-14 my-8 lg:my-[6%]">
            <button
              onClick={nextStep}
              className="bg-[#FF6161] text-lg text-white border px-4 py-1 md:px-8 md:py-[10px] rounded-md"
            >
              Continue
            </button>
          </div>
        </div>
        <div className="border bg-[#F6FBFF] border-[#3399A5] lg:w-[32%] w-full">
          <div className="bg-[#E0E0E0] text-[#7E7E7E] p-[5%] py-7 border-b border-gray-300 font-bold">
            ORDER SUMMARY
          </div>
          <div className="px-8 space-y-3 my-8">
            <div className="flex text-lg font-semibold justify-between">
              <p>Items {`(${cart.length})`}</p>
              <p className="font-medium">₹{totalPrice.toFixed(2)}</p>
            </div>
            <div className="flex text-lg font-semibold justify-between">
              <p>Discounts</p>
              <p className="font-medium">
                {isCouponApplied ? `₹${totalPrice - finalPrice}.00` : "₹0.00"}
              </p>
            </div>
            <div className="flex text-lg font-semibold justify-between">
              <p>GST (18%)</p>
              <p className="font-medium">₹{gst.toFixed(2)}</p>
            </div>
          </div>
          <div className="flex justify-between px-8 bg-[#E0E0E0] text-lg font-bold py-4">
            <p>Total Payable</p>
            <p>₹{isCouponApplied ? (finalPrice + gst).toFixed(2) : (totalPrice + gst).toFixed(2)}</p>
          </div>
          <div className="flex justify-between px-8 text-[#3399A5] text-lg font-bold my-4">
            <p>Total Savings</p>
            <p>{isCouponApplied ? `₹${(totalPrice - finalPrice).toFixed(2)}` : "₹0.00"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
