export const portfoliodata = [
    {   
        description: "A landscaping company that lists all services to increase visibility in search results related to landscaping activities.",
        imageUrl: require('../taraang.png'),  
        heading: "Taraaang Landscape",
        hashtags: ["#Website", "#SEO"],
        width: "500",
        height: 455,
        gridarea: 'Header',
        link:'https://taraaanglandscape.com/'
    },
    {   
        description: "Innovative application for the food sector, streamlining orders and enhancing customer experience.",
        imageUrl: require('../Food Sector - Sicuaura Innoviz .png'),
        heading: "Food Sector - SicuAura Innoviz",
        hashtags: ["#UI/UX Website", "#restaurant"],
        width: "500",
        height: 455,
        gridarea: 'Menu',
        link:'https://fastfoodinnovizbysicu-aura.netlify.app/'
    },
    {
        description: "State-of-the-art gym with the latest equipment and personal training services.",
        imageUrl: require('../sicu-aura-gym.png'),
        heading: "SicuAura Gym",
        hashtags: ["#gym", "#fitness", "#health"],
        width: "500",
        height: 1000,
        gridarea: 'Main',
        link:'https://sicu-aurainnoviz.netlify.app/'
    },
];

export const extradata = [
  {
    description: "Luxury hotel website showcasing rooms, amenities, and booking options.",
    imageUrl: require('../hotel.png'),
    heading: "Luxury Hotel",
    hashtags: ["#hotel", "#luxury", "#booking"],
    width: "500",
    height: 455,
    gridarea: 'Sidebar',
    link: 'https://sicu-aura-estate-4.vercel.app/'
},
{
    description: "Interior design portfolio website featuring various projects and client testimonials.",
    imageUrl: require('../interior.png'),
    heading: "Interior Design",
    hashtags: ["#interior", "#design", "#portfolio"],
    width: "500",
    height: 455,
    gridarea: 'Content',
    link: 'https://sicu-aura-innoviz-interior.vercel.app/'
},
{
  description: "Travel booking website offering flights, hotels, and holiday packages with special deals and customer reviews.",
  imageUrl: require('../Travel.png'),
  heading: "Travel Booking",
  hashtags: ["#travel", "#booking", "#flights", "#hotels"],
  width: "500",
  height: 455,
  gridarea: 'Content',
  link: 'https://sicu-aura-tour-1.vercel.app/home'
},

{
    description: "Innovative application for a restaurant to streamline orders and enhance customer experience.",
    imageUrl: require('../restaurant.png'),
    heading: "Restaurant App",
    hashtags: ["#restaurant", "#app", "#orders"],
    width: "500",
    height: 455,
    gridarea: 'Footer',
    link: 'https://delicacy-2929.vercel.app/'
},
{
    description: "Clinic website featuring appointment scheduling and patient management system.",
    imageUrl: require('../clinic.png'),
    heading: "Clinic Website",
    hashtags: ["#clinic", "#healthcare", "#appointments"],
    width: "500",
    height: 455,
    gridarea: 'Footer',
    link: 'https://sicu-aura-innoviz-clinic.netlify.app/'
},

  {
    description:
      "Branding for events to increase awareness and attendance through effective marketing strategies.",
    imageUrl: require("../Events.png"),
    heading: "Events Branding",
    hashtags: ["#events", "#branding", "#marketing"],
    link: "https://innoviz-event-manger.netlify.app/",
    category: ["Digital marketing"],
  },
  {
    description:
      "A website for nearby restaurants, enabling easy booking and ordering of fast food.",
    imageUrl: require("../Food.png"),
    heading: "Food Booking Website",
    hashtags: ["#food", "#restaurant", "#booking"],
    link: "https://resturantdemobysicu-aurainnoviz.netlify.app/",
    category: ["UI/UX Website", "Development"],
  },
  {
    description:
      "Comprehensive branding for hospitals, enhancing their presence and trust within the community.",
    imageUrl: require("../Hospital.png"),
    heading: "Hospital Branding",
    hashtags: ["#hospital", "#branding", "#healthcare"],
    link: "https://multispecialitysicu-aura.netlify.app/",
    category: ["Digital marketing"],
  },
  {
    description:
      "Branding services for pet-related businesses, increasing awareness and customer engagement.",
    imageUrl: require("../Pet.png"),
    heading: "Pet Business Branding",
    hashtags: ["#pets", "#branding", "#business"],
    link: "https://innoviz-pet-shop.netlify.app/",
    category: ["Digital marketing"],
  },
  {
    description:
      "Application for the motor industry, providing seamless user experience and efficient service management.",
    imageUrl: require("../Sicu-Aura Motor.png"),
    heading: "SicuAura Motor App",
    hashtags: ["#motor", "#application", "#services"],
    link: "https://sicu-aura-motors.netlify.app/",
    category: ["UI/UX mobile", "Development"],
  },
  {
    description:
      "Dental clinic application by SicuAura Innoviz, improving patient management and appointment scheduling.",
    imageUrl: require("../Sicu-aura Innoviz Dental Clinic.png"),
    heading: "Dental Clinic App",
    hashtags: ["#dental", "#clinic", "#application"],
    link: "https://sicu-aura-innoviz-medical-2.vercel.app/",
    category: ["UI/UX mobile", "Development"],
  },
  {
    description:
      "Digital marketing services for educational institutions, boosting online presence and student enrollment.",
    imageUrl: require("../Sicu-aura-Collage.png"),
    heading: "College Digital Marketing",
    hashtags: ["#college", "#marketing", "#digital"],
    link: "https://innoviz-education.netlify.app/",
    category: ["Digital marketing"],
  },
  {
    description:
      "Digital marketing strategies for pet businesses, enhancing visibility and customer interaction online.",
    imageUrl: require("../Sicu-aura-pets.png"),
    heading: "Pet Digital Marketing",
    hashtags: ["#pets", "#marketing", "#digital"],
    link: "https://innovizpetzone.netlify.app/",
    category: ["Digital marketing"],
  },

 
];

      