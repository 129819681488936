import React from "react";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const PrivacyModal = ({ togglePrivacyModal }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full text-start flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="bg-gray-200 p-4 rounded-lg shadow-lg md:w-[90vw] w-full max-h-[95vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6 border-b border-gray-500 pb-4">
          <h2 className="text-2xl font-semibold">Privacy Policy</h2>
          <p
            className="cursor-pointer text-xl p-2 rounded-md bg-red-500 text-white hover:bg-red-600"
            onClick={togglePrivacyModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="text-sm text-gray-700 space-y-6">
          <p>
            This Privacy Policy describes how Sicu-aura Innoviz collects, uses,
            and discloses your information in connection with your use of our
            website and services.
          </p>
          <p>
            <span className="font-semibold">1. Information We Collect</span>
            <br />
            We collect several types of information for various purposes to
            improve our Services to you.
            <br />
            <br />
            <span className="font-semibold">Personal Information:</span>
            <br />
            This may include your name, email address, phone number, and company
            name. We collect this information when you contact us, request a
            quote, or use certain features of our Services.
            <br />
            <br />
            <span className="font-semibold">Usage Data:</span>
            <br />
            We may collect data about your activity on our website, such as the
            pages you visit, the time you spend on the website, and the links
            you click. This data is collected through cookies and other tracking
            technologies.
          </p>
          <p>
            <span className="font-semibold">2. Use of Information</span>
            <br />
            We use the information we collect for various purposes, including:
            <ul className="list-disc pl-6">
              <li>To provide and improve our Services</li>
              <li>To personalize your experience</li>
              <li>To respond to your inquiries and requests</li>
              <li>
                To send you marketing and promotional materials (with your
                consent)
              </li>
              <li>To analyze how you use our Services</li>
            </ul>
          </p>
          <p>
            <span className="font-semibold">3. Sharing of Information</span>
            <br />
            We may share your information with third-party service providers who
            help us operate our business and provide our Services. These service
            providers are contractually obligated to keep your information
            confidential and secure.
            <br />
            <br />
            We may also disclose your information if we are required to do so by
            law or in the good faith belief that such disclosure is necessary to
            comply with a legal process, protect our rights, or protect the
            safety of others.
          </p>
          <p>
            <span className="font-semibold">4. Data Retention</span>
            <br />
            We will retain your information for as long as necessary to provide
            the Services, comply with our legal obligations, resolve disputes,
            and enforce our agreements.
          </p>
          <p>
            <span className="font-semibold">5. Your Choices</span>
            <br />
            You have certain choices regarding your information:
            <ul className="list-disc pl-6">
              <li>
                You can opt-out of receiving marketing communications from us by
                following the unsubscribe instructions in those communications.
              </li>
              <li>
                You can access and update your personal information by
                contacting us.
              </li>
            </ul>
          </p>
          <p>
            <span className="font-semibold">
              6. Cookies and Tracking Technologies
            </span>
            <br />
            We use cookies and other tracking technologies to track the activity
            on our Services and hold certain information. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to
            use some portions of our Services.
          </p>
          <p>
            <span className="font-semibold">7. Security</span>
            <br />
            We take reasonable steps to protect your information from
            unauthorized access, disclosure, alteration, or destruction.
            However, no internet transmission or electronic storage is 100%
            secure.
          </p>
          <p>
            <span className="font-semibold">8. Children's Privacy</span>
            <br />
            Our Services are not directed to children under 13. We do not
            knowingly collect personal information from children under 13. If
            you are a parent or guardian and you are aware that your child has
            provided us with personal information, please contact us. We will
            take steps to remove the information from our servers.
          </p>
          <p>
            <span className="font-semibold">
              9. Changes to this Privacy Policy
            </span>
            <br />
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            <span className="font-semibold">10. Contact Us</span>
            <br />
            If you have any questions about this Privacy Policy, please contact
            us by email:{" "}
            <a href="mailto:innoviz@sicu-aura.com" className="text-blue-500">
              innoviz@sicu-aura.com
            </a>{" "}
            or by phone: <span className="text-blue-500">+91 6289179476</span>.
          </p>
    
        </div>
      </div>
    </div>
  );
};

export default PrivacyModal;
