import React, { useState } from "react";
import Images from "../assets";
import { Link, useNavigate } from "react-router-dom";
import PrivacyModal from "./modal/PrivacyModal";
import TermModal from "./modal/TermModal";
import FAQModal from "./modal/RefundModel";

const Footer = () => {
  const [termModal, setTermModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [faqModal, setFAQModal] = useState(false);
  const navoagetTo = useNavigate();

  const togglePrivacyModal = () => {
    // setPrivacyModal(!privacyModal);
    navoagetTo("/privacy");
  };

  const toggleFaqModal = () => {
    // setFAQModal(!faqModal);
    navoagetTo("/refund");
  };

  const toggleTermModal = () => {
    // setTermModal(!termModal);
    navoagetTo("/terms");
  };

  return (
    <div className=" bg-footer-bg bg-cover bg-no-repeat w-full flex flex-col justify-center items-center overflow-hidden">
      <div className=" w-[80%] flex flex-row mt-[2%] font-Raleway text-white justify-between relative">
        <div className=" flex flex-col lg:w-[70%] mb-[2%] w-[100%] ">
          <div className=" flex flex-wrap justify-between">
            <div className=" flex flex-col">
              <div>
                <img src={Images.footerlogo.default} className="" alt="" />
              </div>
              <div className=" text-left font-semibold md:text-base md:leading-[30px] text-xs mt-[5%]">
                The purity and richness of these <br />
                organic walnuts are unmatched. <br /> A daily dose of nature's
                good.
              </div>
            </div>
            <div className=" flex flex-col md:mt-0 mt-[10%]">
              <h1 className=" font-semibold md:text-lg text-sm ">
                Quick Links
              </h1>
              <ul className=" flex flex-col mt-[5%]  text-xs gap-4 text-left">
                <Link to={"/"}>Home</Link>
                <Link to={"/services"}>Services</Link>
                <Link to={"/work"}>Portfolio</Link>
                <Link to={"/about"}>About Us</Link>
                <Link to={"/Contacts"}>Contact</Link>
              </ul>
            </div>
            <div className="md:mt-0 mt-[10%]">
              <h1 className=" text-left font-semibold md:text-lg text-sm">
                Quick Links
              </h1>
              <ul className=" flex flex-col mt-[5%] text-xs gap-4 text-left">
                <li className="cursor-pointer" onClick={toggleTermModal}>
                  Terms & Conditions
                </li>
                <li className="cursor-pointer" onClick={togglePrivacyModal}>
                  Privacy Policy
                </li>
                <li className="cursor-pointer" onClick={toggleFaqModal}>
                  Refund Policy
                </li>
                <Link
                  to={"/startProject"}
                  className="bg-gradient-to-r from-[#00C5B5] to-[#005F57] font-semibold  text-xs flex items-center justify-center w-[100%] py-[10%]  rounded-md text-center text-white"
                >
                  Start A Project
                </Link>
              </ul>
            </div>
          </div>
          <div className=" flex md:flex-row flex-col mt-[5%] justify-between">
            <div className="flex flex-col">
              <h1 className=" font-bold md:text-lg text-sm  text-left">
                Subscribe Our Newsletter
              </h1>
              <div className=" flex flex-row mt-[5%] rounded border-2 border-white sm:w-[300px] w-[250px]  md:h-[45px] h-[35px] text-gray-700 ">
                <input
                  type="email"
                  name=""
                  id=""
                  placeholder="Enter Your Email Address"
                  className=" sm:w-[300px] w-[250px] placeholder:px-4 sm:placeholder:text-sm placeholder:text-xs "
                />
                <button className=" min-w-[67px] flex justify-center items-center bg-[#004B8C]">
                  <img src={Images.send} alt="" />
                </button>
              </div>
            </div>
            <div>
              <div className=" flex flex-row items-center gap-5 md:mt-0 mt-[10%]">
                <h1 className=" md:text-lg text-sm  font-semibold">
                  FOLLOW US :
                </h1>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/916289179476"
                  >
                    <img
                      src={Images.whatsapp}
                      className="md:w-[100%] w-[80%]"
                      alt="whatsapp"
                    />
                  </a>
                </div>
                <div>
                <a  target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/sicu-aura-innoviz/posts/?feedView=all'>
                  <img
                    src={Images.linkedin}
                    className="md:w-[100%] w-[80%]"
                    alt="linkedin"
                  />                
                        </a>
                </div>
              </div>
              <div className=" flex flex-row items-center gap-5  mt-[10%]">
                <h1 className=" md:text-lg text-sm  font-semibold">
                  RATE US &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                </h1>
                <div
                  className=" cursor-pointer"
                  onClick={() =>
                    window.open(
                      "https://www.google.com/search?q=sicu-aura+innoviz.com&rlz=1C1ONGR_enIN1107IN1107&oq=&gs_lcrp=EgZjaHJvbWUqCQgAECMYJxjqAjIJCAAQIxgnGOoCMgkIARAjGCcY6gIyCQgCECMYJxjqAjIJCAMQIxgnGOoCMgkIBBAjGCcY6gIyCQgFECMYJxjqAjIJCAYQIxgnGOoCMgkIBxAjGCcY6gLSAQs3Njk5NDE5ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x39f89b57b0f40f97:0xeab93b14e51e5407,3"
                    )
                  }
                >
                  <img
                    src={Images.google}
                    className="md:w-[90%] w-[70%]"
                    alt="google"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" text-left sm:mt-[2%] mt-[10%] font-medium sm:text-[10px] text-[9px]">
            *Terms and Condition Applied* | Copyright © 2024 Sicu-aura INNOVIZ.
            All Rights Reserved.
          </div>
        </div>
        <div className="  hidden w-[20%] lg:block relative">
          <img
            src={Images.phone}
            className="w-[100%] absolute bottom-0 right-0"
            alt=""
          />
        </div>
      </div>
      {termModal ? <TermModal toggleTermModal={toggleTermModal} /> : null}
      {privacyModal ? (
        <PrivacyModal togglePrivacyModal={togglePrivacyModal} />
      ) : null}
      {faqModal ? <FAQModal toggleFaqModal={toggleFaqModal} /> : null}
    </div>
  );
};

export default Footer;
