import React from "react";
import { FaTimes } from "react-icons/fa";

const TermModal = ({ toggleTermModal }) => {
  return (
    <div className="fixed top-0 left-0 w-full text-start h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-200 p-4 rounded-lg shadow-lg md:w-[90vw] w-full max-h-[95vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6 border-b border-gray-500 pb-4">
          <h2 className="text-2xl font-semibold">Terms and Conditions</h2>
          <p
            className="cursor-pointer text-xl p-2 rounded-md bg-red-500 text-white hover:bg-red-600"
            onClick={toggleTermModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="text-sm text-gray-700 space-y-5">
          <p>
            <span className="font-semibold">
              Sicu-Aura Innoviz Terms and Conditions
            </span>
            <br />
            These Terms and Conditions govern the use of the services provided
            by Sicu-Aura Innoviz including the development of custom websites,
            software applications, design services, and digital marketing
            services. By accessing or using the Services, you or Clients agree
            to be bound by these Terms.
          </p>
          <p>
            <span className="font-semibold">1. Services</span>
            <br />
            Sicu-Aura Innoviz provides a variety of services to help clients
            achieve their digital goals. These services may include, but are not
            limited to:
            <ul className="list-none pl-6">
              <li>Customized website</li>
              <li>Custom software</li>
              <li>Mobile application</li>
              <li>Website SEO</li>
              <li>Business profile SEO</li>
              <li>Logo design</li>
              <li>Poster design</li>
              <li>Motion graphics</li>
              <li>Animations</li>
              <li>3D design</li>
              <li>Digital Marketing</li>
              <li>Digital Consultancy for speedy growth</li>
            </ul>
            The specific services provided will be outlined in a separate
            agreement between Sicu-Aura Innoviz and the Client.
          </p>
          <p>
            <span className="font-semibold">2. Project Agreement</span>
            <br />
            The Project Agreement will detail the specific scope of work,
            timelines, deliverables, fees, and payment terms for each project.
            It is important to review the Project Agreement carefully before
            signing.
          </p>
          <p>
            <span className="font-semibold">3. Intellectual Property</span>
            <br />
            Sicu-Aura Innoviz retains ownership of all intellectual property
            rights associated with the Services, including but not limited to
            source code, design elements, and documentation. The Client will own
            the intellectual property rights to the final product developed by
            Sicu-Aura Innoviz, subject to the terms of the Project Agreement.
          </p>
          <p>
            <span className="font-semibold">4. Warranties and Disclaimers</span>
            <br />
            Sicu-Aura Innoviz warrants that the Services will be performed in a
            professional and workmanlike manner. However, Sicu-Aura Innoviz
            makes no other warranties, express or implied, including warranties
            of merchantability or fitness for a particular purpose. Sicu-Aura
            Innoviz will not be liable for any damages arising from the use of
            the Services.
          </p>
          <p>
            <span className="font-semibold">5. Limitation of Liability</span>
            <br />
            Sicu-Aura Innoviz's liability to the Client for any cause
            whatsoever, and regardless of the form of the action, will be
            limited to the amount paid by the Client to Sicu-Aura Innoviz under
            the Project Agreement. Sicu-Aura Innoviz will not be liable for any
            indirect, incidental, consequential, or punitive damages.
          </p>
          <p>
            <span className="font-semibold">6. Confidentiality</span>
            <br />
            Sicu-Aura Innoviz agrees to keep confidential all non-public
            information of the Client. The Client agrees to keep confidential
            all non-public information of Sicu-Aura Innoviz.
          </p>
          <p>
            <span className="font-semibold">7. Term and Termination</span>
            <br />
            These Terms will remain in effect until terminated by either party.
            These Terms may be terminated by either party upon written notice to
            the other party. The Project Agreement will govern the termination
            of any specific project.
          </p>
          <p>
            <span className="font-semibold">8. Governing Law</span>
            <br />
            These Terms will be governed by and construed in accordance with the
            laws of India.
          </p>
          <p>
            <span className="font-semibold">9. Entire Agreement</span>
            <br />
            These Terms constitute the entire agreement between the parties with
            respect to the subject matter hereof and supersede all prior or
            contemporaneous communications, representations, or agreements,
            whether oral or written.
          </p>
          <p>
            <span className="font-semibold">10. Amendments</span>
            <br />
            Sicu-Aura Innoviz may amend these Terms at any time by posting the
            amended terms on its website. The amended terms will be effective
            immediately upon posting.
          </p>
          <p>
            <span className="font-semibold">11. Severability</span>
            <br />
            If any provision of these Terms is held to be invalid or
            unenforceable, such provision shall be struck and the remaining
            provisions shall remain in full force and effect.
          </p>
          <p>
            <span className="font-semibold">12. Waiver</span>
            <br />
            No waiver by either party of any breach of these Terms will be
            deemed a waiver of any subsequent breach.
          </p>
          <p>
            <span className="font-semibold">13. Notices</span>
            <br />
            All notices and other communications hereunder shall be in writing
            and shall be deemed to have been duly given when delivered
            personally, sent by certified or registered mail, postage prepaid,
            return receipt requested, or sent by overnight courier, addressed as
            follows:
            <br />
            If to Sicu-Aura Innoviz:
            <br />
            [Your Company Address]
            <br />
            If to Client:
            <br />
            [Client Address]
          </p>
          <p>
            <span className="font-semibold">14. Force Majeure</span>
            <br />
            Neither party will be liable for any delay or failure to perform its
            obligations under these Terms if such delay or failure is due to
            causes beyond such party's reasonable control, including, but not
            limited to, acts of God, acts of war, terrorism, strikes, or natural
            disasters.
          </p>
          <p>
            <span className="font-semibold">15. Contact Us</span>
            <br />
            If you have any questions about these Terms, please contact us at{" "}
            <a href="mailto:innoviz@sicu-aura.com" className="text-blue-500">
              innoviz@sicu-aura.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermModal;
