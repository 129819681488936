import React, { useEffect, useState } from 'react'
import logo from '../assets/logo.svg'
import { Link, useLocation } from 'react-router-dom'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    useBreakpointValue,
    Stack,
} from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import Images from '../assets';

const Navbar = () => {
    const { pathname } = useLocation();
    const [scrollOffset, setOffset] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isDrawer = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        const handleScroll = () => {
          const offset = window.scrollY;
          const isMobile = window.innerWidth <= 768;
          const threshold = isMobile ? 100 : 500;
          setOffset(offset > threshold);
        };
    
        if (pathname !== '/checkout' && pathname !== '/user') {
          window.addEventListener('scroll', handleScroll);
    
          // Check immediately on mount for the initial scroll position
          handleScroll();
        } else {
          // If it's the checkout page, set the offset to true
          setOffset(true);
        }
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [pathname]);

    return (

        <div>
            {
                isDrawer ? <div
                    className={!scrollOffset ? 'bg-transparent flex justify-between px-[10%] py-[1%] font-Raleway z-50 top-0 fixed w-full transition ease-in-out duration-700' : 'bg-[#0D6F7A] flex justify-between px-[10%] py-[1%] font-Raleway z-50 top-0 fixed w-full transition ease-in-out duration-700'}
                >
                    <img src={logo} alt='innoviz'></img>
                    <button className="" onClick={onOpen}>
                        <GiHamburgerMenu className="humb z-50" style={{ color: "white" }} size={25} />
                        <Drawer
                            colorScheme="blackAlpha"
                            placement="right"
                            isOpen={isOpen}
                            onClose={onClose}
                        >
                            <DrawerOverlay>
                                <DrawerContent style={{ backgroundColor: "#0D6F7A" }}>
                                    <DrawerCloseButton style={{ color: "white" }} />
                                    <DrawerHeader>
                                        <img className='w-[30%]' src={logo} alt="innoviz" />
                                    </DrawerHeader>
                                    <DrawerBody >
                                        <Stack >
                                        <ul className='flex flex-col items-center justify-around  cursor-pointer h-[80vh] text-white sm:text-[2.5vw] text-[4vw] gap-6'>
                        <li  className={ pathname==='/'?' relative overflow-hidden text-[#00d6c0]   transition-all':' relative overflow-hidden navli hover:text-[#00d6c0] transition-all'}>
                                <Link to={'/'}>Home</Link>
                                <div className={pathname==='/'? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                             </li>
                            <li className={ pathname.includes('services')?' relative overflow-hidden text-[#00d6c0]   transition-all':' relative overflow-hidden navli hover:text-[#00d6c0] transition-all'}>
                            <Link to={'/services'} onClick={onClose} >Services</Link>
                                <div className={pathname.includes('services')? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                             </li>
                            <li className={ pathname.includes('work')?' relative overflow-hidden text-[#00d6c0]   transition-all':' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                            <Link to={'/work'} onClick={onClose} >Portfolio</Link>
                                <div className={pathname.includes('work')? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                             </li>
                             <li className={pathname.includes('pricing') ? ' relative overflow-hidden text-[#00d6c0]   transition-all' : ' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                                <Link to={'/pricing'}>Pricing</Link>
                                <div className={pathname.includes('pricing') ? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                            </li>
                            <li className={ pathname.includes('blog')?' relative overflow-hidden text-[#00d6c0]   transition-all':' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                            <Link to={'/blog'}>Blogs</Link>
                                <div className={pathname.includes('blog')? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                             </li>
                            <li className={ pathname.includes('career')?' relative overflow-hidden text-[#00d6c0]   transition-all':' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                                <Link to={'/career'}>Career</Link>
                                <div className={pathname.includes('career')? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                             </li>
                             <li className={ pathname.includes('about')?' relative overflow-hidden text-[#00d6c0]   transition-all':' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                             <Link to={'/about'}>About us</Link>
                                <div className={pathname.includes('about')? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                             </li>
                             
                            <li className={ pathname.includes('contacts')?' relative overflow-hidden text-[#00d6c0]   transition-all':' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                            <Link to={'/contacts'}>Contacts</Link>
                                <div className={pathname.includes('contacts')? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                             </li>
                        </ul>

                                        </Stack>
                                    </DrawerBody>
                                </DrawerContent>
                            </DrawerOverlay>
                        </Drawer>
                    </button>
                </div>
                    :
                    <div
                        className={!scrollOffset ? ' flex justify-between pl-[10%] py-[1%] font-Raleway z-50 top-0 fixed w-full transition ease-in-out duration-700 ' : 'bg-[#0D6F7A] flex justify-between pl-[10%] py-[1%] font-Raleway z-50 top-0 fixed w-full transition ease-in-out duration-700'}
                    >
                        <img src={logo} className='w-[11vw]' alt='innoviz'></img>
                        <ul className='flex items-center justify-between cursor-pointer  text-white text-[1.5vw]  lg:gap-9 gap-6'>
                            <li className={pathname === '/' ? ' relative overflow-hidden text-[#00d6c0]   transition-all' : ' relative overflow-hidden navli hover:text-[#00d6c0] transition-all'}>
                                <Link to={'/'}>Home</Link>
                                <div className={pathname === '/' ? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                            </li>
                            <li className={pathname.includes('services') ? ' relative overflow-hidden text-[#00d6c0]   transition-all' : ' relative overflow-hidden navli hover:text-[#00d6c0] transition-all'}>
                                <Link to={'/services'}>Services</Link>
                                <div className={pathname.includes('services') ? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                            </li>
                            <li className={pathname.includes('work') ? ' relative overflow-hidden text-[#00d6c0]   transition-all' : ' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                                <Link to={'/work'}>Portfolio</Link>
                                <div className={pathname.includes('work') ? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                            </li>
                            <li className={pathname.includes('pricing') ? ' relative overflow-hidden text-[#00d6c0]   transition-all' : ' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                                <Link to={'/pricing'}>Pricing</Link>
                                <div className={pathname.includes('pricing') ? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                            </li>
                            <li className={pathname.includes('blog') ? ' relative overflow-hidden text-[#00d6c0]   transition-all' : ' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                                <Link to={'/blog'}>Blogs</Link>
                                <div className={pathname.includes('blog') ? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                            </li>
                            <li className={pathname.includes('career') ? ' relative overflow-hidden text-[#00d6c0]   transition-all' : ' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                                <Link to={'/career'}>Career</Link>
                                <div className={pathname.includes('career') ? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                            </li>
                            <li className={pathname.includes('about') ? ' relative overflow-hidden text-[#00d6c0]   transition-all' : ' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                                <Link to={'/about'}>About us</Link>
                                <div className={pathname.includes('about') ? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                            </li>
                            <li className={pathname.includes('contacts') ? ' relative overflow-hidden text-[#00d6c0]   transition-all' : ' relative overflow-hidden navli hover:text-[#00d6c0]   transition-all'}>
                                <Link to={'/contacts'}>Contacts</Link>
                                <div className={pathname.includes('contacts') ? 'relative bottom-0 bg-[#00d6c0] navspan1 h-1 rounded-xl' : ' relative bottom-0 bg-[#00d6c0] navspan h-1 rounded-xl'}></div>
                            </li>
                            <div className='flex items-center  w-[100px]'>
                                <Link to={'/checkout'}><img alt='cart' className='w-[50%] ' src={Images.navCart}></img></Link>
                                <Link to={'/user'}><img alt='cart'  className='w-[45%] ' src={Images.navUser}></img></Link>
                            </div>
                        </ul>
                    </div>
            }

        </div>

    )
}

export default Navbar