import React, { useEffect, useRef } from "react";
import servicesImg from "../assets/services/index";
import { motion } from "framer-motion";
import ReactGA from "react-ga4";
import servicesAnimation from '../assets/animations/service section.json'
import servicesCards from '../assets/animations/full stack animation.json'
import servicesScale from '../assets/animations/scale up.json'
import servicesCircle from '../assets/animations/circle animation.json'
import servicesSize from '../assets/animations/screen size change animation.json'
import { Link, useLocation } from "react-router-dom";
import LottieAnimation from "../components/lottieAnimation";
const Services = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Services",
    });
  }, []);
  const card = useRef();

  return (

    <motion.div
      initial={{ opacity: 0, top: 0 }}
      animate={{ opacity: 1, top: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      className="sm:text-base text-[7px] bg-[#94ACFF] z-[100] "
    >
      <LottieAnimation animationData={servicesAnimation} />
      <div className="services-gradient_1 sm:py-[2%] py-[5%] ">
        <div className="text-white flex items-center justify-center">
          <img className="sm:w-[6%]  w-[10%]" src={servicesImg.str1} alt="string"></img>
          <div className="sm:text-3xl text-sm  leading-[130%] font-Inter service">
            we align our services to <br /> achieve the goals, which our <br /> Customers aims for</div>
          <img className="sm:w-[6%]  w-[10%]" src={servicesImg.str2} alt="string"></img>

        </div>
      </div>

      {/*----------------------------------------------------------------------- Technology------------------------------------------------------------------- */}
      <div className="bg-gradient-to-r tech from-[#799FEC] to-[#2952A3]">
        <div className="mx-subsection text-white text-start sm:py-[5%] py-[10%] ">
          <div className="sm:text-[4.5vw] text-[8vw] sm:my-[2%] font-Inter ">Technology</div>
          <div className="font-Raleway sm:text-[1.5vw] text-[3vw]">
            We make codes work, to grow your business.
          </div>
        </div>
      </div>
      <div ref={card} className="bg-[#CCDDFF] sm:py-[5%] py-[10%]">
        <div className="mx-subsection flex justify-between">
          <div className=" text-white  font-Raleway relative md:w-[30%] w-[40%] sm:text-lg text-[10px] ">

            <LottieAnimation animationData={servicesCards} />
          </div>

            <div className="bg-[#F2F2F280] board text-start md:w-[40%] w-[50%] flex flex-col justify-between    px-[3%] pt-[5%] pb-[2%]   sm:text-[1.5vw] text-[3vw] sm:rounded-3xl rounded-lg ">
              <div className="flex flex-col gap-[10%]  h-full">
              <div className="md:text-3xl text-md font-bold ">We make our codes to align in the products our users care about.</div>
              <div className="sm:text-[1.3vw] text-[2vw] font-semibold  font-Raleway">We do this with our Full stack technology.We build clean, responsive and efficient products for our users.</div>
              </div>
              <div className="sm:text-[1vw] text-[1.8vw] font-bold  font-Raleway flex justify-between">
                <span>
                Clean
                </span>
                <span>
                Efficient
                </span>
                <span>
                Effective
                </span>
                <span>
                Responsive
                </span>
              </div>
            </div>
          
        </div>
      </div>
      <div className="flex  mx-subsection my-[2%] justify-center space-x-5">
        <div className="font-Raleway  sm:text-4xl text-xs font-semibold py-[2%]">
          Technologies <br /> we work with
        </div>
        <span className="sm:p-[1px] p-[0.1px] sm:mx-0 mx-[5%] rounded-full bg-black"></span>

        <div className="flex items-center justify-between w-[80%]">
          {servicesImg.logo1.map((logo, index) => {
            console.log(logo)
            return (
              <div className="p-[2%] sm:w-[15%] w-[50%] object-cover">
                <img src={logo.default || logo} key={index} alt="logo"></img>
              </div>
            );
          })}
        </div>
      </div>

      {/* -------------------------------------------------------------------------------Design ------------------------------------------------------------------------------------------*/}

      <div className="bg-gradient-to-r design from-[#79ECEC] to-[#29A3A3]">
        <div className="mx-subsection text-white text-start sm:py-[5%] py-[10%] ">
          <div className="sm:text-[4.5vw] text-[8vw] sm:my-[2%] font-Inter">Design</div>
          <div className="font-Raleway sm:text-[1.5vw] text-[3vw]">
            we handcraft the user experience for your business.
          </div>
        </div>
      </div>
      <div className="bg-[#CEFCFC] relative py-[5%]">

        <div className="flex mx-subsection h-[100%] justify-between ">
  <div className="bg-[#F2F2F280] board text-start md:w-[40%] w-[50%] flex flex-col justify-between    px-[3%] pt-[5%] pb-[2%]   sm:text-[1.5vw] text-[3vw] sm:rounded-3xl rounded-lg ">
              <div className="flex flex-col gap-[10%]  h-full">
              <div className="md:text-3xl text-md font-bold ">We care about the experiences our users care about in their products </div>
              <div className="sm:text-[1.3vw] text-[2vw] font-semibold  font-Raleway">We iterate to build the intuitive for our users.we offer complete design solutions for our clients.</div>
              </div>
              <div className="sm:text-[1vw] text-[1.8vw] font-bold  font-Raleway flex justify-between">
                <span>
                Responsive
                </span>
                <span>
                Functional
                </span>
                <span>
                Aesthetic
                </span>
              </div>
            </div>
          <div className=" relative md:w-[35%] w-[40%]">

            <LottieAnimation animationData={servicesSize} />

          </div>
        </div>
      </div>
      <div className="flex mx-subsection my-[2%]  justify-center space-x-5 ">
        <div className="font-Raleway   sm:text-4xl text-xs font-semibold py-[2%]">
          Technologies <br /> we work with
        </div>
        <span className="sm:p-[1px] p-[0.1px] sm:mx-0 mx-[5%] rounded-full bg-black"></span>

        <div className="flex items-center justify-between w-[80%]">
          {servicesImg.logo2.map((logo, index) => {
            return (
              <div className="p-[2%] w-[15%]">
                <img src={logo} key={index} alt="logo"></img>
              </div>
            );
          })}
        </div>
      </div>

      {/* -------------------------------------------------------------------------------Digital Marketing ------------------------------------------------------------------------------------------*/}

      <div className="bg-gradient-to-r from-[#799FEC] to-[#2952A3]">
        <div className="mx-subsection text-white text-start sm:py-[5%] py-[10%] ">
          <div className="sm:text-[4.5vw] text-[8vw] sm:my-[2%] font-Inter">
            Digital Marketing
          </div>
          <div className="font-Raleway sm:text-[1.5vw] text-[3vw]">
            We help you market your business to the last mile.
          </div>
        </div>
      </div>

      <div className="bg-[#CCDDFF] flex justify-around px-[10%] py-[5%]">
        <div className="md:w-[25%] w-[30%] ">
          <LottieAnimation animationData={servicesCircle} />
        </div>
        <div className="bg-[#F2F2F280] board text-start md:w-[40%] w-[50%] flex flex-col justify-between    px-[3%] pt-[5%] pb-[2%]   sm:text-[1.5vw] text-[3vw] sm:rounded-3xl rounded-lg ">
              <div className="flex flex-col gap-[10%]  h-full">
              <div className="md:text-3xl text-md font-bold ">We help our clients to reach the audience by leveraging different digital channels </div>
              <div className="sm:text-[1.3vw] text-[2vw] font-semibold  font-Raleway">We  offer different strategies,tools to reach the targeted demographics and potential users.
</div>
              </div>
              <div className="sm:text-[1vw] text-[1.8vw] font-bold  font-Raleway flex justify-between">
                <span>
                Reach
                </span>
                <span>
                Growth
                </span>
                <span>
                Marketing
                </span>
              </div>
            </div>
      </div>
      <div className="flex mx-subsection my-[2%] justify-center space-x-5 ">
        <div className="font-Raleway  sm:text-4xl text-xs font-semibold py-[2%]">
          Technologies <br /> we work with
        </div>
        <span className="sm:p-[1px] p-[0.1px] sm:mx-0 mx-[5%] rounded-full bg-black"></span>

        <div className="flex items-center justify-between w-[80%]">
          {servicesImg.logo3.map((logo, index) => {
            return (
              <div className="p-[2%]">
                <img src={logo} key={index} alt="logo"></img>
              </div>
            );
          })}
        </div>
      </div>

      {/* -------------------------------------------------------------------------------Scale your Business------------------------------------------------------------------------------------------*/}

      <div className="bg-[#CEFCFC]  py-[10%]">
        <div className="mx-subsection text-start flex justify-between items-center relative">
          <div>
            <div className="sm:text-[4vw] text-[6vw] font-Inter">
              <div>
                Scale your Business
              </div>
              <div className="py-[5%]">
                with us

              </div>
            </div>
            <div className="font-Raleway sm:text-[1.5vw] text-[3vw] py-[2%]">
              Any stage your business <br />
              may be in,we will scale
              <br /> it for you.
            </div>
            <Link to={'/contacts'} className="bg-gradient-to-r from-[#00C5B5] to-[#005F57] sm:text-[1.5vw] text-[3vw] flex items-center justify-center w-[40%] py-[4%] sm:rounded-xl rounded-md text-center text-white sm:mt-[15%] mt-[5%]">
              Get in touch
              <img
                className="w-[15%] mt-[1%] ml-1"
                src={servicesImg.arrow}
                alt="arrow"
              ></img>
            </Link>
          </div>
          <div className="sm:w-[33%] w-[40%] absolute right-0 bottom-0">
            <LottieAnimation animationData={servicesScale} />
          </div>
        </div>
      </div>
    </motion.div>

  );
};

export default Services;
