import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Images from '../assets';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation } from 'react-router-dom';
import servicesImg from '../assets/services/index';
import axios from "axios";
import { api } from '../components/mailerApi';
import ReactGA from "react-ga4";
import contactAnimation from '../assets/animations/Contact page animation.json'
import LottieAnimation from '../components/lottieAnimation';
import toast, { Toaster } from 'react-hot-toast';
import MetaPixelEvent from '../utils/MetaPixalEvent';

const Contacts = () => {
    const { pathname } = useLocation();
    const location = useLocation();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search,
            title: "Contacts",
        });
    }, []);
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        number: '',
        selectedDate: startDate,
        file: null,
        text: '',
        selectedRequirement: '',

    });
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === "file") {
            setFormData({
                ...formData,
                [name]: e.target.files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });

        }
    };
    const handleRequirementSelect = (requirement) => {
        setFormData({
            ...formData,
            selectedRequirement: requirement,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
          header: "Sicu-Aura Innoviz",
          subject: "we have received new contact request from sicu-aura Innoviz website",
          html: `
            <p>I hope this email finds you well. I am writing to provide you with the details of a user who has submitted a request on our website:</p>
            <p>Name: ${formData.name}</p>
            <p>Company: ${formData.company}</p>
            <p>Email: ${formData.email}</p>
            <p>Contact Number: ${formData.number}</p>
            <p>Meeting Date: ${formData.selectedDate}</p>
            <p>Request: ${formData.selectedRequirement}</p>
            <p>Message: ${formData.text}</p>`,
                  // to: "support@sicu-aura.com,innoviz@sicu-aura.com,founder@sicu-aura.com ",
          to: "logeshthirumurugan@gmail.com,loganop007@gmail.com"
        };
      
        try {
          const response = await axios.post(api, data);
          setFormData({
            name: '',
            company: '',
            email: '',
            number: '',
            selectedDate: new Date(),
            file: null,
            text: '',
            selectedRequirement: '',
          });
          toast.success('Mail Sent.');
        } catch (error) {
          console.error("Error sending email:", error);
          toast.error('Error sending email.');
        }
      };
      
      
    return (
        <motion.div
            className='flex flex-col items-center overflow-hidden'
            initial={{ opacity: 0, top: 0 }}
            animate={{ opacity: 1, top: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
        >
             <MetaPixelEvent eventName="ContactPageView" />
            <div className='w-[110%]'>
                <LottieAnimation animationData={contactAnimation} />
            </div>

            <div className=' flex flex-col w-full quotebg justify-center items-center '>
                <div className="text-white flex items-center justify-center">
                    <img className="sm:w-[6%] w-[10%]" src={servicesImg.str1} alt="string"></img>
                    <p className='text-white font-Raleway sm:text-3xl text-sm font-semibold'>We would love to hear from you !</p>
                    <img className="sm:w-[6%]  w-[10%]" src={servicesImg.str2} alt="string"></img>

                </div>
                <div className=' bg-[#DAF9F9] w-full flex flex-col justify-center items-center '>
                    <div className=' flex flex-row justify-between sm:w-[60%] w-[80%] my-[5%] '>
                        <div className=' flex flex-row justify-between'>
                            <div className=' font-Raleway font-normal  flex'><h1 className='font-Raleway font-normal  sm:text-7xl text-5xl text-gray-400 flex items-center'>#</h1></div>
                            <div className='font-Raleway sm:text-xl text-xs  flex font-semibold'>
                                <p className='flex items-center font-semibold text-left'>About your  <br /> Business</p>
                            </div>

                        </div>
                        <div className=' flex flex-row justify-between'>
                            <div className=' font-Raleway font-normal  flex'><h1 className='font-Raleway font-normal  sm:text-7xl text-5xl text-gray-400 flex items-center'>#</h1></div>
                            <div className='font-Raleway sm:text-xl text-xs  flex font-semibold'><p className='flex font-semibold items-center text-left'>About your <br /> products</p>
                            </div>
                        </div>
                        <div className=' flex flex-row justify-between'>
                            <div className=' font-Raleway font-normal  flex'><h1 className='font-Raleway font-normal  sm:text-7xl text-5xl text-gray-400 flex items-center'>#</h1></div>
                            <div className='font-Raleway sm:text-xl text-xs  flex font-semibold'><p className='flex items-center font-semibold text-left'>Just want <br /> to know us</p>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className=' bg-[#C7ECE8] p-[2%] rounded-lg border-black border-[1px] mb-[5%] sm:w-[50%] w-[80%]  text-black font-normal sm:text-sm text-xs ' >
                        <div className=' grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-0'>
                            <div className=' flex flex-col justify-start text-left gap-1 '>
                                <h1>Name</h1>
                                <input type="text" name="name" onChange={handleChange} className=' h-[35px] rounded-[5px] ' />
                            </div>
                            <div className=' flex flex-col justify-start text-left gap-1 '>
                                <h1> Company</h1>
                                <input type="text" name="company" onChange={handleChange} className='h-[35px] rounded-[5px]  ' />
                            </div>
                            <div className=' flex flex-col justify-start text-left gap-1 '>
                                <h1>E-mail</h1>
                                <input type="text" name="email" onChange={handleChange} className='h-[35px] rounded-[5px] ' />
                            </div>
                            <div className=' flex flex-col justify-start text-left gap-1'>
                                <h1>Phone</h1>
                                <input type="text" name="number" onChange={handleChange} className='h-[35px] rounded-[5px]  ' />
                            </div>
                        </div>


                        <div className=' flex flex-col justify-center items-start text-left mt-[2%]'>
                            <div className=' text-left flex'>Your requirements</div>
                            <div className='grid sm:grid-cols-4 grid-cols-1  gap-1 mt-[2%]  w-[100%] bg-white p-[2%] rounded-[10px]'>
                                <div onClick={() => handleRequirementSelect('Build')} className='bg-white sm:rounded-[30px] rounded-[10px] p-[1%] sm:w-[8vw]  sm:border border-[1px] border-black flex items-center justify-center cursor-pointer  ' style={{ backgroundColor: formData.selectedRequirement === 'Build' ? 'black' : 'white', color: formData.selectedRequirement === 'Build' ? 'white' : 'black' }}>Build</div>
                                <div onClick={() => handleRequirementSelect('Audit')} className=' bg-white sm:rounded-[30px] rounded-[10px] p-[1%] sm:w-[8vw]   sm:border border-[1px] border-black flex items-center justify-center cursor-pointer  ' style={{ backgroundColor: formData.selectedRequirement === 'Audit' ? 'black' : 'white', color: formData.selectedRequirement === 'Audit' ? 'white' : 'black' }}>Audit</div>
                                <div onClick={() => handleRequirementSelect('Talk')} className='  bg-white sm:rounded-[30px] rounded-[10px] p-[1%] sm:w-[8vw]  sm:border border-[1px] border-black flex items-center justify-center cursor-pointer   ' style={{ backgroundColor: formData.selectedRequirement === 'Talk' ? 'black' : 'white', color: formData.selectedRequirement === 'Talk' ? 'white' : 'black' }}>Talk</div>
                                <div onClick={() => handleRequirementSelect('Explore')} className='bg-white sm:rounded-[10px] row-span-2 rounded-[10px] sm:p-[10%] p-[3%] sm:w-[11vw]   sm:border border-[1px] border-black flex items-center justify-center cursor-pointer  ' style={{ backgroundColor: formData.selectedRequirement === 'Explore' ? 'black' : 'white', color: formData.selectedRequirement === 'Explore' ? 'white' : 'black' }}>Explore on  how we can be a help !!</div>
                                <div onClick={() => handleRequirementSelect('project')} className='bg-white sm:rounded-[30px]  rounded-[10px] p-[1%] sm:w-[30.3vw]   sm:border border-[1px] border-black flex items-center justify-center cursor-pointer  ' style={{ backgroundColor: formData.selectedRequirement === 'project' ? 'black' : 'white', color: formData.selectedRequirement === 'project' ? 'white' : 'black' }}>Want to Start a Project with your team</div>
                            </div>
                        </div>

                        <div className=' mt-[2%] flex sm:flex-row flex-col justify-between'>
                            <div className='flex flex-col text-left sm:w-[45%]'>
                                <div>Schedule a meet:</div>
                                <div className=' mt-[2%] bg-white flex justify-between items-center px-[2%] h-[35px] rounded-[5px]'>
                                    <DatePicker
                                        // showIcon
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        className='w-[50%]' />
                                    <div className=' bg-[#606060] px-[2%] h-[25px] rounded-[2px] flex items-center justify-center '>Calender </div>
                                </div>

                            </div>

                            <div className='flex flex-col sm:w-[45%] sm:mt-0 mt-6'>
                                <div className='text-left' >Attach a brief if any:</div>
                                <div className='bg-white flex mt-[2%]  p-[4.4px] h-[35px] rounded-[5px]'>

                                    <input type='file' name='file' onChange={handleChange} className='text-sm h-[35px]' />

                                </div>
                            </div>
                        </div>

                        <div className=" flex flex-col w-full sm:mt-[2%] mt-[15%] text-left ">
                            <div className="">Write your message</div>
                            <textarea type="text" name='text' onChange={handleChange} className=" mt-[2%] h-[150px] outline-none p-1 px-2  rounded-[10px]" />
                        </div>
                        <div className=' flex flex-col justify-start items-start mt-6'><button type='submit' className=' px-12 rounded-[5px] text-white py-2 bg-gradient-to-t from-[#00C5B5] to-[#005F57]'>Send</button> </div>
                    </form>
                </div>

                <div className=' flex items-start flex-col w-[80%] relative py-[5%] '>
                    <p className='text-black font-Raleway  sm:text-4xl text-2xl font-semibold text-left'>Want to work with us?</p>
                    <p className='text-black font-Raleway sm:text-xl text-sm font-semibold underline-offset-1 underline mt-[2%]'><Link to='/career'>Job opportunities  and Internships</Link></p>

                </div>
                <div className=' w-full flex flex-col items-center justify-center  bg-white  py-[6%]'>
                    <div className=' flex w-full flex-row justify-between  font-Inter sm:text-xl text-[9px]'>
                        {/* <div><a href='tel:6291984165'>+91-6291984165</a></div> */}
                        <div>
                            <a className='flex flex-col items-center' target='_blank' rel='noreferrer' href='tel:6289179476'>
                                <img src={Images.call} className='sm:w-[15%] w-[30%]' alt='phone'></img>
                                +91 6289179476

                            </a>
                        </div>
                        {/* <div><a href="mailto:innoviz@sicu-aura.com">innoviz@sicu-aura.com</a></div> */}
                        <div>
                            <a className='flex flex-col items-center' target='_blank' rel='noreferrer' href='mailto:innoviz@sicu-aura.com'>
                                <img src={Images.email} className='sm:w-[15%] w-[30%]' alt='phone'>
                                </img>
                                innoviz@sicu-aura.com
                            </a>
                        </div>
                        <div>
                            <a className='flex flex-col items-center' target='_blank' rel='noreferrer' href='https://wa.me/916289179476'>
                            <img src={Images.chat} className='sm:w-[15%] w-[30%]' alt='phone'></img>
                            chat
                        </a>
                        </div>
                    </div>
                </div>
                <div className=' w-full flex h-[40vh]'>

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29434.722552106272!2d88.34856621443623!3d22.752748305460635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89aefcc6fe3a7%3A0x6a6d434ac504dbc5!2sBarrackpore%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1711952053647!5m2!1sen!2sin" width="2000" height="600" title='map' style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>

        </motion.div>
    );
}

export default Contacts;
