import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import { FaRupeeSign } from "react-icons/fa";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import {
  removeFromCart,
  setCouponCode,
  setCustomerDetails,
} from "../../redux/cart/cartSlice";
import { auth } from "../firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

const CustomerDetails = ({ nextStep }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const { pathname } = useLocation();
  const [user, setUser] = useState(null);
  const customerDetails = useSelector((state) => state.cart.customerDetails);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      formData.name = currentUser?.displayName || "";
    });

    return () => unsubscribe();
  }, []);
 
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  const [formData, setFormData] = useState({
    name: "",
    designation: customerDetails?.designation || "",
    organization: customerDetails?.organization || "",
    address: customerDetails?.address || "",
    mobile: customerDetails?.mobile || "",
    email: customerDetails?.email || "",
  });

  const totalPrice = cart.reduce((acc, item) => {
    const total = parseFloat(item.price.replace(/,/g, ""));
    return acc + total;
  }, 0);

  useEffect(() => {
    dispatch(setCouponCode(false));
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRemove = (id) => {
    dispatch(removeFromCart({ id }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (cart.length === 0) {
      toast.error("Your cart is empty");
      return;
    }

    if (
      // !formData.name ||
      !formData.designation ||
      !formData.organization ||
      !formData.address ||
      !formData.mobile ||
      !formData.email
    ) {
      toast.error("Please provide all details");
      return;
    }

    dispatch(setCustomerDetails(formData));
    nextStep();
  };

  return (
    <div className="text-start w-full">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col-reverse lg:flex-row gap-5 max-w-7xl gap-y-12 mx-auto"
      >
        <div className="border lg:w-[65%] md:w-[80%] mx-auto w-full">
          <div className="bg-[#3399A5] p-[5%] text-xl text-white">
            CUSTOMER DETAILS
          </div>
          <div className="bg-[#F6FBFF] lg:px-14 md:px-6 sm:px-4 px-2 flex gap-x-14 my-14 md:mr-12 lg:mr-0">
            <div className="hidden space-y-4 w-full sm:flex text-[#676767] font-medium flex-col items-end">
              <p className="text-xl font-bold text-[#6F6F6F] scale-y-110">
                NAME
              </p>
              <p>Designation :</p>
              <p>Organization Name :</p>
              <p>Address :</p>
            </div>
            <div className="space-y-3 flex flex-col items-center sm:items-start w-full">
              <p className="text-xl font-bold text-black scale-y-110">
                {user?.displayName}
              </p>
              <input
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
                className="bg-[#D9D9D9] rounded-md px-4 py-[5px] text-sm text-black placeholder:text-xs placeholder:text-[#555] placeholder:font-medium font-medium"
                placeholder="Designation"
              />
              <input
                name="organization"
                value={formData.organization}
                onChange={handleInputChange}
                className="bg-[#D9D9D9] rounded-md px-4 py-[5px] text-sm text-black placeholder:text-xs placeholder:text-[#555] placeholder:font-medium font-medium"
                placeholder="Organization"
              />
              <input
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="bg-[#D9D9D9] rounded-md px-4 py-[5px] text-sm text-black placeholder:text-xs placeholder:text-[#555] placeholder:font-medium font-medium"
                placeholder="Address"
              />
            </div>
          </div>
          <div className="text-center font-semibold mb-10 w-[80%] mx-auto lg:w-full">
            <p>
              Enter your mobile number and email address on which invoice
              details will be shared.
            </p>
          </div>
          <div className="bg-[#F6FBFF] lg:px-14 md:px-6 sm:px-4 px-2 flex lg:flex-row flex-col gap-x-14 ">
            <div className="lg:w-full w-fit mx-auto flex gap-x-6 lg:justify-end justify-center">
              <input
                className="bg-[#D9D9D9] w-[50px] rounded-md px-2 placeholder:text-center py-[9px] text-base text-black placeholder:text-base placeholder:text-[#555] placeholder:font-medium font-medium"
                placeholder="+91"
                disabled
              />
              <input
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                className="bg-[#D9D9D9] lg:w-full w-[60%] sm:w-fit rounded-md px-4 py-[9px] text-base text-black placeholder:text-base placeholder:text-[#555] placeholder:font-medium font-medium"
                placeholder="Mobile No."
              />
            </div>
            <div className="lg:w-full w-fit mx-auto flex justify-center mt-8 lg:mt-0">
              <input
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="bg-[#D9D9D9] lg:w-full w-fit rounded-md px-4 py-[9px] text-base text-black placeholder:text-base placeholder:text-[#555] placeholder:font-medium font-medium"
                placeholder="Email"
              />
            </div>
          </div>
          <div className="flex lg:justify-end lg:mr-[15%] justify-center my-[5%]">
            <button
              type="submit"
              className="bg-[#FF6161] text-lg text-white border px-8 py-[10px] rounded-md"
            >
              Continue
            </button>
          </div>
        </div>
        <div className="border lg:w-[32%] md:w-[80%] h-fit pb-4 w-full mx-auto">
          <div className="bg-[#3399A5] text-white p-[5%]">Order Summary</div>
          {cart.length > 0 ? (
            <>
              <table className="min-w-full table-auto border-collapse">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-4 py-2 border">Item</th>
                    <th className="px-4 py-2 border">Price</th>
                    <th className="px-4 py-2 border">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((item, index) => (
                    <tr key={index} className="text-center hover:bg-gray-100">
                      <td className="px-4 py-2 border">{item.name}</td>
                      <td className="px-4 py-2 border">
                        <FaRupeeSign
                          className="inline-block text-zinc-700"
                          size={15}
                        />{" "}
                        {item.price}.00
                      </td>
                      <td className="px-4 py-2 border">
                        <button
                          className="text-red-600 hover:text-red-800 transition-transform transform hover:scale-110"
                          type="button"
                          onClick={() => handleRemove(item.id)}
                        >
                          <AiFillDelete size={20} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex flex-col sm:flex-row justify-center items-center mt-6 mb-6 lg:mb-0">
                <p className="text-lg text-[#3399A5] font-bold mb-4 sm:mb-0 flex items-center">
                  Total: <FaRupeeSign className="inline-block ml-1" size={15} />{" "}
                  {totalPrice.toLocaleString()}.00
                </p>
              </div>
            </>
          ) : (
            <div className="text-center py-10">
              <p className="text-lg text-[#FF6161] font-bold">
                Your cart is empty.
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default CustomerDetails;
