const trialPackage = [
  {
    id: 0,
    name: "Audit Pack",
    des: "Introductory pack for beginners",
    price: "6000",
    things: ["Website auditing", "Social media Auditing", "Marketing Audit"],
    solution: [
      "Consultancy and Solutions",
      "Growth Consultancy",
      "Social Media Posts",
    ],
  },
];

const basicPackage = [
  {
    id: 1,
    name: "Single page",
    type: "Basic",
    des: "Essentials for your website",
    price: "19,999",
    solution: [
      "Fully Coded Website",
      "SSL",
      "Not customized",
      "Hosting",
      "Domain",
    ],
  },
  {
    id: 2,
    name: "Multiple page",
    type: "Basic",
    des: "Essentials for your website",
    price: "23,999",
    solution: [
      "Fully Coded Website",
      "SSL",
      "Not customized",
      "Hosting",
      "Domain",
    ],
  },
  {
    id: 3,
    name: "Single page",
    type: "Customized",
    des: "Essentials for your website",
    price: "28,499",
    solution: ["Fully Coded Website", "SSL", "customized", "Hosting", "Domain"],
  },
  {
    id: 4,
    name: "Multiple page",
    type: "Customized",
    des: "Essentials for your website",
    price: "32,499",
    solution: ["Fully Coded Website", "SSL", "customized", "Hosting", "Domain"],
  },
];
const animatedPackage = [
  {
    id: 5,
    name: "Single page",
    des: "For better experience while navigation",
    price: "40,999",
    solution: [
      "Fully Coded Website",
      "Custom design",
      "Animated lookup",
      "SSL",
      "Hosting",
      "Domain",
      "SEO",
      "Keywords",
    ],
  },
  {
    id: 6,
    name: "Multiple page",
    des: "For better experience while navigation",
    price: "48,999",
    solution: [
      "Fully Coded Website",
      "Custom design",
      "Animated lookup",
      "SSL",
      "Hosting",
      "Unlimited page",
      "Domain",
      "SEO",
      "Keywords",
    ],
  },
  {
    id: 7,
    name: "Lead generation",
    des: "For better experience while navigation",
    price: "56,999",
    solution: [
      "Fully Coded Website",
      "Custom design",
      "Animated lookup",
      "SSL",
      "Hosting",
      "Domain",
      "SEO",
      "Keywords",
      "Chat bot",
      "Login",
      "Lead generation",
    ],
  },
  {
    id: 8,
    name: "Branding",
    des: "For better experience while navigation",
    price: "62,999",
    solution: [
      "Fully Coded Website",
      "Custom design",
      "Animated lookup",
      "SSL",
      "Hosting",
      "Domain",
      "SEO",
      "Keywords",
      "Back Links",
      "Blogs",
      "Chat bot",
      "Login",
      "Animation",
    ],
  },
];
const premiumPackage = [
  {
    id: 9,
    name: "Semi-Ecommerce",
    des: "Features you need for the website",
    price: "68,999",
    solution: [
      "Full stack development",
      "Custom design",
      "Whatsapp Integration",
      "Animation lookup",
      "SSL",
      "Hosting",
      "Domain",
      "Keywords SEO",
      "VPS",
      "Back links",
      "Chat bot",
      "Login",
      "Admin panel",
    ],
  },
  {
    id: 10,
    name: "Luxury",
    des: "Features you need for the website",
    price: "78,999",
    solution: [
      "Full stack development",
      "Custom design",
      "API Integration",
      "Animation lookup",
      "SSL",
      "Storage",
      "Blogs",
      "Hosting",
      "Domain",
      "Keywords SEO",
      "VPS",
      "Back links",
      "Chat bot",
      "Login",
      "Admin panel",
    ],
  },
  {
    id: 11,
    name: "E-commerce",
    des: "For better experience while navigation",
    price: "1,99,999",
    solution: [
      "Full stack development",
      "Internal search",
      "Payment gateway",
      "Dashboard analytics ",
      "Logistics",
      "Wish lists",
      "Cart",
      "Store management",
      "Support",
      "FAQs",
      "Custom design",
      "API Integration",
      "Animated lookup",
      "SSL",
      "Storage",
      "Blogs",
      "Hosting",
      "Domain",
      "Keywords SEO",
      "VPS",
      "Back links",
      "Chat bot",
      "Login",
      "Admin panel",
    ],
  },
];

const marketingTrialPackage = [
  {
    id: 12,
    name: "Facebook Special",
    des: "Market your business over facebook",
    price: "9,999",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "1 Ad Design",
      "Marketing Content",
      "1 Ad campaign",
    ],
  },
];

const marketingBasicPackage = [
  {
    id: 13,
    name: "Awareness Special",
    des: "Spread awareness about your business",
    price: "15,999",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "2 Ad Design",
      "Marketing Content",
      "2 Ad sets",
    ],
  },
  {
    id: 14,
    name: "Instagram Special",
    des: "Marktet your business in Instagram",
    price: "20,999",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "1 Ad Carousel",
      "1 Ad Design",
      "2 Ad Sets",
    ],
  },
  {
    id: 15,
    name: "Meta Special",
    des: "Spread awareess about your business",
    price: "30,500",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "4 Ad design",
      "Marketing Content",
      "4 Ad Sets",
    ],
  },
  {
    id: 16,
    name: "Traffic Special",
    des: "Bring customer traffic into your business",
    price: "39,999",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "6 Ad design",
      "3-3 Meta Google Ad",
      "Content Development",
      "Marketing Content",
      "6 Ad sets",
      "Filtration targeting",
      "6 Social Media Post",
    ],
  },
];

const marketingPremiumPackage = [
  {
    id: 17,
    name: "Youtube Special",
    des: "Market your business on Youtube",
    price: "49,999",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "1 Ad Carousel",
      "Content Development",
      "Minimize Bidding Cost",
      "2 Ad Sets",
      "* Social Media Post",
    ],
  },
  {
    id: 18,
    name: "Branding Special",
    des: "Bring customer traffic into your business",
    price: "59,999",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "8 Ad Designs",
      "4-4 Meta-Google Ad",
      "Content Development",
      "Marketing Content",
      "8 Ad Sets",
      "8 Social Media Posts",
      "2 Reels",
    ],
  },
  {
    id: 19,
    name: "Lead Generation",
    des: "Campaign for lead generation for business",
    price: "65,999",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "10 Ad Designs",
      "5-5 Meta-Google Ad",
      "Content Development",
      "Marketing Content",
      "10 Ad Sets",
      "Filtration Targeting",
      "10 Social Media Posts",
      "4 Reels",
    ],
  },
  {
    id: 20,
    name: "Google Ads",
    des: "Campaign for lead generation for business",
    price: "73,999",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "14 Ad Designs",
      "5-5 Meta-Google Ad",
      "Content Development",
      "Marketing Content",
      "14 Ad Sets",
      "Minimize Bidding Costs",
      "Analytics Updates",
      "Performance",
      "Optimizations",
    ],
  },
];

const professionalMarketingPackage = [
  {
    id: 21,
    name: "Professional Campaign",
    des: "Campaign for leads generaion for buisness",
    price: "1,14,999",
    solution: [
      "3 Months",
      "Awareness Campaign",
      "Traffic Campaign",
      "Conversation Campaign",
      "18 Ad Design",
      "Content Optimization",
      "18 Ad Sets",
      "Bidding Optimization",
      "Analytics Report",
      "14 Special Media Post",
      "6 Reels",
      "Filtered Targeting",
      "Performance",
      "Optimization",
    ],
  },
];

const designPackage = [
  {
    id: 22,
    name: "Social Media Content",
    des: "Build Content for your buisness",
    price: "20,999",
    solution: [
      "Industry Research",
      "Audience Targeting",
      "1 Ad Design",
      "Marketing Design",
      "1 Ad Campaign",
    ],
  },
  {
    id: 23,
    name: "Graphical Reels",
    des: "Video ontent for your buisness",
    price: "35,999",
    solution: ["2 Reels/week", "30 Days Content Moderaion", "12 Posters"],
  },
  {
    id: 24,
    name: "Video Services",
    des: "Video Content for yout buisness",
    price: "Contant sales",
    solution: ["3D Content", "Animation", "Motion graphics", "Video editing"],
  },
];

export default {
  trialPackage,
  basicPackage,
  animatedPackage,
  premiumPackage,
  marketingTrialPackage,
  marketingBasicPackage,
  marketingPremiumPackage,
  professionalMarketingPackage,
  designPackage,
};
