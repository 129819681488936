const portfoliodata = [
    {
        id: 1,
        category: 'Website',
        pic: require('../taraang.png'),
        description: 'Showcase of our landscaping services, enhancing online visibility and attracting potential clients.',
    },
    {
        id: 2,
        category: 'Website',
        pic: require('../food.png'),
        description: 'An intuitive website for nearby restaurants, enabling easy booking and ordering of fast food.',
    },
    {
        id: 3,
        category: 'Website',
        pic: require('../misc.png'),
        description: 'Comprehensive destination booking platform for travelers, including accommodation and allied services.',
    },
    {
        id: 4,
        category: 'Branding',
        pic: require('../../Work/Events.png'),
        description: 'Branding for events to increase awareness and attendance through effective marketing strategies.',
    },
    {
        id: 5,
        category: 'Application',
        pic: require('../../Work/Food.png'),
        description: 'Innovative application for the food sector, streamlining orders and enhancing customer experience.',
    },
    {
        id: 6,
        category: 'Branding',
        pic: require('../../Work/Hospital.png'),
        description: 'Comprehensive branding for hospitals, enhancing their presence and trust within the community.',
    },
    {
        id: 7,
        category: 'Branding',
        pic: require('../../Work/Hotel Sicu-aura.png'),
        description: 'Effective branding for hotels to boost visibility and attract more guests through strategic marketing.',
    },
    {
        id: 8,
        category: 'Branding',
        pic: require('../../Work/Pet.png'),
        description: 'Branding services for pet-related businesses, increasing awareness and customer engagement.',
    },
    {
        id: 9,
        category: 'Application',
        pic: require('../../Work/Sicu-Aura Motor.png'),
        description: 'Application for the motor industry, providing seamless user experience and efficient service management.',
    },
    {
        id: 10,
        category: 'Application',
        pic: require('../../Work/Sicu-aura Innoviz Dental Clinic.png'),
        description: 'Dental clinic application by Sicu-Aura Innoviz, improving patient management and appointment scheduling.',
    },
    {
        id: 11,
        category: 'Digital marketing',
        pic: require('../../Work/Sicu-aura-Collage.png'),
        description: 'Digital marketing services for educational institutions, boosting online presence and student enrollment.',
    },
    {
        id: 12,
        category: 'Digital marketing',
        pic: require('../../Work/Sicu-aura-pets.png'),
        description: 'Digital marketing strategies for pet businesses, enhancing visibility and customer interaction online.',
    },
    {
        id: 13,
        category: 'Website',
        description: "Luxury hotel website showcasing rooms, amenities, and booking options.",
        pic: require('../../Work/hotel.png'),

    },
    {
        description: "Interior design portfolio website featuring various projects and client testimonials.",
        pic: require('../../Work/interior.png'),
        id: 14,
        category: 'Website',
    },
    {    id: 15,
        description: "Innovative application for a restaurant to streamline orders and enhance customer experience.",
        pic: require('../../Work/restaurant.png'),
        category: 'Website',
    },
    {    
        id: 16,
        description: "Clinic website featuring appointment scheduling and patient management system.",
        pic: require('../../Work/clinic.png'),
        category: 'Website',
    },

];

export default portfoliodata;
