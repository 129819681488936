import React, { useEffect, useState } from "react";
import careerimg from "../assets/career/index";
import Carousel from "react-elastic-carousel";
import arrowl from '../assets/arrow-left.svg'
import arrowr from '../assets/arrow-right.svg'
import { motion } from 'framer-motion'
import { collection, getDocs } from "firebase/firestore";
import { db } from "../components/firebase";
import careerAnimation from '../assets/animations/career.json'
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import LottieAnimation from "../components/lottieAnimation";
const Career = () => {
    const [jobs, setJobs] = useState([]);
    // const [formData, setFormData] = useState({
    //     position: "",
    //     fullName: "",
    //     mobileNumber: "",
    //     experience: "",
    //     workDescription: "",
    //     sampleWork: "",
    //     resume: undefined
    // });
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search,
            title: "Career",
        });
        fetchJobs();
    }, []);
    const fetchJobs = async () => {
        const querySnapshot = await getDocs(collection(db, "JobList"));
        const jobs = [];
        querySnapshot.forEach((doc) => {
            if (doc.exists()) {
                const jobData = doc.data();
                jobs.push(jobData);
            } else {
                console.log("No such document!");
            }
        });
        setJobs(jobs);
        console.log(jobs);
    };
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     console.log(formData);
    //     setFormData({
    //         position: "",
    //         fullName: "",
    //         mobileNumber: "",
    //         experience: "",
    //         workDescription: "",
    //         sampleWork: "",
    //         resume: ""
    //     });

    // };
    const MyArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === 'PREV' ? <img src={arrowl} className={isEdge ? "opacity-[50%] cursor-not-allowed w-[30%] mb-10" : "w-[30%] mb-10"} alt='arrowr'></img> : <img className={isEdge ? "opacity-[50%] mb-10 cursor-not-allowed w-[30%]" : "w-[30%] mb-10"} src={arrowr} alt='arrowr'></img>;
        return (
            <button onClick={onClick} disabled={isEdge}>
                {pointer}
            </button>
        );
    };
    return (

        <motion.div
            initial={{ opacity: 0, top: 0 }}
            animate={{ opacity: 1, top: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
            className="relative bg-white">
            <LottieAnimation animationData={careerAnimation} />
            <div className="font-Inter bg-gradient-to-r to-[#86cdff08] from-[#1e2f5b35] sm:pt-[5%] pt-[10%]">
                <div className="sm:text-[3.8vw] text-[5vw] mx-section font-semibold">
                    What it is like to work at Sicu-aura Innvoiz?
                </div>
                <div className="pt-[1%] flex items-center justify-between mx-section">
                    <img
                        className="mix-blend-multiply w-[40vw]"
                        src={careerimg.careerimg.careerimg1}
                        alt="career"
                    ></img>
                    <div className=" sm:text-[1.5vw] text-[3vw] sm:pt-[0%] pt-[5%] sm:text-start text-justify sm:w-[30vw] w-[40vw]">
                        We at Sicu-Aura Innoviz, faster an environment where ideas are
                        heard, discussed,reflected and developed. We care to make impact
                        full work,not only for our clients business but also for our people
                        career.
                    </div>
                </div>
                <div className="pt-[1%] flex items-end justify-between mx-section">
                    <div className=" sm:text-[1.5vw] text-[3vw] sm:text-start text-justify sm:w-[30vw] w-[40vw] pb-[5%]">
                        <div className="sm:text-[2.5vw] text-[4vw] font-semibold py-[1%]">
                            Join the team
                        </div>
                        As a person you are enthusiastic and care to listen and
                        understand.If you have passion and commitment for the work what you
                        do with the scope of experiments. We would like to hear from you.
                    </div>
                    <img
                        className="mix-blend-multiply w-[40vw]"
                        src={careerimg.careerimg.careerimg2}
                        alt="career"
                    ></img>
                </div>
            </div>
            <div>
                <div className="sm:text-[4vw] text-lg font-semibold sm:py-[5%] py-[10%]">
                    Our job openings
                </div>
                <div className="bg-gradient-to-r to-[#86cdff08] from-[#1e2f5b35] sm:py-[5%] py-[10%]">
                    {jobs.map((data, index) => {
                        return (
                            <div className="flex flex-col mx-[10%]">
                                <div className="text-start sm:text-2xl font-semibold text-sm sm:py-[2%] sm:pt-[5%] pt-[10%] py-[5%]">
                                    {data.jobtitle}
                                </div>
                                <div className="sm:flex gap-[3%]">
                                    <div className="sm:w-[80%] bg-[#0C3151] px-[2%] text-[#EEEEEE] font-Inter text-start  rounded-md">
                                        <div className="pt-[1%] pb-[1%] px-[5%] sm:text-2xl text-sm text-start">
                                            Job brief
                                        </div>
                                        <Carousel pagination={false} renderArrow={MyArrow} className="">
                                            <div className="sm:text-[1vw] py-[6px] text-[2.5vw]">
                                                {data.lookingfor}
                                            </div>
                                            <div className="sm:text-[1vw] text-[2.5vw]">
                                                <div className="b blur-sm"> {data.lookingfor}</div>
                                                <a className="relative flex justify-center bottom-[50%]  " href={`https://sicu-aura.com/Career/${data.id}`} target="_blank" rel="noreferrer"><p className="shadow-xl md:p-[2%] p-[4%] md:rounded-md rounded bg-[#0C3151]  text-xs text-[#57F2F2] ">Learn more about the job</p></a>
                                            </div>
                                        </Carousel>
                                    </div>
                                    <div className="bg-[#0C3151] sm:w-[20%] sm:rounded-xl rounded-md sm:mt-0 py-[1%] mt-[2%] text-[#EEEEEE] font-Raleway flex justify-around flex-col">
                                        <div className="sm:text-2xl text-xs">
                                            {data.jobtype}
                                        </div>
                                        <a href={`https://sicu-aura.com/Career/${data.id}`} target="_blank" rel="noreferrer" className="sm:text-2xl text-xs text-[#57F2F2] cursor-pointer" >
                                            Apply
                                        </a>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* {
                PopUp ?

                    <div
                    

                        className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center z-[60] bg-[#000000b9]">
                        <motion.form
                            initial={{
                                opacity: 1,
                                y: 60,
                                scale: 0.5,
                            }}
                            animate={{
                                opacity: 1,
                                y: 0,
                                scale: 1,
                                transition: {
                                    type: "spring",
                                    stiffness: 300,
                                },
                            }}
                            exit={{
                                opacity: 0,
                                scale: 0.5,
                                transition: { duration: 0.3 },
                            }}
                            onSubmit={handleSubmit}
                            className=" relative bg-[#C7ECE8] flex flex-col justify-center p-[3%] rounded-lg border-black border-[1px] w-screen h-fit sm:w-auto sm:h-auto  text-black font-normal sm:text-[2vw] md:text-[1vw] text-[3vw] "
                        >
                            <span className="a absolute top-1 right-1 sm:text-[2vw] text-[6vw] text-[#005F57] cursor-pointer hover:scale-110 transition-all ease-in-out duration-600" onClick={() => setPopUp(false)}><IoIosCloseCircleOutline/></span>

                            <div className=" grid sm:grid-cols-2 gap-[2%]">
                                <div className=" flex flex-col justify-start text-left gap-2">
                                    <h1>Position applied for</h1>
                                    <input type="text"   className="h-8  rounded-[5px] outline-none px-[3%]" required name="position" value={formData.position} onChange={handleChange}  />
                                </div>
                                <div className=" flex flex-col justify-sart text-left gap-2 ">
                                    <h1>Name</h1>
                                    <input type="text" className="h-8  rounded-[5px] outline-none px-[3%]" required name="fullName" value={formData.fullName} onChange={handleChange} />
                                </div>
                                <div className=" flex flex-col justify-start text-left gap-2">
                                    <h1>Upload your CV/Resume</h1>
                                    <div className="flex items-center w-[100%] bg-white h-8 rounded-[5px] ">
                                    <input  className=" px-[1%]   " type="file" name="resume" required onChange={(e) => setFormData({ ...formData, resume: e.target.files[0] })} />
                                    </div>
                                </div>
                                <div className=" flex flex-col justify-start text-left gap-2  ">
                                    <h1>Mobile Number</h1>
                                    <input type="number"  className=" h-8  rounded-[5px] outline-none px-[3%]" required name="mobileNumber" value={formData.mobileNumber} onChange={handleChange}/>
                                </div>
                                <div className=" flex flex-col justify-start text-left gap-2 ">
                                    <h1>Uplaod Sample work links</h1>
                                    <input type="text"  className=" h-8  rounded-[5px] outline-none px-[3%]" required name="sampleWork" value={formData.sampleWork} onChange={handleChange}/>
                                </div>
                                <div className=" flex flex-col justify-start text-left gap-2 ">
                                    <h1>Years of experience</h1>
                                    <input type="text"  className="  h-8  rounded-[5px] outline-none px-[3%]" required name="experience" value={formData.experience} onChange={handleChange}/>
                                </div>
                            </div>
                            <div className=" flex flex-col w-full sm:mt-[5%] mt-[15%] text-left ">
                                <div className="">Tell us about your work</div>
                                <textarea type="text"  className=" mt-[4%] h-[10vh]  rounded-[5px] outline-none p-[3%]" required name="workDescription" value={formData.workDescription} onChange={handleChange}/>
                            </div>

                            <div className=" flex flex-row justify-between items-start mt-6">
                                <button type="submit" className=" px-8 rounded-[5px] text-white py-2 bg-gradient-to-t from-[#00C5B5] to-[#005F57]">
                                    Submit
                                </button>{" "}
                                {
                                    PopUp ?
                                        <button onClick={() => setPopUp(false)} className=" px-8 rounded-[5px]  text-white py-2 bg-gradient-to-t from-[#00C5B5] to-[#005F57]">
                                            Back
                                        </button>
                                        : null
                                }

                            </div>

                        </motion.form>
                    </div>
                    : null

            } */}
        </motion.div>

    );
};

export default Career;
