import React from "react";

const ViewBlogs = ({ blogs, setPop, isPop }) => {
  return (
    <div className="flex justify-center fixed z-50 bg-[#0d6f7a] overflow-auto w-full h-full">
      <p className="text-white  w-full text-end absolute px-[5%] py-[1%] ">
        <button
          onClick={() => {
            setPop({ pop: false, id: null });
          }}
          className="bg-[#959595] px-[2%] md:text-sm text-[10px] rounded-[5px] text-white py-[4px] bg-gradient-to-t from-[#00C5B5] to-[#005F57]"
        >
          Back
        </button>
      </p>
      <div className="shadow-lg flex justify-center items-center p-[5%]">
        {blogs.map((data, index) => {
          if (data.id === isPop.id) {
            return (
              <div className="flex items-center text-white ">
                <div className="flex flex-col justify-center  items-center w-[40%]">
                  {data.imageUrl.includes(".mp4") ? (
                    <video
                      controls
                      id="myVideo"
                      className="md:w-[20%]  object-cover rounded-md"
                    >
                      <source src={data.imageUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={data.imageUrl}
                      loading="lazy"
                      className="md:w-[90%] w-[50%] rounded-md object-cover bg-gray-500"
                      alt="blog"
                    />
                  )}

                  <div className="sm:text-[1vw] text-[3vw]">
                    #{data.tagline ? data.tagline.split("#")[1] : ""}
                  </div>
                </div>

                <div className="text-start font-Inter py-[2%] w-[60%]">
                  <div className="font-semibold md:text-3xl text-xl">
                    {" "}
                    {data.title}
                  </div>
                  <div
                    className=" mt-4 h-[55vh] overflow-auto"
                    dangerouslySetInnerHTML={{
                      __html: data.content ? data.content : "",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ViewBlogs;
