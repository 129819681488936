import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import lottie from 'lottie-web';
import animationData from '../assets/animations/touch animation in main page.json';

const LottieScroll = forwardRef((props, ref) => {
  const lottieRef = useRef();
  const animationInstanceRef = useRef();

  useEffect(() => {
    if (lottieRef.current) {
      animationInstanceRef.current = lottie.loadAnimation({
        container: lottieRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData,
      });
    }

    return () => {
      if (animationInstanceRef.current) {
        animationInstanceRef.current.destroy();
      }
    };
  }, []);

  useImperativeHandle(ref, () => ({
    handleScroll: (progress) => {
      if (animationInstanceRef.current) {
        const frame = progress * (animationInstanceRef.current.totalFrames - 1);
        animationInstanceRef.current.goToAndStop(frame, true);
      }
    }
  }));

  return (
    <div ref={lottieRef} />
  );
});

export default LottieScroll;
