import React, { useEffect, useRef, useState } from "react";
import CHECKOUT_STEPS from "./stepperData";

const Stepper = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  const [margins, setMargins] = useState({
    marginLeft: 0,
    marginRight: 0,
  });

  const stepRef = useRef([]);

  useEffect(() => {
    setMargins({
      marginLeft: stepRef.current[0].offsetWidth / 2,
      marginRight: stepRef.current[CHECKOUT_STEPS.length - 1].offsetWidth / 2,
    });
  }, [stepRef]);

  const ActiveComponent = CHECKOUT_STEPS[currentStep - 1].Component;

  const nextStep = () => {
    setCurrentStep((prev) => {
      if (prev === CHECKOUT_STEPS.length) {
        setIsComplete(true);
        return prev;
      } else {
        return prev + 1;
      }
    });
  };

  const calculateWidth = () => {
    return ((currentStep-1) / (CHECKOUT_STEPS.length -1)) * 100;
  };

  return (
    <div className=" ">
      <div className="flex items-center justify-between mx-auto w-full lg:text-lg md:text-sm text-xs lg:w-[50vw] relative mt-32">
        {CHECKOUT_STEPS.map((step, index) => (
          <div
            ref={(el) => (stepRef.current[index] = el)}
            key={index}
            className="relative "
          >
            <div className=" flex flex-col items-center font-medium border-black">
              <div
                className={`p-[10px] z-10 rounded-full w-2 h-2 ${
                  currentStep > index + 1 ||
                  isComplete ||
                  currentStep === index + 1
                    ? "bg-[#3399A5]"
                    : "bg-[#D9D9D9]"
                }`}
              />
              <div className="text-wrap lg:w-full">{step.name}</div>
            </div>
          </div>
        ))}
        <div
          className="absolute top-[20%] border-[#D9D9D9] border-t-[3px] border-dashed h-[3px] z-0 left-0 right-0"
          style={{
            marginLeft: margins.marginLeft,
            marginRight: margins.marginRight,
          }}
        >
          {/* <div
            className="h-full transition-all ease-in-out duration-500 bg-[#3399A5]"
            style={{ width: `${calculateWidth()}%` }}
          /> */}
        </div>
      </div>
      {/* <button onClick={nextStep}>Next</button> */}
      <div className="min-h-screen  p-4 mt-10">
        <ActiveComponent nextStep={nextStep}/>
      </div>
    </div>
  );
};

export default Stepper;
