import React, { useEffect, useRef, useState } from "react";
import ScrollTrigger1 from "react-scroll-trigger";
import CountUp from "react-countup";
import { Link, useLocation } from "react-router-dom";
import Images from "../assets";
import portfoliodata from "../assets/Home/data/portfoliodata";
import Carousel from "react-elastic-carousel";
import reviewdata from "../assets/Home/data/reviewdata";
import Transition from "../components/transition";
import ReactGA from "react-ga4";
import touch from "../assets/animations/touch animation in main page.json";
import arrowl from "../assets/arrow-left-black.svg";
import arrowr from "../assets/arrow-right-black.svg";
import TextSpan from "../components/TextSpan";
import LottieScroll from "../components/LottieScroll";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import LottieAnimation from "../components/lottieAnimation";
import SignIn from "../components/SignIn";

gsap.registerPlugin(ScrollTrigger);



const Home = ({isPopUp,setPopUp}) => {
  const { pathname } = useLocation();
  let component = useRef();
  const lottieScrollRef = useRef();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Home",
    });
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const ctx = gsap.context(() => {
        ScrollTrigger.create({
          trigger: ".page2",
          start: "top 13%",
          end: "+=100%",
          pin: true,
          pinSpacing: true,
          onUpdate: (self) => {
            const progress = self.progress;
            if (lottieScrollRef.current) {
              lottieScrollRef.current.handleScroll(progress);
            }
          },
        });
      }, component);

      return () => ctx.revert();
    }, 2000); 

    return () => clearTimeout(timeoutId);
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const [counterOn, setCounterOn] = useState(false);
  const categories = [
    "All",
    "Website",
    "Branding",
    "Application",
    "Digital marketing",
  ];

  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  const filteredData =
    selectedCategory === "All"
      ? portfoliodata
      : portfoliodata.filter((data) => data.category === selectedCategory);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 2 },
    { width: 1000, itemsToShow: 2 },
  ];
  const breakPoints2 = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 },
  ];
  const StarRating = ({ rating }) => {
    const starArray = Array.from({ length: rating }, (_, index) => index + 1);

    return (
      <div className="flex ">
        {starArray.map((index) => (
          <img
            key={index}
            src={Images.star}
            alt={`Star ${index}`}
            style={{ margin: "5px" }}
          />
        ))}
      </div>
    );
  };
  const MyArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === "PREV" ? (
        <img
          src={arrowl}
          className={
            isEdge ? "opacity-[50%] cursor-not-allowed w-[50%]" : "w-[50%]"
          }
          alt="arrowr"
        ></img>
      ) : (
        <img
          className={
            isEdge ? "opacity-[50%] cursor-not-allowed w-[50%]" : "w-[50%]"
          }
          src={arrowr}
          alt="arrowr"
        ></img>
      );
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };
  const title = "INNOVIZ".split("");
  return (
    <>
   
      <Transition>
      <div
        className=" overflow-hidden bg-[#0C3151]">
        <div className=" bg-hero-pattern bg-no-repeat bg-cover sm:h-[85vh] h-[70vh] flex justify-center items-center ">
          <div className=" flex flex-col font-Raleway text-white items-center">
            <div className=" md:text-[40px] text-[20px] sm:text-[30px] font-[700] sm:leading-[47px]">
              S&nbsp;I&nbsp;C&nbsp;U&nbsp;-&nbsp;A&nbsp;U&nbsp;R&nbsp;A
            </div>
            <div className=" md:text-[128px] sm:text-[90px] text-[40px] font-extrabold sm:leading-[155px]">
              {title.map((letter, index) => (
                <TextSpan key={index} className="">
                  {letter}
                </TextSpan>
              ))}
            </div>

            <div className=" md:text-2xl sm:text-xl text-lg font-normal  text-center sm:tracking-[5px] px-1 sm:px-0 mt-2">
              We build delightful digital experiences for the <br />
              people, which businesses <br />
              care about.
            </div>
          </div>
        </div>

        <div className=" flex flex-col items-center bg-[#0C3151] ">
          <div className="flex flex-col items-center bg-[#0C3151] max-w-[80%]">
            <div className=" flex flex-row text-white gap-5 font-Inter sm:text-[3vh] text-[1.5vh] md:text-[4vh]  font-medium sm:mt-7 mt-4">
              <div>Tech consultancy</div>
              <div className=" bg-white w-[5px] h-[50px] rounded-full -mt-[5px] "></div>
              <div>Design consultancy</div>
              <div className=" bg-white w-[5px] h-[50px] rounded-full -mt-[5px] "></div>
              <div>Business growth consultancy</div>
            </div>
          </div>
          <div className=" mt-section flex flex-col items-center max-w-[80%]">
            <div className=" text-white font-Raleway text-[3vh] sm:text-[5.5vh] md:text-6xl font-semibold tracking-widest">
              Our Imapcts
            </div>
            <ScrollTrigger1
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <div className=" mt-section text-white flex flex-wrap justify-center font-Raleway md:gap-[10rem] sm:gap-[6rem] gap-[3.5rem] tracking-widest">
                <div className=" flex flex-col">
                  <div className="  sm:text-3xl text-xl ">
                    {counterOn && (
                      <CountUp start={0} end={200} duration={2} delay={0} />
                    )}{" "}
                    +
                  </div>
                  <div className=" sm:mt-4 mt-2 sm:text-2xl text-lg">
                    Projects <br /> Completed
                  </div>
                </div>
                <div className=" flex flex-col">
                  <div className=" sm:text-3xl text-xl">
                    {counterOn && (
                      <CountUp start={0} end={100} duration={2} delay={0} />
                    )}{" "}
                    +
                  </div>
                  <div className=" sm:mt-4 mt-2 sm:text-2xl text-lg">
                    Digital <br /> Products
                  </div>
                </div>
                <div className=" flex flex-col">
                  <div className="  sm:text-3xl text-xl">
                    {counterOn && (
                      <CountUp start={0} end={200} duration={2} delay={0} />
                    )}{" "}
                    +
                  </div>
                  <div className=" sm:mt-4 mt-2 sm:text-2xl text-lg">
                    Clients <br /> Served
                  </div>
                </div>
                <div className=" flex flex-col">
                  <div className="  sm:text-3xl text-xl">
                    {counterOn && (
                      <CountUp start={0} end={5} duration={2} delay={0} />
                    )}{" "}
                    +
                  </div>
                  <div className=" sm:mt-4 mt-2 sm:text-2xl text-lg">
                    Years <br /> Experience
                  </div>
                </div>
              </div>
            </ScrollTrigger1>
          </div>
          <div className=" mt-subsection my-8 font-Inter font-medium sm:text-2xl text-base text-white">
            Our clients see on average 40% of their total revenue originating{" "}
            <br className=" hidden sm:block" /> from their website
          </div>
          {/* <div className=' bg-white h-1 w-[200px] mt-section rounded-full'></div> */}
        </div>

        <div className=" mt-section  flex flex-col items-center sm:max-w-[80%] sm:min-w-[80%] min-w-[90%] max-w-[90%] bg-hero-service bg-no-repeat bg-cover rounded-t-[20px] mx-auto">
          <div className=" flex flex-row max-w-[90%] sm:justify-between justify-center w-[90%] ">
            <div className="flex flex-col  font-Raleway  text-white sm:mt-section mt-20 mb-16 sm:mb-0">
              <h1 className="sm:text-5xl text-2xl font-semibold sm:text-left text-center">
                Our Services
              </h1>
              <p className=" sm:text-2xl text-sm font-normal sm:text-left text-center mt-4">
                To build your business
              </p>
            </div>
            <div className="mt-section hidden sm:flex px-4  bg-white  max-h-[75px] rounded-xl  justify-center items-center shadow-md cursor-pointer">
              <Link
                to={"/services"}
                className=" px-5  py-3 font-semibold text-2xl font-Raleway"
              >
                Explore
              </Link>
            </div>
          </div>
          <div className=" mt-[7%] flex sm:flex-row flex-col  justify-around lg:justify-between sm:w-[90%] w-[100%] ">
            <div className=" flex sm:hidden sm:w-0 h-0 bg-services2-bg bg-cover bg-no-repeat  flex-row items-center my-[5%] rounded-r-[40px] w-[97%] ml-[-7px] justify-evenly min-h-[30vh]">
              <div className=" mt-0">
                <img src={Images.tech} alt="" />
              </div>
              <div className=" flex flex-col">
                <div className=" font-Raleway font-semibold text-xl text-white mt-0">
                  Technology
                </div>
                <div className=" mt-4">
                  <ul className=" text-white font-Inter text-xs text-left list-disc flex flex-col gap-1">
                    <li>Mobile Application</li>
                    <li>Web Application</li>
                    <li>Full Stack Dev</li>
                    <li>Database Management</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="pb-8 sm:flex hidden  bg-hero-pattern bg-cover bg-no-repeat  flex-col items-center w-[32%]  min-h-[60vh] rounded-t-[40px]">
              <div className=" mt-16">
                <img src={Images.tech} alt="" />
              </div>
              <div className=" font-Raleway font-semibold text-2xl lg:text-4xl text-white mt-8">
                Technology
              </div>
              <div className=" mt-16">
                <ul className=" text-white font-Inter  lg:text-lg text-xs text-left list-disc flex flex-col gap-4">
                  <li>Mobile Application</li>
                  <li>Web Application</li>
                  <li>Full Stack Dev</li>
                  <li>Database Management</li>
                </ul>
              </div>
            </div>

            <div className=" flex sm:hidden sm:w-0 sm:h-0 bg-services2-bg bg-cover bg-no-repeat flex-row-reverse items-center rounded-l-[40px] w-[97%] ml-[12px] justify-evenly min-h-[30vh]">
              <div className=" mt-0">
                <img src={Images.design} alt="" />
              </div>
              <div className=" flex flex-col">
                <div className=" font-Raleway font-semibold text-xl text-white mt-0">
                  Design
                </div>
                <div className=" mt-4">
                  <ul className=" text-white font-Inter  text-xs text-left list-disc flex flex-col gap-1">
                    <li>UI Design</li>
                    <li>UX Design</li>
                    <li>Animation </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="w-[32%] pb-8 hidden  lg:w-0 bg-hero-pattern bg-cover bg-no-repeat sm:flex flex-col items-center min-w-[30%] min-h-[60vh] rounded-t-[40px]">
              <div className=" mt-16">
                <img src={Images.design} alt="" />
              </div>
              <div className=" font-Raleway font-semibold text-2xl lg:text-4xl text-white mt-8">
                Design
              </div>
              <div className=" mt-16">
                <ul className=" text-white font-Inter  lg:text-lg text-xs text-left list-disc flex flex-col gap-4">
                  <li>UI Design</li>
                  <li>UX Design</li>
                  <li>Animation</li>
                </ul>
              </div>
            </div>
            <div className="w-[32%] pb-8 hidden bg-hero-pattern bg-cover bg-no-repeat sm:flex flex-col items-center min-w-[30%] min-h-[60vh] rounded-t-[40px]">
              <div className=" mt-16">
                <img src={Images.market} alt="" />
              </div>
              <div className=" font-Raleway font-semibold text-2xl lg:text-4xl text-white mt-8">
                Marketing
              </div>
              <div className=" mt-16">
                <ul className=" text-white font-Inter  lg:text-lg text-xs text-left list-disc flex flex-col gap-4">
                  <li>SEO</li>
                  <li>SMM</li>
                  <li>Social Media</li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" flex sm:hidden my-[5%] bg-services2-bg bg-cover bg-no-repeat  flex-row items-center rounded-r-[40px] w-[97%] ml-[-px] justify-evenly min-h-[30vh]">
            <div className=" mt-0">
              <img src={Images.market} alt="" />
            </div>
            <div className=" flex flex-col">
              <div className=" font-Raleway font-semibold text-xl text-white mt-0">
                Marketing
              </div>
              <div className=" mt-4">
                <ul className=" text-white font-Inter  text-xs text-left list-disc flex flex-col gap-1">
                  <li>SEO</li>
                  <li>SMM</li>
                  <li>Social Media</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-section sm:hidden mx-auto px-4 my-3 bg-white w-[120px] h-[45px] rounded-[10px] flex  justify-center items-center shadow-md cursor-pointer">
          <Link
            to={"/services"}
            className=" px-[2%]  py-3 font-semibold text-lg font-Raleway"
          >
            Explore
          </Link>
        </div>

        <div className=" flex flex-col  bg-white z-10 w-full items-center  ">
          <div className=" mt-section flex flex-col items-center justify-center max-w-[90%] w-[90%] mb-section">
            <div className=" font-Raleway font-semibold text-[3.7vh] sm:text-[5.5vh] md:text-[52px]">
              Industries We Serve
            </div>
            <div className=" mt-[4%] grid sm:grid-cols-5 grid-cols-3 place-content-center place-items-center mx-[3%]  sm:gap-[4.3rem] gap-[15px] font-Inter font-medium sm:text-[1.5vw] text-[2.8vw] text-[#004078] text-center ">
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%] ">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.hotel} alt="" />
                </div>
                <div className=" mt-4">Hotel Industry</div>
              </div>
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.pet} alt="" />
                </div>
                <div className=" mt-4">Pet Shop</div>
              </div>
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.realestate} alt="" />
                </div>
                <div className=" mt-4">Real Estate</div>
              </div>
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.book} alt="" />
                </div>
                <div className=" mt-4">Book Authors</div>
              </div>
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.trader} alt="" />
                </div>
                <div className=" mt-4">Traders</div>
              </div>
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.gym} alt="" />
                </div>
                <div className=" mt-4">Gym’s</div>
              </div>
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%] ">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.kitchen} alt="" />
                </div>
                <div className=" mt-4">Cloud Kitchens</div>
              </div>
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.event} alt="" />
                </div>
                <div className=" mt-4">Event Planners</div>
              </div>
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.travel} alt="" />
                </div>
                <div className=" mt-4">Travel Agency</div>
              </div>
              <div className=" flex flex-col items-center sm:w-[100%] w-[80%] mx-[2%]">
                <div className="border-2 border-[#004078] rounded-[10px] p-4">
                  <img src={Images.landscape} alt="" />
                </div>
                <div className=" mt-4">Landscape</div>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-section flex w-full flex-col  md:items-end items-center">
          <div className=" flex md:flex-row flex-col bg-white md:w-[90%] w-[80%] justify-between md:rounded-l-[20px] md:rounded-none  rounded-[20px]  ">
            <div
              className=" flex flex-col  md:w-[30%] w-[100%] md:justify-between sm:justify-evenly justify-between items-center md:rounded-l-[20px] md:rounded-none rounded-t-[20px]    "
              style={{
                background:
                  "linear-gradient(0deg, #40BAFF -197.37%, #4481BA -52.39%, #FEBDD1 197.37%)",
              }}
            >
              <div className=" font-Raleway font-semibold md:text-4xl sm:text-2xl text-xl p-4 mt-12">
                PORTFOLIO
              </div>
              <div className="flex flex-col md:mb-20 mb-12 md:gap-[2.5rem] sm:gap-[1.5rem] gap-4 items-center  text-left  ">
                {categories.map((category) => (
                  <div
                    key={category}
                    className=" font-Raleway md:text-2xl sm:text-xl text-base underline-offset-[10px] transition-all cursor-pointer"
                    style={{
                      fontWeight: selectedCategory === category ? 700 : 400,
                      color:
                        selectedCategory === category ? "white" : "black",
                      textDecoration:
                        selectedCategory === category ? "underline" : "none",
                      textDecorationThickness:
                        selectedCategory === category ? "4px" : "0px",
                    }}
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category}
                  </div>
                ))}
              </div>
            </div>

            <div className=" md:w-[75%] w-[100%]  mt-12 mb-12 p-4">
              <Carousel
                breakPoints={breakPoints}
                renderArrow={MyArrow}
                pagination={false}
              >
                {filteredData.map((data) => {
                  return (
                    <div
                      className="flex mx-[1%] rounded-[10px] md:w-[65%] w-[100%] border justify-center shadow-xl relative overflow-hidden main-container cursor-pointer"
                      key={data.id}
                    >
                      <img
                        src={data.pic}
                        alt=""
                        className=" ease-linear transition-transform duration-1000  md:h-[400px] h-[200px] object-cover"
                      />
                      <div className="overlay absolute w-full bg-black bg-opacity-40 bottom-0 rounded-b-[10px] flex flex-col items-center justify-center sm:px-4 px-2 font-Raleway text-white font-semibold sm:text-base text-xs leading-3 sm:leading-[26px] ">
                        {data.description}
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
        <div
          className="mt-section flex flex-col items-center w-[100%] overflow-hidden"
        >
          <div ref={component} className=" xl:h-auto  xl:visible invisible  h-0">
            <div className="page2 ">
              <LottieScroll ref={lottieScrollRef} />

            </div>
          </div>
          <div className="visible xl:invisible xl:h-0">
            <LottieAnimation animationData={touch} />
          </div>

          <div>
          </div>
          <Link
            to="/contacts"
            className="md:p-4 sm:p-3 p-2 mt-4 font-Raleway font-semibold md:text-2xl sm:text-xl text-sm text-white md:rounded-lg rounded-md getintouch cursor-pointer"
          >
            Get in Touch
          </Link>
        </div>
        <div className=" mt-section flex flex-col items-center  w-full  bg-white  review mx-auto">
          <div className=" mt-subsection font-Raleway text-[3.7vh] sm:text-[5.5vh] md:text-[52px] sm:p-0 p-2 font-semibold text-black">
            What Our Customer Says
          </div>
          <div className=" mt-section w-[80%]  lg:h-[400px] mb-subsection">
            <Carousel breakPoints={breakPoints2} pagination={false}>
              {reviewdata.map((data) => {
                return (
                  <div
                    className="flex lg:flex-row flex-col gap-[5%] justify-start"
                    key={data.id}
                  >
                    <div className=" lg:w-full md:w-[50%] w-full">
                      <img
                        src={data.image}
                        className=" w-full = h-auto"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col justify-between text-left    ">
                      <div className="">
                        <img src={Images.invcomma} className="w-[10%]" alt="" />
                      </div>
                      <div className=" font-Raleway font-semibold sm:text-base text-xs   text-[#0C3151] lg:leading-[30px]">
                        {data.text}
                      </div>
                      <div className=" mt-[4%] sm:w-auto w-[15px]">
                        <StarRating rating={data.rating} />
                      </div>
                      <div className=" text-left mt-[4%]">
                        <h1 className=" font-Raleway font-bold sm:text-[20px] text-sm text-[#0C3151]">
                          {data.name}
                        </h1>
                        <p className=" text-[#1D1D1D] font-normal sm:text-sm text-xs mt-[2%]">
                          Verified customer
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      </Transition>

    </>
  );
};

export default Home;
