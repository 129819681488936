import React, { useEffect, useState } from 'react';
import Images from '../assets';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { motion } from 'framer-motion';
import axios from "axios";
import { api } from '../components/mailerApi';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';
const StartProject = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search,
            title: "StartProject",
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        requirements: [],
        meetDate: startDate,
        briefFile: null,
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRequirementsChange = (value) => {
        const index = formData.requirements.indexOf(value);
        if (index === -1) {
            setFormData({ ...formData, requirements: [...formData.requirements, value] });
        } else {
            const newRequirements = [...formData.requirements];
            newRequirements.splice(index, 1);
            setFormData({ ...formData, requirements: newRequirements });
        }
    };
    const isRequirementSelected = (value) => {
        return formData.requirements.includes(value);
    };
    const handleBriefFileChange = (e) => {
        setFormData({ ...formData, briefFile: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            header: "Sicu-Aura Innoviz",
            subject: "User Request from Innoviz Start a Project page",
            html: `
                <p>I hope this email finds you well. I am writing to provide you with the details of a user who has submitted a request on our website:</p>
                <p>Name: ${formData.name}</p>
                <p>Company: ${formData.company}</p>
                <p>Email: ${formData.email}</p>
                <p>Contact Number: ${formData.phone}</p>
                <p>Meeting Date: ${formData.meetDate}</p>
                <p>Request: ${formData.requirements}</p>
                <p>Message: ${formData.message}</p>                `,
            to: " innoviz@sicu-aura.com",

        };

        try {
            const response = await axios.post(
                api, data);
            console.log("Email sent:", response.data);
        } catch (error) {
            console.error("Error sending email:", error);
        }
        setFormData({
            name: '',
            company: '',
            email: '',
            phone: '',
            requirements: [],
            meetDate: '',
            briefFile: null,
            message: ''
        });

    };

    return (
        <motion.div
            initial={{ opacity: 0, top: 0 }}
            animate={{ opacity: 1, top: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
            className=' flex flex-col items-center' >
            <div className=' flex flex-row justify-around w-full bg-gradient-to-t to-[#001F89] px-[10%] from-[#94ACFF]  text-white relative sm:pt-[10%] pt-[20%] z-20 items-end '>
                <div className='mb-[20%] text-start'>
                    <div className=' flex font-Raleway font-bold sm:text-[5vw]  text-[8vw] '>Start a Project</div>
                    <div className='sm:text-[1.5vw] text-[3vw] text-[#EEEEEE] font-Inter'>Lets build what you have in your mind</div>

                </div>
                <div className=' w-[20vw]'><img src={Images.bulb} alt="" /></div>

            </div>

            <div className=' w-[100%] flex flex-col z-20'>

                <div className=' text-white font-Raleway sm:text-[2.5vw] text-[3.5vw] py-[5%] font-semibold bg-[#50B6B0]'>
                    We would love to hear from you,about your company <br /> and discuss the ways how we can be a help for you.
                </div>

                <div className=' projectbg flex flex-col justify-center items-center '>
                    <form onSubmit={handleSubmit} className=' bg-[#C7ECE8] p-[2%] rounded-lg border-black  border-[1px] mb-[5%] mt-[5%] max-w-[80%] min-w-[40%] text-black font-normal sm:text-sm text-xs  ' >
                        <div className=' grid sm:grid-cols-2 grid-cols-1 sm:gap-2 gap-0'>
                            <div className=' flex flex-col justify-start text-left gap-1 '>
                                <h1>Name</h1>
                                <input type="text" name="name" required value={formData.name} onChange={handleInputChange} className=' rounded-[5px] h-[35px] ' />
                            </div>
                            <div className=' flex flex-col justify-start text-left gap-1 '>
                                <h1> Company</h1>
                                <input type="text" name="company" required value={formData.company} onChange={handleInputChange} className=' rounded-[5px] h-[35px] ' />
                            </div>
                            <div className=' flex flex-col justify-start text-left gap-1 '>
                                <h1>E-mail</h1>
                                <input type="text" name="email" required value={formData.email} onChange={handleInputChange} className=' rounded-[5px] h-[35px] ' />
                            </div>
                            <div className=' flex flex-col justify-start text-left gap-1 '>
                                <h1>Phone</h1>
                                <input type="text" name="phone" required value={formData.phone} onChange={handleInputChange} className=' rounded-[5px] h-[35px] ' />

                            </div>
                        </div>


                        <div className=' flex flex-col justify-center items-start text-left mt-3'>
                            <div className=' text-left flex'>Your requirements</div>
                            <div className='grid sm:grid-cols-3 grid-cols-2 gap-4 mt-[3%] place-content-center place-items-center w-[100%] bg-white p-[5%] rounded-[10px]'>
                                <div className={` rounded-[30px] p-[4%] sm:w-[10vw] w-[30vw]  border-[1px] ${isRequirementSelected('MobileApp') ? 'bg-black text-white' : 'border-black'} flex items-center justify-center cursor-pointer`} onClick={() => handleRequirementsChange('MobileApp')}>Mobile App</div>
                                <div className={` rounded-[30px] p-[4%] sm:w-[10vw] w-[30vw]  border-[1px] ${isRequirementSelected('WebApp') ? 'bg-black text-white' : 'border-black'} flex items-center justify-center cursor-pointer`} onClick={() => handleRequirementsChange('WebApp')}>Web App</div>
                                <div className={` rounded-[30px] p-[4%] sm:w-[10vw] w-[30vw]  border-[1px] ${isRequirementSelected('E-commerce') ? 'bg-black text-white' : 'border-black'} flex items-center justify-center cursor-pointer`} onClick={() => handleRequirementsChange('E-commerce')}>E-commerce</div>
                                <div className={` rounded-[30px] p-[4%] sm:w-[10vw] w-[30vw]  border-[1px] ${isRequirementSelected('UIDesign') ? 'bg-black text-white' : 'border-black'} flex items-center justify-center cursor-pointer`} onClick={() => handleRequirementsChange('UIDesign')}>UI Design</div>
                                <div className={` rounded-[30px] p-[4%] sm:w-[10vw] w-[30vw]  border-[1px] ${isRequirementSelected('UXDesign') ? 'bg-black text-white' : 'border-black'} flex items-center justify-center cursor-pointer`} onClick={() => handleRequirementsChange('UXDesign')}>UX Design</div>
                                <div className={` rounded-[30px] p-[4%] sm:w-[10vw] w-[30vw]  border-[1px] ${isRequirementSelected('SEO/SMM') ? 'bg-black text-white' : 'border-black'} flex items-center justify-center cursor-pointer`} onClick={() => handleRequirementsChange('SEO/SMM')}>SEO/SMM</div>
                                <div className={` rounded-[30px] p-[4%] sm:w-[10vw] w-[30vw]  border-[1px] ${isRequirementSelected('MotionDesign') ? 'bg-black text-white' : 'border-black'} flex items-center justify-center cursor-pointer`} onClick={() => handleRequirementsChange('MotionDesign')}>Motion Design</div>
                                <div className={` rounded-[30px] p-[4%] sm:w-[10vw] w-[30vw]  border-[1px] ${isRequirementSelected('AllServices') ? 'bg-black text-white' : 'border-black'} flex items-center justify-center cursor-pointer`} onClick={() => handleRequirementsChange('AllServices')}>All Services</div>
                                <div className={` rounded-[30px] p-[4%] sm:w-[10vw] w-[30vw]  border-[1px] ${isRequirementSelected('Customize') ? 'bg-black text-white' : 'border-black'} flex items-center justify-center cursor-pointer`} onClick={() => handleRequirementsChange('Customize')}>Customize</div>

                            </div>
                        </div>

                        <div className=' mt-3 flex sm:flex-row flex-col justify-between'>
                            <div className='flex flex-col text-left sm:w-[45%]'>
                                <div>Schedule a meet:</div>
                                <div className=' mt-4 bg-white flex justify-center items-center px-[2%] p-[2.4%] rounded-[5px]'>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        className='w-[50%]' />
                                    <div className=' bg-[#606060] p-[2%] rounded-[5px] flex items-center justify-center '>Calender </div>
                                </div>

                            </div>

                            <div className='flex flex-col sm:w-[45%] sm:mt-0 mt-6'>
                                <div className='text-left' >Attach a brief if any:</div>
                                <div className='bg-white mt-4 p-[3%] rounded-[5px]'>
                                    <input type='file' onChange={handleBriefFileChange} className='' />
                                </div>
                            </div>
                        </div>

                        <div className=" flex flex-col w-full sm:mt-[3%] mt-[15%] text-left ">
                            <div className="">Write your message</div>
                            <textarea name="message" value={formData.message} onChange={handleInputChange} className=" p-[2%] mt-[4%] h-[20vh] outline-none  rounded-[10px]" />
                        </div>
                        <div className=' flex flex-col justify-start items-start mt-6'><button type="submit" className=' px-12  rounded-[5px] text-white py-2 bg-gradient-to-t from-[#00C5B5] to-[#005F57]'>Send</button> </div>
                    </form>
                    <div className=' flex  font-normal sm:text-[1.5vw] text-[3vw] '>
                        Write us in the form given about the project you have in your mind.<br /> We will get you in touch with you soon.
                    </div>
                    <div className=' flex flex-row justify-between min-w-[60%] max-w-[85%] gap-8'>
                        <div className=' font-Raleway font-normal  flex'><h1 className='font-Raleway font-normal text-[15vw] text-gray-400 flex items-center'>#</h1></div>

                        <div className='font-Raleway  sm:text-[2vw] text-[3vw] flex font-semibold'><p className='flex items-center text-left'>I cannot fill the form, <br /> I want to connect now</p></div>
                        <div className=' flex items-center '><button className='sm:w-[10vw] w-[20vw] bg-[#1B1B1B] text-white p-[6%]  font-Inter  sm:text-[1.5vw] text-[3vw] font-normal rounded-[5px]' onClick={() => window.open('https://wa.me/916289179476')}>Let's chat!</button></div>


                    </div>

                </div>

            </div>

        </motion.div>
    );
}

export default StartProject;
