import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import { FaRupeeSign } from "react-icons/fa";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { removeAllItems, removeFromCart } from "../../redux/cart/cartSlice";
import credit from "../../assets/pricing/credit.png";
import debit from "../../assets/pricing/debit.png";
import googlepay from "../../assets/pricing/googlepay.png";
import phonepay from "../../assets/pricing/phonepay.png";
import paytm from "../../assets/pricing/paytm.png";
import amazone from "../../assets/pricing/amazone.png";
import axios from "axios";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

// const HOST_URL = "https://innoviz-payment-gateway.onrender.com/pay";
// const HOST_URL ='http://localhost:3002/pay'
const HOST_URL ='https://innoviz-payment-gateway.vercel.app/pay'

const Payment = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const finalPrice = useSelector((state) => state.cart.finalPrice);
  const couponCode = useSelector((state) => state.cart.couponCode);
  const customerDetails = useSelector((state) => state.cart.customerDetails);
  const user = useSelector((state) => state.auth.user);
  console.log(user);

  const totalPrice = cart.reduce((acc, item) => {
    const total = parseFloat(item.price.replace(/,/g, ""));
    return acc + total;
  }, 0);

  // Calculate GST and total payable including GST
  const gstRate = 0.18;
  const priceBeforeGST = couponCode ? finalPrice : totalPrice;
  const gstAmount = priceBeforeGST * gstRate;
  const totalWithGST = priceBeforeGST + gstAmount;

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleCheckout = async (e) => {
    e.preventDefault(e);
    saveOrder();
    const amountInPaise = totalWithGST;

    try {
      console.log(amountInPaise);
      const response = await axios.get(`${HOST_URL}?amount=${amountInPaise}`);
      const redirectUrl = response.data.url;
      console.log(redirectUrl);
      window.location.href = redirectUrl;
    } catch (error) {
      console.error("Payment initiation error:", error);
    }
  };

  console.log(cart);
  const saveOrder = async () => {
    const firebaseData = totalWithGST;

    if (cart) {
      try {
        const res = await axios.post(
          "https://pavan-sicu-aura-default-rtdb.firebaseio.com/orders.json",
          {
            order: {
              cart,
              user,
              firebaseData,
              customerDetails,
            },
          }
        );
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto">
        <div className="border lg:w-[90%] w-full mx-auto">
          <div className="bg-[#3399A5] px-8 py-5 text-lg font-semibold text-start text-white">
            CUSTOMER DETAILS
          </div>
          <div className="flex flex-col-reverse lg:flex-row justify-between gap-x-6 px-2 sm:px-4 md:px-6 lg:px-14 mt-14 py-6">
            <div className="border border-[#3399A5] w-full md:w-[80%] mx-auto lg:mx-0 mt-12 lg:mt-0 lg:w-[40%]">
              <div className="bg-[#E0E0E0] text-[#7E7E7E] p-[5%] py-7 border-b border-gray-300 font-bold ">
                ORDER SUMMARY
              </div>
              <div className="px-8 space-y-3 my-8">
                <div className="flex text-lg font-semibold justify-between">
                  <p>Items {`(${cart.length})`}</p>
                  <p className="font-medium">₹{totalPrice.toFixed(2)}</p>
                </div>
                <div className="flex text-lg font-semibold justify-between">
                  <p>Discounts</p>
                  <p>{couponCode ? <p>₹{totalPrice - finalPrice}.00</p> : "₹0.00"}</p>
                </div>
                <div className="flex text-lg font-semibold justify-between">
                  <p>GST (18%)</p>
                  <p>₹{gstAmount.toFixed(2)}</p>
                </div>
              </div>
              <div className="flex justify-between px-8 bg-[#E0E0E0] text-lg font-bold py-4">
                <p>Total Payable</p>
                <p>₹{totalWithGST.toFixed(2)}</p>
              </div>
              <div className="flex justify-between px-8 text-[#3399A5] text-lg font-bold my-4">
                <p>Total Savings</p>
                <p>{couponCode ? <p>₹{totalPrice - finalPrice}.00</p> : "₹0.00"}</p>
              </div>
            </div>
            <div className="space-y-8 flex flex-col items-end mx-auto lg:mx-0">
              <div className="flex sm:flex-row flex-wrap items-center justify-center mx-auto gap-y-6 flex-col gap-x-4">
                <img
                  alt="phonepay"
                  className="cursor-pointer"
                  onClick={handleCheckout}
                  src={phonepay}
                />
                <img
                  alt="googlepay"
                  className="cursor-pointer"
                  onClick={handleCheckout}
                  src={googlepay}
                />
                <img
                  alt="paytm"
                  className="cursor-pointer"
                  onClick={handleCheckout}
                  src={paytm}
                />
                <img alt="amazone" onClick={handleCheckout} src={amazone} />
              </div>
              <div className="flex sm:flex-row flex-wrap items-center justify-center mx-auto gap-y-6 flex-col gap-x-4">
                <img
                  alt="credit"
                  className="cursor-pointer"
                  onClick={handleCheckout}
                  src={credit}
                />
                <img
                  alt="debit"
                  className="cursor-pointer"
                  onClick={handleCheckout}
                  src={debit}
                />
              </div>
            </div>
          </div>
          <div className="flex lg:justify-end justify-center lg:mr-[15%] my-[2%] mb-8">
            <button
              onClick={handleCheckout}
              className="bg-[#FF6161] text-lg text-white border  px-8 py-[10px] rounded-md"
            >
              Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
