import CustomerDetails from "./CustomerDetails";
import OrderSummary from "./OrderSummary";
import Payment from "./Payment";

const CHECKOUT_STEPS = [
  {
    name: "Customer Details",
    Component: (props) => <CustomerDetails {...props} />,
  },
  {
    name: "Order Summary",
    Component: (props) => <OrderSummary {...props} />,
  },
  {
    name: "Payment",
    Component: (props) => <Payment {...props} />,
  },
];

export default CHECKOUT_STEPS;
