import React, { useEffect } from "react";
import q from "../assets/about/q.svg";
import aboutdata from "../assets/about/aboutdata";
import rocket from "../assets/about/rocket.svg";
import service from "../assets/about/services.svg";
import blogs from "../assets/about/blogs.svg";
import career from "../assets/about/career.svg";
import servicesImg from "../assets/services/index";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import car1 from "../assets/about/car1.png";
import car2 from "../assets/about/car2.png";


gsap.registerPlugin(ScrollTrigger);
const About = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Blogs",
    });
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {

      const animateSVGPath = () => {
        const svgPath = document.querySelector(".squiggle path");
        if (svgPath) {
          const pathLength = svgPath.getTotalLength();
          gsap.set(svgPath, {
            strokeDasharray: pathLength,
            strokeDashoffset: pathLength,
          });
  
          ScrollTrigger.create({
            trigger: ".squiggle",
            start: "top center ",
            end: "bottom bottom",
            onUpdate: (self) => {
              const progress = self.progress;
              const offset = pathLength * (1 - progress);
              gsap.to(svgPath, { strokeDashoffset: offset }); 
            },
          });
        }
      };
  
      animateSVGPath();
  

      return () => {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      };
    }, 1000); 

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, top: 0 }}
      animate={{ opacity: 1, top: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      className=" z-10 bg-white"
    >
      <div className="bg-gradient-to-t to-[#001F89] sm:pt-[14%] pt-[20%]  from-[#94ACFF] items-start text-white text-start flex  justify-around">
        <div className="">
          <div className="sm:text-[4vw] text-[5vw] font-bold sm:mt-[5%] font-Inter">
            We provide solution,
            <br /> that are #desirable,
            <br /> #reasonable #viable.
          </div>
          <div className="sm:text-[1.5vw] text-[3vw] text-[#EEEEEE]">
            We leverage the use of Technology, Design <br />
            and Digital marketing.
          </div>
        </div>
        <div className="sm:w-[20vw] w-[30vw]">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 461 675"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.line
              initial={{ pathLength: 0, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 2 },
              }}
              y1="-2.5"
              x2="347.001"
              y2="-2.5"
              transform="matrix(0.00309587 -0.999995 -0.999995 -0.00309587 60 674)"
              stroke="url(#paint0_linear_0_1)"
              stroke-width="5"
            />
            <motion.line
              initial={{ pathLength: 0, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 3 },
              }}
              x1="90.5"
              y1="674"
              x2="90.5"
              y2="442"
              stroke="url(#paint1_linear_0_1)"
              stroke-width="5"
            />
            <motion.line
              initial={{ pathLength: 0, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 2 },
              }}
              y1="-2.5"
              x2="435"
              y2="-2.5"
              transform="matrix(-1.31134e-07 -1 -1 1.31134e-07 125 674)"
              stroke="url(#paint2_linear_0_1)"
              stroke-width="5"
            />
            <motion.line
              initial={{ pathLength: 0, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 2 },
              }}
              y1="-2.5"
              x2="495"
              y2="-2.5"
              transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 168 674)"
              stroke="url(#paint3_linear_0_1)"
              stroke-width="5"
            />
            <motion.line
              initial={{ pathLength: 0, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 2 },
              }}
              y1="-2.5"
              x2="324"
              y2="-2.5"
              transform="matrix(-1.31134e-07 -1 -1 1.31134e-07 231 674)"
              stroke="url(#paint4_linear_0_1)"
              stroke-width="5"
            />
            <motion.line
              initial={{ pathLength: 0.5, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 3 },
              }}
              x1="271.5"
              y1="673"
              x2="271.5"
              y2="490"
              stroke="url(#paint5_linear_0_1)"
              stroke-width="5"
            />
            <motion.line
              initial={{ pathLength: 0.5, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 1.5 },
              }}
              x1="213.5"
              y1="674"
              x2="213.5"
              y2="80"
              stroke="url(#paint6_linear_0_1)"
              stroke-width="5"
            />
            <motion.line
              initial={{ pathLength: 0, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 3 },
              }}
              y1="-2.5"
              x2="488"
              y2="-2.5"
              transform="matrix(-1.31134e-07 -1 -1 1.31134e-07 330 674)"
              stroke="url(#paint7_linear_0_1)"
              stroke-width="5"
            />
            <motion.line
              initial={{ pathLength: 0, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 3 },
              }}
              y1="-2.5"
              x2="383"
              y2="-2.5"
              transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 354 674)"
              stroke="url(#paint8_linear_0_1)"
              stroke-width="5"
            />
            <motion.line
              initial={{ pathLength: 0, pathOffset: -0.1, opacity: 0 }}
              whileInView={{
                pathLength: 1,
                pathOffset: 0,
                opacity: 1,
                transition: { duration: 3 },
              }}
              y1="-2.5"
              x2="215"
              y2="-2.5"
              transform="matrix(-1.31134e-07 -1 -1 1.31134e-07 384 674)"
              stroke="url(#paint9_linear_0_1)"
              stroke-width="5"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 3.5 },
              }}
              cx="231"
              cy="350"
              r="110"
              fill="#DEB3FF"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 3.3 },
              }}
              cx="168"
              cy="180"
              r="20"
              fill="#DFB5FF"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 2 } }}
              cx="216"
              cy="83"
              r="83"
              fill="#399CC6"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { duration: 3.2 },
              }}
              cx="92.5"
              cy="434.5"
              r="52.5"
              fill="#72D5FF"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 3 } }}
              cx="383.5"
              cy="459.5"
              r="77.5"
              fill="#EA9686"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 3 } }}
              cx="216"
              cy="207"
              r="35"
              fill="#EA9686"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 2 } }}
              cx="274"
              cy="490"
              r="35"
              fill="#EDFF93"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 3 } }}
              cx="134.5"
              cy="172.5"
              r="12.5"
              fill="#EDFF93"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 3 } }}
              cx="125"
              cy="235"
              r="49"
              fill="#D9D9D9"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 3 } }}
              cx="61"
              cy="325"
              r="61"
              fill="#8E97EF"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 3 } }}
              cx="353.5"
              cy="291.5"
              r="25.5"
              fill="#AEB6FF"
            />
            <motion.circle
              initial={{ opacity: 1, y: 250 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 3 } }}
              cx="329.5"
              cy="196.5"
              r="72.5"
              fill="#D9D9D9"
            />
            <defs>
              <linearGradient
                id="paint0_linear_0_1"
                x1="0"
                y1="0.5"
                x2="347.001"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_0_1"
                x1="93.5"
                y1="674"
                x2="93.5"
                y2="442"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_0_1"
                x1="0"
                y1="0.5"
                x2="435"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_0_1"
                x1="0"
                y1="0.5"
                x2="495"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_0_1"
                x1="0"
                y1="0.5"
                x2="324"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_0_1"
                x1="274.5"
                y1="673"
                x2="274.5"
                y2="490"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
              <linearGradient
                id="paint6_linear_0_1"
                x1="216.5"
                y1="674"
                x2="216.5"
                y2="80"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
              <linearGradient
                id="paint7_linear_0_1"
                x1="0"
                y1="0.5"
                x2="488"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
              <linearGradient
                id="paint8_linear_0_1"
                x1="0"
                y1="0.5"
                x2="383"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
              <linearGradient
                id="paint9_linear_0_1"
                x1="0"
                y1="0.5"
                x2="215"
                y2="0.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#7CD8FF" stop-opacity="0.5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className=" sm:text-4xl text-lg sm:py-[5%] py-[10%] font-semibold font-Inter border-t">
        How Sicu-Aura Innoviz can <br />
        assist your business
      </div>
      <div className="font-Raleway bg-gradient-to-r to-[#86cdff08] from-[#1e2f5b35] sm:py-[5%] py-[20%] flex justify-center border-t">
        <div className="relative  sm:text-base text-[10px] text-start">
          <img className="md:w-[30vw] w-[40vw] " src={q} alt="q"></img>
          <div className="absolute sm:-top-[10%] -top-[15%] sm:left-[30%] left-[15%]">
            Flexibility in pricing
          </div>
          <div className="absolute  top-[20%] sm:-right-[30%] -right-[45%]">
            Consultancy
          </div>
          <div className="absolute  top-[20%] sm:-left-[35%] -left-[50%]">
            Customization
          </div>
          <div className="absolute  bottom-[10%] sm:-left-[30%] -left-[20%]">
            Refine results
          </div>
          <div className="absolute  bottom-[10%] sm:-right-[50%] -right-[40%]">
            Saving your resources
          </div>
        </div>
      </div>
      {/*-----------------------------------------------------------------------Our Process------------------------------------------------------------------- */}
      <div className="flex justify-center"></div>
      <div
        id="scroll-to"
        className="sm:py-[5%] py-[10%]  border-t bg-svg-background path-animation"
      >
        <div className="  sm:text-4xl text-lg font-semibold  font-Inter">
          Our Process
        </div>
        <div className="mt-14 w-[100%] ">
          <div className="flex justify-center">
            <img src={car2} alt="car2" className="w-[20%]" />
          </div>
          <div className="flex justify-center z-0 absolute  w-[100%] ">
            <svg
              className="squiggle md:w-[67%] w-[92%]"
              viewBox="0 0 1202 3252"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M606.5 -17.5V15.5C606.5 75 485 66.5 332 66.5C179 66.5 21.5 80.5 21.5 289.5C20.5 294.5 19.5 299.5 18.5 304.5C17.5 317.5 -3.5 370.5 93.5 419.5C129.5 435.5 156.5 446 222.5 464C288.5 482 469.5 518 589.5 540C779.5 574.833 905 599 905 599C1262 646 1318.5 996.5 828.5 1101.5C695.5 1126.5 618 1138 372 1188C161 1231 10.6133 1238.17 4.5 1348.5C-26.5 1910.51 1201.5 1523.96 1201.5 1908C1201.5 2408.21 1.5 2101.31 1.5 2465.5C1.5 2784.6 1201.5 2519.76 1201.5 2947.5C1201.5 3373.41 626.338 2927.2 589.5 3303.5"
                stroke="#0D6F7A"
                stroke-width="3"
              />
            </svg>
          </div>
        </div>
        <div>
          {aboutdata.process.map((data, index) => {
            return (
              <div
                key={index}
                className={`flex justify-between mx-section items-center py-[5%] ${index % 2 !== 0 ? "flex-row-reverse" : ""
                  }`}
              >
                <div className="font-Inter sm:w-[15vw] w-[30vw] text-start text-content z-10 bg-[#ffffff] rounded-2xl">
                  <div className="sm:text-[2.5vw] text-[4vw]">{data.title}</div>
                  <div className=" sm:text-[1.5vw] text-[3vw] ">
                    {data.content}
                  </div>
                </div>
                <div className="z-10">
                  <img
                    className="sm:w-[20vw] w-[30vw]"
                    src={data.pic}
                    alt="process"
                  ></img>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <div className="flex justify-center  mt-[3%]  w-[100%]">
            <img src={car1} alt="car1" className="w-[20%] z-20" />
          </div>
        </div>
      </div>
      {/*----------------------------------------------------------------------- Our Values------------------------------------------------------------------- */}
      <div className="sm:py-[5%] py-[10%] border-t">
        <div className="sm:text-4xl text-lg font-semibold  font-Inter ">
          Our Values
        </div>
        <div className="flex gap-[5%] justify-center pt-[4%] ">
          <div className="bg-[#76F7FF] sm:text-[1.5vw] text-[2.5vw] font-Inter font-semibold sm:w-[15vw] w-[20vw] py-[5%] sm:rounded-3xl rounded-lg">
            Customer
            <br /> experience
          </div>
          <div className="bg-[#FF9279] sm:text-[1.5vw] text-[2.5vw] font-Inter font-semibold sm:w-[15vw] w-[20vw] py-[5%] sm:rounded-3xl rounded-lg">
            Transparency
          </div>
        </div>
        <div className="flex gap-[5%] justify-center pt-[4%]">
          <div className="bg-[#8390FF] sm:text-[1.5vw] text-[2.5vw] font-Inter font-semibold sm:w-[15vw] w-[20vw] py-[7%] sm:rounded-3xl rounded-lg">
            Accountability
          </div>
          <div className="bg-[#CE90FF] sm:text-[1.5vw] text-[2.5vw] font-Inter font-semibold sm:w-[15vw] w-[20vw] py-[7%] sm:rounded-3xl rounded-lg">
            Fairness
          </div>
          <div className="bg-[#7DD8FF] sm:text-[1.5vw] text-[2.5vw] font-Inter font-semibold sm:w-[15vw] w-[20vw] py-[7%] sm:rounded-3xl rounded-lg">
            Process
          </div>
        </div>
        <div className=" bg-gradient-to-r from-[#08297C] to-[#759CE8] sm:mx-[15vw] mx-[8vw]   text-white py-[2%] mt-[10%] sm:rounded-3xl rounded-lg">
          <div className="mx-subsection text-start flex justify-between items-center relative">
            <div>
              <div className="sm:text-4xl text-lg font-Inter">
                Let’s start on the Idea,
                <br /> you thinking about !
              </div>
              <div className="font-Raleway text-[#ECECEC]  sm:text-[1.3vw] text-[3vw] py-[10%]">
                Any stage your business <br />
                may be in,we will scale
                <br /> it for you.
              </div>
              <Link to={'/contacts'} className="bg-gradient-to-r from-[#00C5B5] to-[#005F57] sm:text-[1.5vw] text-[3vw] flex items-center justify-center w-[45%] sm:py-[5%] py-[3%] sm:rounded-lg rounded-md text-center text-white  mt-[5%]">
                Get in touch
                <img
                  className="w-[15%] mt-[1%] ml-1"
                  src={servicesImg.arrow}
                  alt="arrow"
                ></img>
              </Link>
            </div>
            <div className=" w-[40vw] absolute -right-[20%] ">
              <img
                src={rocket}
                className="mix-blend-multiply"
                alt="rocket"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-gradient-to-r to-[#86cdff30] font-Inter rounded-t from-[#597eb67c] sm:text-[1.5vw] text-[2.5vw] flex justify-between px-[10%] py-[5%]">
        <div>
          <img
            className="sm:w-[10vw] w-[20vw]"
            src={service}
            alt="services"
          ></img>
          <Link to={"/services"}>Services</Link>
        </div>
        <div>
          <img
            className="sm:w-[10vw] w-[20vw]"
            src={blogs}
            alt="services"
          ></img>
          <Link to={"/blogs"}>Blogs</Link>
        </div>
        <div>
          <img
            className="sm:w-[10vw] w-[20vw]"
            src={career}
            alt="services"
          ></img>
          <Link to={"/career"}>Career</Link>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
