import React from 'react';
import splvideo from '../assets/Home/Spalash Screen  Black-1.mp4'
import transition from './transition';

const PreLoader = () => {
    return (
        <div className="preloader bg-black">
            <video autoPlay loop={false} muted playsInline className='h-[100vh] w-full sm:object-cover object-contain'>
                <source src={splvideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default PreLoader;
