import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  finalPrice: 0,
  couponCode: null,
  customerDetails: {
    name: "",
    designation: "",
    organization: "",
    address: "",
    mobile: "",
    email: "",
  },
  paymentDetails: [], // Array to store payment details for multiple transactions
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.cart.find((item) => item.id === action.payload.id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cart.push({ ...action.payload, quantity: 1 });
      }
      state.finalPrice += action.payload.price;
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const item = state.cart.find((item) => item.id === id);
      if (item) {
        state.finalPrice += (quantity - item.quantity) * item.price;
        item.quantity = quantity;
      }
    },
    removeFromCart: (state, action) => {
      const itemToRemove = state.cart.find((item) => item.id === action.payload.id);
      if (itemToRemove) {
        state.finalPrice -= itemToRemove.quantity * itemToRemove.price;
        state.cart = state.cart.filter((item) => item.id !== action.payload.id);
      }
    },
    removeAllItems: (state) => {
      state.finalPrice = 0;
      state.cart = [];
    },
    setFinalPrice: (state, action) => {
      state.finalPrice = action.payload;
    },
    setCouponCode: (state, action) => {
      state.couponCode = action.payload;
    },
    setCustomerDetails: (state, action) => {
      state.customerDetails = action.payload;
    },
    addPaymentDetails: (state, action) => {
      if (Array.isArray(state.paymentDetails)) {
        state.paymentDetails = state.paymentDetails.concat(action.payload);
      } else {
        console.error("paymentDetails is not an array");
      }
    },
  },
});

export const {
  addToCart,
  updateQuantity,
  removeFromCart,
  removeAllItems,
  setFinalPrice,
  setCouponCode,
  setCustomerDetails,
  addPaymentDetails,
} = cartSlice.actions;
export default cartSlice.reducer;
